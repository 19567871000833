import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import UserClasses from '../Admin/users/Users.module.css';
import Layout from 'Components/Layout';
import { Helmet } from 'react-helmet';
import { Services } from 'Services';
import { useNavigate, useParams } from 'react-router-dom';
import { Success, Error } from 'Util/Toast';
import { Button } from '@mui/material';
import CommonTutorClasses from '../Admin/commoncssfiles/CategoryTutor.module.css';
import ReactLoading from 'react-loading';

const EditCommunities = () => {
    const navigate = useNavigate();
    const { communityId } = useParams(); // Ensure communityId is correctly fetched
    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        criteriaMode: 'all',
    });
    const [loading, setLoading] = useState(false);
    const [communityData, setCommunityData] = useState(null);

    useEffect(() => {
        // Fetch community data based on communityId
        const fetchCommunity = async () => {
            const token = { Authorization: `token ${localStorage.getItem('Token')}` };
            try {
                const response = await Services.getCommunitiesById(communityId, token);
                if (response.Status === 1) {
                    setCommunityData(response.Community);
                    // Prefill form fields with community data
                    const { communityName, communityArea, communityAddress, communityPinCode, communityLatitude, communityLongitude, communityStatus } = response.Community;
                    setValue('communityName', communityName);
                    setValue('communityArea', communityArea);
                    setValue('communityAddress', communityAddress);
                    setValue('communityPinCode', communityPinCode);
                    setValue('communityLatitude', communityLatitude);
                    setValue('communityLongitude', communityLongitude);
                    setValue('communityStatus', communityStatus.toString());
                } else {
                    console.error('Error fetching community:', response.Message);
                    Error(response.Message);
                }
            } catch (error) {
                console.error('Error fetching community:', error);
                Error('An error occurred while fetching the community');
            }
        };

        if (communityId) {
            fetchCommunity();
        }
    }, [communityId, setValue]);

    const onSubmit = async (data) => {
        setLoading(true);

        const requestBody = {
            communityName: data.communityName,
            communityArea: data.communityArea,
            communityAddress: data.communityAddress,
            communityPinCode: parseInt(data.communityPinCode),
            communityLatitude: parseFloat(data.communityLatitude),
            communityLongitude: parseFloat(data.communityLongitude),
            communityStatus: parseInt(data.communityStatus), // Convert to integer
        };

        const token = { Authorization: `token ${localStorage.getItem('Token')}` };

        try {
            const response = await Services.editCommunity(communityId, requestBody, token); // Pass communityId here
            if (response.Status === 1) {
                navigate('/communities');
                Success(response.Message);
                console.log('Community updated successfully:', response.Message);
            } else {
                Error(response.Message);
                console.error('Error updating community:', response.Message);
            }
        } catch (error) {
            Error('An error occurred while updating the community');
            console.error('Error updating community:', error);
        } finally {
            setLoading(false);
        }
    };

    const validateLatitude = (value) => {
        const latitude = parseFloat(value);
        return latitude >= -90 && latitude <= 90 || "Latitude must be between -90 and 90";
    };

    const validateLongitude = (value) => {
        const longitude = parseFloat(value);
        return longitude >= -180 && longitude <= 180 || "Longitude must be between -180 and 180";
    };

    return (
        <Layout Active={'Communities'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Edit Community</title>
            </Helmet>
            <div className={UserClasses['main-first-container']}>
                <div className={UserClasses['main-data-tab']}>
                    <div className={UserClasses['add-users-btn']}>
                        <Button variant="contained" color="primary" onClick={() => navigate('/communities')}>
                            BACK
                        </Button>
                    </div>
                    <div className={CommonTutorClasses['wrapper']} data-aos="zoom-in-down">
                        <h2 className={CommonTutorClasses['main-header']}>Edit Community</h2>
                        <form className={CommonTutorClasses["form"]} onSubmit={handleSubmit(onSubmit)}>
                            <div className={CommonTutorClasses['form-control']}>
                                <label htmlFor="communityName">Community Name</label>
                                <input type='text' {...register('communityName', { required: true })} />
                                {errors.communityName && <span>This field is required</span>}
                            </div>
                            <div className={CommonTutorClasses['form-control']}>
                                <label htmlFor="communityArea">Community Area</label>
                                <input type='text' {...register('communityArea', { required: true })} />
                                {errors.communityArea && <span>This field is required</span>}
                            </div>
                            <div className={CommonTutorClasses['form-control']}>
                                <label htmlFor="communityAddress">Community Address</label>
                                <input type='text' {...register('communityAddress', { required: true })} />
                                {errors.communityAddress && <span>This field is required</span>}
                            </div>
                            <div className={CommonTutorClasses['form-control']}>
                                <label htmlFor="communityPinCode">Community Pin Code</label>
                                <input
                                    type='text'
                                    {...register('communityPinCode', {
                                        required: 'This field is required',
                                        pattern: {
                                            value: /^[1-9][0-9]{5}$/, // Regex for 6-digit pin code
                                            message: 'Invalid pin code format'
                                        }
                                    })}
                                />
                                {errors.communityPinCode && (
                                    <span>{errors.communityPinCode.message}</span>
                                )}
                            </div>
                            <div className={CommonTutorClasses['form-control']}>
                                <label htmlFor="communityLatitude">Community Latitude</label>
                                <input
                                    type='number'
                                    step='any'
                                    {...register('communityLatitude', {
                                        required: true,
                                        validate: {
                                            validLatitude: validateLatitude,
                                        }
                                    })}
                                />
                                {errors.communityLatitude && (
                                    <span>{errors.communityLatitude.message}</span>
                                )}
                            </div>
                            <div className={CommonTutorClasses['form-control']}>
                                <label htmlFor="communityLongitude">Community Longitude</label>
                                <input
                                    type='number'
                                    step='any'
                                    {...register('communityLongitude', {
                                        required: true,
                                        validate: {
                                            validLongitude: validateLongitude,
                                        }
                                    })}
                                />
                                {errors.communityLongitude && (
                                    <span>{errors.communityLongitude.message}</span>
                                )}
                            </div>
                            <div className={CommonTutorClasses['form-control']}>
                                <label htmlFor="communityStatus">Community Status</label>
                                <select {...register('communityStatus', { required: true })}>
                                    <option value="1">Active</option>
                                    <option value="0">Inactive</option>
                                </select>
                                {errors.communityStatus && <span>This field is required</span>}
                            </div>
                            <button type="submit" disabled={loading}>
                                {loading ? (
                                    <ReactLoading color="green" type="spokes" height={30} width={30} />
                                ) : (
                                    'Submit'
                                )}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default EditCommunities;
