import React, { useEffect, useState } from 'react';
import Layout from 'Components/Layout';
import UserClasses from '../users/Users.module.css';
import { Helmet } from 'react-helmet';
import { deliveryTableHeads } from '../constants/Constants';
import { SkeletonTheme } from 'react-loading-skeleton';
import UsersSkeleton from '../users/UsersSkeleton';
import ProductClasses from '../products/Products.module.css';
import DeliveryClasses from './AddDeliveryBoy.module.css';
import OrderClasses from '../orders/Orders.module.css';
import { Button, TablePagination } from '@mui/material';
import { Services } from 'Services';
import { useNavigate } from 'react-router-dom';
import { CiSquarePlus } from 'react-icons/ci';
import { FaRegEdit } from 'react-icons/fa';
import CategoryClasses from '../categories/AddCategory.module.css';
import newimg from '../../../Assets/square-user.jpg';
import deliveryBoyIcon from '../../../Assets/no-deliveryboy1.gif'; 

const DeliveryBoyManagement = () => {
    const [loading, setLoading] = useState(true);
    const [accounts, setAccounts] = useState([]);
    const [page, setPage] = useState(() => parseInt(localStorage.getItem('currentDeliverBoyPage'), 10) || 0);
    const [rowsPerPage, setRowsPerPage] = useState(() => parseInt(localStorage.getItem('orderRowsPerPage'), 10) || 10);
    const [totalCount, setTotalCount] = useState(0);
    const navigate = useNavigate();

    const token = { Authorization: `token ${localStorage.getItem('Token')}` };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await Services.getDeliveryPersonsList(token);
                setAccounts(response.accounts);
                setTotalCount(response.accounts.length);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching delivery persons:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        localStorage.setItem('currentDeliverBoyPage', page.toString());
    }, [page]);

    useEffect(() => {
        localStorage.setItem('orderRowsPerPage', rowsPerPage.toString());
    }, [rowsPerPage]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const indexOfLastItem = (page + 1) * rowsPerPage;
    const indexOfFirstItem = indexOfLastItem - rowsPerPage;

    const currentData = accounts.slice(indexOfFirstItem, indexOfLastItem);

    const handleAddDeliveryBoyClick = () => {
        navigate('/add-delivery-boy');
    };

    const handleEditDeliveryBoyClick = (deliveryPersonId) => {
        navigate(`/edit-deliveryboy/${deliveryPersonId}`);
    };

    const renderTable = () => {
        if (currentData.length === 0) {
            return (
                <div className={UserClasses['no-records-container']}>
                    <img height={100} src={deliveryBoyIcon} alt="Delivery Boy" className={UserClasses['no-records-icon']} data-aos="fade-right"/>
                    <p>No Delivery Boys Found</p>
                </div>
            );
        }

        return (
            <table>
                <thead>
                    <tr className={UserClasses['main-table-heads']}>
                        {deliveryTableHeads.map((head, index) => (
                            <th key={index} className={ProductClasses['products-table-head']}>{head}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {currentData.map((person, index) => (
                        <tr key={person.deliveryPersonId} data-aos="fade-up"
                            data-aos-anchor-placement="top-bottom">
                            <td>
                                <img
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        window.open(person.imageId, "_blank");
                                    }}
                                    className={DeliveryClasses['delivery-boy-img']}
                                    src={person.imageId}
                                    onError={(e) => {
                                        e.currentTarget.onerror = null;
                                        e.currentTarget.src = newimg;
                                    }}
                                    alt="deliveryboy"
                                />
                            </td>
                            <td>{person.firstName} {person.lastName}</td>
                            <td>{person.email}</td>
                            <td>{person.mobileNumber}</td>
                            <td>
                                <button
                                    className={`${CategoryClasses['edit-button']} ${CategoryClasses['button']}`}
                                    onClick={() => handleEditDeliveryBoyClick(person.deliveryPersonId)}
                                >
                                    <FaRegEdit />
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    return (
        <Layout Active={'DeliveryBoyManagement'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Delivery Boy Management</title>
            </Helmet>
            <div className={UserClasses['main-first-container']}>
                <div className={ProductClasses['main-add-btn']}>
                    <Button variant="contained" color="primary" onClick={handleAddDeliveryBoyClick} data-aos="zoom-in">
                        Add Delivery Boy <CiSquarePlus className="CiSquarePlus" />
                    </Button>
                </div>
                <div className={UserClasses['main-data-tab']}>
                    <div className={UserClasses['users-main']}>
                        <h2 className={OrderClasses['header-form']}>
                            Delivery Boy Management <span className={UserClasses['head-count-main']}>({totalCount})</span>
                        </h2>

                        <div>
                            <SkeletonTheme color='#ddd' highlightColor='#eee'>
                                {loading ? (
                                    <UsersSkeleton TableHeader={deliveryTableHeads} />
                                ) : (
                                    <div style={{ overflowY: 'hidden' }}>
                                        {renderTable()}
                                    </div>
                                )}
                            </SkeletonTheme>
                            <div className={UserClasses['table-pagination']}>
                                <div className={UserClasses['current-page']}>
                                    <p>Current Page: {page + 1}</p>
                                </div>
                                <TablePagination
                                    component="div"
                                    count={accounts.length}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={rowsPerPage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default DeliveryBoyManagement;
