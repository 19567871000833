import React, { useState, useEffect } from 'react';
import Layout from 'Components/Layout';
import UserClasses from '../users/Users.module.css';
import ProductClasses from '../products/Products.module.css';
import OrderClasses from '../orders/Orders.module.css';
import { Helmet } from 'react-helmet';
import { Button, Box, IconButton } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import DeliveryClasses from './AddDeliveryBoy.module.css';
import { BsCloudUpload, BsEye, BsEyeSlash } from 'react-icons/bs';
import ReactLoading from 'react-loading';
import S3 from 'react-s3';
import { Services } from 'Services';
import { Success, Error } from 'Util/Toast';

const EditDeliveryBoy = () => {
    const navigate = useNavigate();
    const { deliveryPersonId } = useParams();

    const [image, setImage] = useState('');
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    // Initialize formData state with default values or with location.state.person if available
    const initialFormData = {
        firstName: '',
        lastName: '',
        email: '',
        mobileNumber: '',
        password: '',
        imageId: '',
        role: '',
        salaryPerMonth: ''
    };

    const [formData, setFormData] = useState(initialFormData);

    useEffect(() => {
        const token = { Authorization: `token ${localStorage.getItem('Token')}` };

        // Fetch delivery person details by ID
        Services.getDeliveryPersonById(deliveryPersonId, token)
            .then((response) => {
                if (response.Status === 1) {
                    const deliveryPerson = response.DeliveryPerson;
                    setFormData({
                        firstName: deliveryPerson.deliveryPersonFirstName,
                        lastName: deliveryPerson.deliveryPersonLastName,
                        email: deliveryPerson.deliveryPersonEmail,
                        mobileNumber: deliveryPerson.deliveryPersonPhone,
                        password: deliveryPerson.password,
                        role: deliveryPerson.deliveryPersonRole.toString(), // Assuming role is a number
                        salaryPerMonth: deliveryPerson.deliveryPersonSalary.toString(), // Assuming salaryPerMonth is a number
                        imageId: deliveryPerson.deliveryPersonImageId,
                    });

                    setImage(deliveryPerson.deliveryPersonImagePreview);
                } else {
                    console.error('Error fetching delivery person:', response);
                    Error('Failed to fetch delivery person details');
                }
            })
            .catch((error) => {
                console.error('Error fetching delivery person:', error);
                Error('Failed to fetch delivery person details');
            });
    }, [deliveryPersonId]);

    // Handle form input changes with validations
    const handleChange = (event) => {
        const { name, value } = event.target;
        let updatedValue = value;

        // Validation using regex patterns
        switch (name) {
            case 'email':
                // Basic email format validation
                updatedValue = value.trim();
                break;
            case 'mobileNumber':
                // Mobile number format validation (allowing only 10 digits)
                updatedValue = value.replace(/\D/g, '').slice(0, 10);
                break;
            case 'password':
                // Password length validation (at least 6 characters)
                updatedValue = value;
                break;

            case 'salaryPerMonth':
                // Salary should not be negative
                updatedValue = value >= 0 ? value : formData.salaryPerMonth;
                break;
            default:
                updatedValue = value;
                break;
        }

        // Update formData with validated value
        setFormData({ ...formData, [name]: updatedValue });
    };

    // Config for AWS S3
    const config = {
        bucketName: process.env.REACT_APP_BUCKETNAME,
        dirName: 'delivery-persons',
        region: 'ap-south-1',
        accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
    };

    // Handle form submission
    const handleSubmit = (event) => {
        event.preventDefault();
        const token = { Authorization: `token ${localStorage.getItem('Token')}` };

        setLoading(true);

        // Update delivery person API call
        Services.editDeliveryPerson(deliveryPersonId, formData, token)
            .then((response) => {
                if (response.Status) {
                    navigate('/deliveryboymanagement');
                    Success(response.Message);
                    console.log('Delivery person updated successfully:', response);
                } else {
                    Error(response.Message);
                }
            })
            .catch((error) => {
                console.error('Error updating delivery person:', error);
                Error('Failed to update delivery person');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Handle file upload
    const filehandleChange = (event) => {
        const files = event.target.files[0];
        if (
            files.type === 'image/png' ||
            files.type === 'image/jpeg' ||
            files.type === 'image/webp'
        ) {
            const timestamp = Date.now();
            const newfile = new File(
                [files],
                `${timestamp}.${String(files.type).split('/')[1]}`
            );

            setLoading(true);

            S3.uploadFile(newfile, {
                ...config,
                dirName: 'delivery-persons',
            })
                .then((res) => {
                    setImage(res.location);
                    setLoading(false);
                    setFormData((prev) => ({ ...prev, imageId: `${timestamp}.${String(files.type).split('/')[1]}` }));
                })
                .catch((err) => {
                    alert(err);
                    console.log(err);
                    setLoading(false);
                });
        } else {
            alert(`${files.name} is neither PNG nor jpeg`);
        }
    };

    // UI for file upload
    const fileuploadDesign = (
        <div className={DeliveryClasses["add-item"]} onClick={() => document.getElementById("image").click()}>
            <BsCloudUpload className={DeliveryClasses["upload-icon"]} />
            <h5>Drag Your Files here</h5>
            <p>(Only *.jpeg ,*.png will be accepted)</p>
            <input type="file" id="image" onChange={filehandleChange} style={{ display: 'none' }} />
        </div>
    );

    // Toggle password visibility
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <Layout Active={'DeliveryBoyManagement'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Edit Delivery Boy</title>
            </Helmet>
            <div className={UserClasses['main-first-container']}>
                <div className={UserClasses['main-data-tab']}>
                    <div className={UserClasses['add-users-btn']}>
                        <Button variant="contained" color="primary" onClick={() => navigate(-1)}>
                            BACK
                        </Button>
                    </div>
                    <div className={UserClasses['users-main']}>
                        <h2 className={OrderClasses['header-form']}>
                            Edit Delivery Boy
                        </h2>
                        <form className={DeliveryClasses['form-container']} onSubmit={handleSubmit} data-aos="fade-up"
                            data-aos-anchor-placement="top-bottom">
                            <Box mb={2}>
                                <input
                                    className={DeliveryClasses['form-textfields']}
                                    name="firstName"
                                    placeholder="First Name"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                />
                            </Box>
                            <Box mb={2}>
                                <input
                                    className={DeliveryClasses['form-textfields']}
                                    name="lastName"
                                    placeholder="Last Name"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                />
                            </Box>
                            <Box mb={2}>
                                <input
                                    className={DeliveryClasses['form-textfields']}
                                    name="email"
                                    placeholder="Email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    type="email"
                                />
                            </Box>
                            <Box mb={2}>
                                <input
                                    className={DeliveryClasses['form-textfields']}
                                    name="mobileNumber"
                                    placeholder="Mobile Number"
                                    value={formData.mobileNumber}
                                    onChange={handleChange}
                                    type="tel"  // Using type="tel" for better mobile number support
                                />
                            </Box>
                            <Box mb={2}>
                                <div style={{ position: 'relative' }}>
                                    <input
                                        className={DeliveryClasses['form-textfields']}
                                        name="password"
                                        placeholder="Password"
                                        type={showPassword ? "text" : "password"}
                                        value={formData.password}
                                        onChange={handleChange}
                                        minLength={6}
                                    />
                                    <IconButton
                                        style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}
                                        onClick={togglePasswordVisibility}
                                    >
                                        {!showPassword ? <BsEyeSlash /> : <BsEye />}
                                    </IconButton>
                                </div>
                            </Box>
                            <Box mb={2}>
                                <select
                                    className={DeliveryClasses['form-textfields']}
                                    name="role"
                                    value={formData.role}
                                    onChange={handleChange}
                                >
                                    <option value="">Select Role</option>
                                    <option value="1">Delivery Boy</option>
                                </select>
                            </Box>
                            <Box mb={2}>
                                <input
                                    className={DeliveryClasses['form-textfields']}
                                    name="salaryPerMonth"
                                    placeholder="Salary Per Month"
                                    value={formData.salaryPerMonth}
                                    onChange={handleChange}
                                    type="number"
                                />
                            </Box>
                            <div className={DeliveryClasses['file-upload-wrapper']}>
                                <header>
                                    <h3>
                                        Upload Image
                                    </h3>
                                </header>
                                <div className={DeliveryClasses["upload-image"]}>
                                    {fileuploadDesign}
                                </div>
                                {image && <img src={image} alt="Uploaded" height={150} width={150} />}
                            </div>
                            <Box mt={2}>
                                <div className={ProductClasses['form-btn-main']}>
                                    {loading && <ReactLoading type="spin" color="#000" height={50} width={50} />}
                                    <Button type="submit" disabled={loading}>Submit</Button>
                                </div>
                            </Box>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default EditDeliveryBoy;
