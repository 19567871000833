// AddCategory.js

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import UserClasses from '../users/Users.module.css';
import Layout from 'Components/Layout';
import { Helmet } from 'react-helmet';
import CategoryClasses from '../categories/AddCategory.module.css';
import S3 from 'react-s3';
import { Services } from 'Services';
import { useNavigate } from 'react-router-dom';
import { Success, Error } from 'Util/Toast';
import { Button } from '@mui/material';
import CommonCategoryClasses from '../commoncssfiles/CategoryCommon.module.css';
import CommonTutorClasses from '../commoncssfiles/CategoryTutor.module.css';
import { BsCloudUpload } from 'react-icons/bs';
import ReactLoading from 'react-loading';
import ProductClasses from '../products/Products.module.css';

window.Buffer = window.Buffer || require('buffer').Buffer;

const AddBanner = () => {
    const navigate = useNavigate();
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const [base64, setBase64] = useState('');
    const [imagePreview, setImagePreview] = useState('');
    const [loading, setLoading] = useState(false);

    const config = {
        bucketName: process.env.REACT_APP_BUCKETNAME,
        dirName: 'banners',
        region: 'ap-south-1',
        accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
    };

    const filehandleChange = async (event) => {
        const files = event.target.files[0];
        if (
            files.type === 'image/png' ||
            files.type === 'image/jpeg' ||
            files.type === 'image/webp'
        ) {
            try {
                const timestamp = Date.now();
                const newfile = new File(
                    [files],
                    `${timestamp}.${String(files.type).split('/')[1]}`
                );

                setLoading(true);
                const res = await S3.uploadFile(newfile, config);
                setBase64(res.location);
                setImagePreview(URL.createObjectURL(files)); // Set image preview
                setValue('imageUrl', `${timestamp}.${String(files.type).split('/')[1]}`); // Set only the file name
                setLoading(false);
            } catch (error) {
                console.error('Error uploading image:', error);
                setLoading(false);
                Error('Error uploading image');
            }
        } else {
            alert(`${files.name} is neither PNG nor jpeg`);
        }
    };

    const onSubmit = async (data) => {
        if (!data.imageUrl) {
            // Show error message if image is not uploaded
            Error('Please upload an image.');
            return;
        }

        setLoading(true);

        const requestBody = {
            bannerName: data.bannerName,
            imageUrl: data.imageUrl, // Use the endpoint file name
            priority: parseInt(data.priority), // Use the priority value from the form
        };

        const token = { Authorization: `token ${localStorage.getItem('Token')}` };

        try {
            const response = await Services.addBanner(requestBody, token);
            if (response.Status === 1) {
                navigate('/banners');
                Success(response.Message);
                console.log('Banner added successfully:', response.Message);
            } else {
                Error(response.Message);
                console.error('Error adding banner:', response.Message);
            }
        } catch (error) {
            console.error('Error adding banner:', error);
        } finally {
            setLoading(false); // Set loading to false after API call
        }
    };

    const fileuploadDesign = (
        <div className={CommonTutorClasses["add-item"]} onClick={() => document.getElementById("image").click()}>
            <div className={CommonTutorClasses['upload-image']}>
                {imagePreview && <img src={imagePreview} alt="Selected" className={CommonTutorClasses['uploaded-coupon-image']} />}
                {loading && <ReactLoading className={ProductClasses['loading-spinner-container']} type="spin" color="#000" height={50} width={50} />} {/* Loading spinner */}
                {!imagePreview && (
                    <div className={CommonTutorClasses['add-item']}>
                        <BsCloudUpload className={CommonTutorClasses['upload-icon']} />
                        <h5>Drag Your Files here</h5>
                        <p>(Only *.jpeg, *.png will be accepted)</p>
                    </div>
                )}
                <input type="file" id="image" onChange={filehandleChange} style={{ display: 'none' }} />
            </div>
        </div>
    );

    return (
        <Layout Active={'Banners'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Create Banner</title>
            </Helmet>
            <div className={UserClasses['main-first-container']}>
                <div className={UserClasses['main-data-tab']}>
                    <div className={UserClasses['add-users-btn']}>
                        <Button variant="contained" color="primary" onClick={() => navigate(-1)}>
                            BACK
                        </Button>
                    </div>
                    <div className={CommonTutorClasses['wrapper']} data-aos="zoom-in-down">
                        <h2 className={CommonTutorClasses['main-header']}>Create Banner</h2>
                        <form style={{display: 'block'}} className={CommonTutorClasses["form"]} onSubmit={handleSubmit(onSubmit)}>
                            <div style={{marginBottom: '10px'}} className={CommonTutorClasses['form-control']}>
                                <label htmlFor="bannerName">Banner Name</label>
                                <input style={{width: '97%'}} type='text' {...register('bannerName', { required: 'Banner name is required' })} />
                                {errors.bannerName && <p className={CategoryClasses["error-text"]}>{errors.bannerName.message}</p>}
                            </div>
                            <div style={{marginBottom: '10px'}} className={CommonTutorClasses['form-control']}>
                                <label htmlFor="priority">Priority</label>
                                <input
                                    style={{width: '97%'}}
                                    type='number'
                                    {...register('priority', { required: 'Priority is required', valueAsNumber: true })}
                                />
                                {errors.priority && <p className={CategoryClasses["error-text"]}>{errors.priority.message}</p>}
                            </div>
                            <div className={CommonTutorClasses['file-upload-wrapper']}>
                                <header>
                                    <h3>
                                        Upload Banner Image <span className="important">*</span>
                                    </h3>
                                </header>
                                <div className={CommonTutorClasses["upload-image"]} required>
                                    {fileuploadDesign}
                                </div>
                                {errors.imageUrl && <p className="error-message">Please upload a Banner image.</p>}
                            </div>
                            <div className={ProductClasses['form-btn-main']}>
                                <Button style={{marginTop: '10px'}} type="submit" disabled={loading}>Add Banner</Button> {/* Disable button when loading */}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default AddBanner;
