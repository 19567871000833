import Layout from 'Components/Layout';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import UserClasses from '../users/Users.module.css';
import { Services } from 'Services';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography, Select, MenuItem, Button, Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Paper, IconButton, Collapse, Box } from '@mui/material';
import { Success } from 'Util/Toast';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import OrderDetailStyles from './OrderDetails.module.css';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const AdditionalOrderDetails = () => {
    const [user, setUser] = useState(null);
    const [basketDetails, setBasketDetails] = useState(null);
    const [orderDetails, setOrderDetails] = useState(null);
    const [address, setAddress] = useState(null);
    const [selectedOrderStatus, setSelectedOrderStatus] = useState('');
    const [additionalOrders, setAdditionalOrders] = useState([]);
    const [OrderItems, setOrderItems] = useState([]);
    const { orderId } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const token = { Authorization: `token ${localStorage.getItem('Token')}` };
        Services.getAdditionalOrderDetails(orderId, token)
            .then(response => {
                const { OrderDetails, address, orderStatus, OrderItems } = response;
                setUser({
                    userId: OrderDetails.userId,
                    fullName: OrderDetails.fullname,
                    mobileNumber: OrderDetails.mobileNumber,
                    address
                });
                setBasketDetails({
                    basketName: OrderDetails.basketId,
                    basketPrice: OrderDetails.basketPrice
                });
                setOrderDetails(OrderDetails);
                setAddress(OrderDetails.address);
                setSelectedOrderStatus(OrderDetails.orderStatus.toString());
                setAdditionalOrders(OrderItems || []);
                setOrderItems(OrderItems || []);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching order details:', error);
                setLoading(false);
            });
    }, [orderId]);

    const Row = (props) => {
        const { order, index } = props;
        const [open, setOpen] = useState(false);

        console.log(order)
    
        return (
            <React.Fragment>
                <TableRow>
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell>
                        <Typography variant="body1">{`Order Item ${index + 1}`}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="body1">{order.productName}</Typography>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <Table size="small" aria-label="order-item-details">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Product</TableCell>
                                            <TableCell>Metric Type & Value</TableCell>
                                            <TableCell>Price</TableCell>
                                            <TableCell>Image</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                <Typography variant="body1">{order.productName}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="body2">{order.metricValue} {order.metricType}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="body2">₹{order.price}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <img src={order.imageUrl} alt={order.productName} className={OrderDetailStyles['product-image']} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    };
    
    

    return (
        <Layout Active={'Orders'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Order Details</title>
            </Helmet>
            <div className={UserClasses['main-first-container']}>
                <div className={UserClasses['main-data-tab']}>
                    <div className={UserClasses['add-users-btn']}>
                        <Button variant="contained" color="primary" onClick={() => navigate(-1)}>
                            BACK
                        </Button>
                    </div>
                    <div className={UserClasses['users-main']}>
                        <div>
                            <SkeletonTheme color='#ddd' highlightColor='#eee'>
                                {loading ? (
                                    <div style={{ display: 'flex', justifyContent: 'center' }} className={UserClasses['prod-main-skel']}>
                                        <Skeleton height={'8rem'} width={'900px'} count={3} />
                                        {/* Add more skeletons as needed */}
                                    </div>
                                ) : (
                                    <div className={OrderDetailStyles['order-details-container']}>
                                        {user && (
                                            <div className={OrderDetailStyles['user-details-container']}>
                                                <Typography className={OrderDetailStyles['head-every-data']} variant="h6">User Details</Typography>
                                                <Typography className={OrderDetailStyles['para-two-para']} variant="body1"><span className={OrderDetailStyles['head-every-para']}>User ID:</span>{' '}{user.userId || 'NA'}</Typography>
                                                <Typography className={OrderDetailStyles['para-two-para']} variant="body1"><span className={OrderDetailStyles['head-every-para']}>Name:</span>{' '}{user.fullName || 'NA'}</Typography>
                                                <Typography className={OrderDetailStyles['para-two-para']} variant="body1"><span className={OrderDetailStyles['head-every-para']}>Mobile:</span>{' '}{user.mobileNumber || 'NA'}</Typography>
                                            </div>
                                        )}
                                        {address && (
                                            <div className={OrderDetailStyles['basket-details-container']}>
                                                <Typography className={OrderDetailStyles['head-every-data']} variant="h6">Address</Typography>
                                                <Typography className={OrderDetailStyles['para-two-para']} variant="body1"><span className={OrderDetailStyles['head-every-para']}>Flat No:</span> {orderDetails.address.flatNo || 'NA'}</Typography>
                                                <Typography className={OrderDetailStyles['para-two-para']} variant="body1"><span className={OrderDetailStyles['head-every-para']}>Area Name:</span> {orderDetails.address.areaName || 'NA'}</Typography>
                                                <Typography className={OrderDetailStyles['para-two-para']} variant="body1"><span className={OrderDetailStyles['head-every-para']}>City:</span> {orderDetails.address.city || 'NA'}</Typography>
                                                <Typography className={OrderDetailStyles['para-two-para']} variant="body1"><span className={OrderDetailStyles['head-every-para']}>State:</span> {orderDetails.address.state || 'NA'}</Typography>
                                                <Typography className={OrderDetailStyles['para-two-para']} variant="body1"><span className={OrderDetailStyles['head-every-para']}>Pincode:</span> {orderDetails.address.pincode || 'NA'}</Typography>
                                            </div>
                                        )}
                                        {orderDetails && (
                                            <div className={OrderDetailStyles['basket-details-container']}>
                                                <Typography className={OrderDetailStyles['head-every-data']} variant="h6">Basket Details</Typography>
                                                <Typography className={OrderDetailStyles['para-two-para']} variant="body1"><span className={OrderDetailStyles['head-every-para']}>Basket Name:</span> {orderDetails.basketName || 'NA'}</Typography>
                                                <Typography className={OrderDetailStyles['para-two-para']} variant="body1"><span className={OrderDetailStyles['head-every-para']}>Basket Price:</span> ₹{orderDetails.basketPrice || 'NA'}</Typography>
                                                <Typography className={OrderDetailStyles['para-two-para']} variant="body1"><span className={OrderDetailStyles['head-every-para']}>Discount:</span> ₹{orderDetails.discount || 'NA'}</Typography>
                                                <Typography className={OrderDetailStyles['para-two-para']} variant="body1"><span className={OrderDetailStyles['head-every-para']}>Expected Delivery:</span> {orderDetails.expectedDelivery || 'NA'}</Typography>
                                            </div>
                                        )}
                                        <div className={OrderDetailStyles['additional-orders-container']}>
                                            <Typography className={OrderDetailStyles['head-every-data']} variant="h6">Additional Orders</Typography>
                                            <TableContainer component={Paper}>
                                                <Table aria-label="collapsible table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell />
                                                            <TableCell>Order Items</TableCell>
                                                            <TableCell></TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                    {OrderItems?.map((order, index) => (
                                                        <Row key={index} order={order} index={index} />
                                                    ))}
                                                </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    </div>
                                )}
                            </SkeletonTheme>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default AdditionalOrderDetails;
