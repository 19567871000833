import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import UserClasses from '../users/Users.module.css';
import Layout from 'Components/Layout';
import { Helmet } from 'react-helmet';
import CategoryClasses from '../categories/AddCategory.module.css';
import { Services } from 'Services';
import { useNavigate, useLocation } from 'react-router-dom';
import { Success, Error } from 'Util/Toast';
import { Button } from '@mui/material';
import CommonTutorClasses from '../commoncssfiles/CategoryTutor.module.css';
import ProductClasses from '../products/Products.module.css';

const EditHelpNumber = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const [loading, setLoading] = useState(false);

    // Get help number from state
    const helpNumber = state?.helpNumber || '';

    // Pre-fill the form with existing data
    useEffect(() => {
        setValue('HelpNumber', helpNumber);
    }, [helpNumber, setValue]);

    // Handle form submission
    const onSubmit = async (data) => {
        setLoading(true);

        const requestBody = {
            helpNumber: data.HelpNumber,  // Adjusted field name
        };

        const token = { Authorization: `token ${localStorage.getItem('Token')}` };

        try {
            const response = await Services.addOrUpdateHelpNumber(requestBody, token);  // Adjusted service call
            if (response.Status === 1) {
                navigate('/helpnumber');  // Adjusted route if needed
                Success('Help number updated successfully!');
            } else {
                console.error('Error updating help number:', response.Message);
                Error(response.Message);
            }
        } catch (error) {
            console.error('Error updating help number:', error);
            Error('An error occurred while updating the help number.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Layout Active={'HelpNumber'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Edit Help Number</title>
            </Helmet>
            <div className={UserClasses['main-first-container']}>
                <div className={UserClasses['main-data-tab']}>
                    <div className={UserClasses['add-users-btn']}>
                        <Button variant="contained" color="primary" onClick={() => navigate(-1)}>
                            BACK
                        </Button>
                    </div>
                    <div className={CommonTutorClasses['wrapper']} data-aos="zoom-in-down">
                        <h2 className={CommonTutorClasses['main-header']}>Edit Help Number</h2>
                        <form style={{ display: 'block' }} className={CommonTutorClasses["form"]} onSubmit={handleSubmit(onSubmit)}>
                            <div style={{ marginBottom: '10px' }} className={CommonTutorClasses['form-control']}>
                                <label htmlFor="HelpNumber">Help Number</label>
                                <input
                                    type='text'
                                    {...register('HelpNumber', {
                                        required: 'Help number is required',
                                        pattern: {
                                            value: /^[0-9]{10}$/,
                                            message: 'Help number must be a 10-digit number'
                                        }
                                    })}
                                    style={{ width: '97%' }}
                                />
                                {errors.HelpNumber && <p className={CategoryClasses["error-text"]}>{errors.HelpNumber.message}</p>}
                            </div>
                            <div className={ProductClasses['form-btn-main']}>
                                <Button style={{ marginTop: '10px' }} type="submit" disabled={loading}>
                                    {loading ? 'Updating...' : 'Update Help Number'}
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default EditHelpNumber;
