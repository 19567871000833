import React, { useEffect, useState } from 'react';
import Layout from 'Components/Layout';
import UserClasses from '../users/Users.module.css';
import { Helmet } from 'react-helmet';
import OrderClasses from '../orders/Orders.module.css';
import ProductClasses from '../products/Products.module.css';
import { Button, TextField, Modal, Backdrop, Fade, Select, MenuItem, FormControl } from '@mui/material';
import { Services } from 'Services';
import { useNavigate } from 'react-router-dom';
import { Success, Error } from 'Util/Toast';
import { FaRegEdit } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import CategoryClasses from '../categories/AddCategory.module.css';
import CouponClasses from '../coupons/Coupons.module.css';
import UsersSkeleton from '../users/UsersSkeleton';
import { subscriptionPlanTableHeads } from '../constants/Constants';
import TablePagination from '@mui/material/TablePagination';

const SubscriptionPlans = () => {
    const [data, setData] = useState([]); // State to store plans
    const [loading, setLoading] = useState(true); // State to manage loading state
    const [open, setOpen] = useState(false); // State to manage modal visibility
    const [formData, setFormData] = useState({}); // State to manage form data
    const [selectedPlanId, setSelectedPlanId] = useState(null); // State to store the selected plan ID

    // Retrieve initial page and rows per page from local storage
    const [page, setPage] = useState(() => parseInt(localStorage.getItem('currentPlanPage')) || 0);
    const [rowsPerPage, setRowsPerPage] = useState(() => parseInt(localStorage.getItem('planRowsPerPage')) || 10);

    const navigate = useNavigate();

    useEffect(() => {
        // Function to fetch plans and update state
        const fetchPlans = async () => {
            try {
                const token = { Authorization: `token ${localStorage.getItem('Token')}` };
                const response = await Services.getPlans(token); // Call getPlans API
                setData(response.data); // Update state with fetched plans
                setLoading(false); // Set loading state to false
            } catch (error) {
                console.error('Error fetching plans:', error);
                setLoading(false); // Set loading state to false in case of error
                Error('Failed to fetch plans.'); // Show error toast
            }
        };

        fetchPlans(); // Call fetchPlans function when component mounts
    }, []);

    const handleCreatePlan = () => {
        navigate('/addsubscription');
    }

    const handlePlanDetails = (subscriptionPlanId) => {
        navigate(`/admin/plans/subscriptionPlanId/${subscriptionPlanId}`);
    }

    const handleEdit = (planId, e) => {
        e.stopPropagation(); // Prevent the table row click event
        const selectedPlan = data.find(plan => plan.subscriptionPlanId === planId); // Find the clicked plan from data array
        if (selectedPlan) {
            setFormData({
                planName: selectedPlan.planName,
                basicPlanAmount: selectedPlan.basicPlanAmount,
                basicAdultCount: selectedPlan.basicAdultCount,
                basicChildCount: selectedPlan.basicChildCount,
                extraAdultAmount: selectedPlan.extraAdultAmount,
                extraChildAmount: selectedPlan.extraChildAmount
            });
            setSelectedPlanId(planId); // Set the selected plan ID
            setOpen(true); // Open the modal
        }
    }

    const handleClose = () => {
        setOpen(false); // Close the modal
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleFormSubmit = async () => {
        try {
            const token = { Authorization: `token ${localStorage.getItem('Token')}` };
            const response = await Services.updatePlan(selectedPlanId, formData, token); // Call updatePlan API
            handleClose(); // Close the modal

            // Fetch the updated plans data and update the state to reflect the changes
            const updatedResponse = await Services.getPlans(token);
            setData(updatedResponse.data);
            Success(response.Message);
        } catch (error) {
            console.error('Error updating plan:', error);
            Error('Failed to update plan.'); // Show error toast
        }
    }

    const handleDelete = async (planId, e) => {
        e.stopPropagation(); // Prevent the table row click event
        try {
            const token = { Authorization: `token ${localStorage.getItem('Token')}` };
            // Call the deactivatePlan API to delete the plan
            await Services.deactivatePlan(planId, token);
            // Remove the deleted plan from the data state
            const updatedData = data.filter(plan => plan.subscriptionPlanId !== planId);
            setData(updatedData);
            Success("Plan deleted successfully");
        } catch (error) {
            console.error('Error deleting plan:', error);
            Error('Failed to delete plan.'); // Show error toast
        }
    }

    const handleStatusChange = async (planId, newStatus, e) => {
        // Prevent event propagation
        e.stopPropagation();

        try {
            const token = { Authorization: `token ${localStorage.getItem('Token')}` };
            const status = newStatus === 'Active' ? 1 : 0; // Convert "Active" to 1 and "Inactive" to 0
            await Services.changePlanStatus(planId, status, token); // Call the API

            // Update the status in the local state
            setData(prevData =>
                prevData.map(plan =>
                    plan.subscriptionPlanId === planId ? { ...plan, status } : plan
                )
            );
            Success(`Plan status changed to ${newStatus}`);
        } catch (error) {
            console.error('Error changing plan status:', error);
            Error('Failed to change plan status.'); // Show error toast
        }
    };

    // Handle page change
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        // Persist the current page in local storage
        localStorage.setItem('currentPlanPage', newPage);
    };

    // Handle rows per page change
    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        // Reset the current page to 0 when rows per page changes
        setPage(0);
        // Persist the rows per page in local storage
        localStorage.setItem('planRowsPerPage', newRowsPerPage);
    };

    // Calculate the indices for the current page
    const indexOfLastItem = (page + 1) * rowsPerPage;
    const indexOfFirstItem = indexOfLastItem - rowsPerPage;

    // Slice data to get items for the current page
    const currentPlans = data.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <Layout Active={'SubscriptionPlans'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Subscription Plans</title>
            </Helmet>
            <div className={UserClasses['main-first-container']}>
                <div className={ProductClasses['main-add-btn']}>
                    <Button variant="contained" color="primary" onClick={handleCreatePlan}>
                        Create Plan
                    </Button>
                </div>
                <div className={UserClasses['main-data-tab']}>
                    <div className={UserClasses['users-main']}>
                        <div>
                            <h2 className={OrderClasses['header-form']}>
                                Subscription Plans
                            </h2>
                            {loading ? (
                                <UsersSkeleton TableHeader={subscriptionPlanTableHeads} />
                            ) : (
                                <div>
                                    {currentPlans.length > 0 ? (
                                        <table className={ProductClasses['category-table']}>
                                            <thead>
                                                <tr className={UserClasses['main-table-heads']}>
                                                    {subscriptionPlanTableHeads.map((head, index) => (
                                                        <th key={index} className={ProductClasses['products-table-head']}>{head}</th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentPlans.map(plan => (
                                                    <tr key={plan.id} onClick={() => handlePlanDetails(plan.subscriptionPlanId)}>
                                                        <td>{plan.planName}</td>
                                                        <td>{plan.basicPlanAmount}</td>
                                                        <td>{plan.createdAt}</td>
                                                        <td>{plan.planLimitDays}</td>
                                                        <td>
                                                            <FormControl onClick={(e) => e.stopPropagation()} variant="outlined" size="small">
                                                                <Select
                                                                    className={CouponClasses['coupon-drop']}
                                                                    value={plan.status === 0 ? 'Inactive' : 'Active'}
                                                                    onChange={(e) => handleStatusChange(plan.subscriptionPlanId, e.target.value, e)}
                                                                >
                                                                    <MenuItem value="Active">Active</MenuItem>
                                                                    <MenuItem value="Inactive">Inactive</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </td>
                                                        <td>
                                                            <button className={`${CategoryClasses['edit-button']} ${CategoryClasses['button']}`} onClick={(e) => handleEdit(plan.subscriptionPlanId, e)}><FaRegEdit /></button>
                                                            <button className={`${CategoryClasses['delete-button']} ${CategoryClasses['button']}`} onClick={(e) => handleDelete(plan.subscriptionPlanId, e)}><AiFillDelete /></button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    ) : (
                                        <p>No Records Found</p>
                                    )}

                                    {/* Add TablePagination component */}
                                    <div className={UserClasses['table-pagination']}>
                                        <div className={UserClasses['current-page']}>
                                            <p>Current Page: {page + 1}</p>
                                        </div>
                                        <TablePagination
                                            component="div"
                                            count={data.length}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            rowsPerPage={rowsPerPage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={ProductClasses['modal']}>
                        <h2>Edit Plan</h2>
                        <div className={ProductClasses['modal-content']}>
                            <div className={ProductClasses['modal-input']}>
                                <TextField
                                    label="Plan Name"
                                    name="planName"
                                    value={formData.planName || ''}
                                    onChange={handleInputChange}
                                    fullWidth
                                />
                            </div>
                            <div className={ProductClasses['modal-input']}>
                                <TextField
                                    label="Basic Plan Amount"
                                    name="basicPlanAmount"
                                    value={formData.basicPlanAmount || ''}
                                    onChange={handleInputChange}
                                    fullWidth
                                />
                            </div>
                            <div className={ProductClasses['modal-input']}>
                                <TextField
                                    label="Basic Adult Count"
                                    name="basicAdultCount"
                                    value={formData.basicAdultCount || ''}
                                    onChange={handleInputChange}
                                    fullWidth
                                />
                            </div>
                            <div className={ProductClasses['modal-input']}>
                                <TextField
                                    label="Basic Child Count"
                                    name="basicChildCount"
                                    value={formData.basicChildCount || ''}
                                    onChange={handleInputChange}
                                    fullWidth
                                />
                            </div>
                            <div className={ProductClasses['modal-input']}>
                                <TextField
                                    label="Extra Adult Amount"
                                    name="extraAdultAmount"
                                    value={formData.extraAdultAmount || ''}
                                    onChange={handleInputChange}
                                    fullWidth
                                />
                            </div>
                            <div className={ProductClasses['modal-input']}>
                                <TextField
                                    label="Extra Child Amount"
                                    name="extraChildAmount"
                                    value={formData.extraChildAmount || ''}
                                    onChange={handleInputChange}
                                    fullWidth
                                />
                            </div>
                        </div>
                        <div className={ProductClasses['modal-button']}>
                            <Button variant="contained" color="primary" onClick={handleFormSubmit}>
                                Submit
                            </Button>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </Layout>
    );
};

export default SubscriptionPlans;
