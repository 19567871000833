import React, { useEffect, useState } from 'react';
import Layout from 'Components/Layout';
import UserClasses from '../users/Users.module.css';
import { Helmet } from 'react-helmet';

const Configuration = () => {

  return (
    <Layout Active={'Configuration'}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Configuration</title>
      </Helmet>
      <div className={UserClasses['main-data-tab']}>
        <div className={UserClasses['users-main']}>

        </div>
      </div>
    </Layout>
  );
};

export default Configuration;
