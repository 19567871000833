export const userTableHeads = [
  'REGISTERED NAME', 
  'PHONE',
  'REGISTRATION DATE',
  'WALLET AMOUNT',
]

export const productTableHeads = [
    'PRODUCT IMAGE', 
    'PRODUCT NAME',
    'PRODUCT CATEGORY',
    'CATEGORY IMAGE',
    'DESCRIPTION',
    'ACTIONS',
  ]

export const helpNumberTableHeads = [
    'NUMBER',
    'ACTIONS',
] 

export const categoryTableHeads = [
    'IMAGE',
    'CATEGORY NAME',
    'UPDATED AT',
    'ACTIONS',
] 

export const bannerTableHeads = [
  'BANNER ID',
  'BANNER NAME',
  'BANNER IMAGE',
  'PRIORITY',
  'STATUS',
] 

export const communityTableHeads = [
  'COMMUNITY ID',
  'COMMUNITY NAME',
  'COMMUNITY AREA',
  'LAT, LONG',
  'ACTIONS',
] 

export const basketTableHeads = [
  'BASKET NAME',
  'ASSIGNED TO',
  'NO OF PRODUCTS',
  'PRICE',
  'BASKET STATUS',
  'ACTIONS',
] 

export const ordersTableHeads = [
  'CLIENT NAME',
  'ORDER STATUS',
  'CREATED AT', 
  'ASSIGN DELIVERY BOY'
] 

export const walletTableHeads = [
  'USER NAME',
  'WALLET BALANCE',
  'DATE',
  'REMAINDER',
] 

export const walletTransactionTableHeads = [
  'FULL NAME',
  'MOBILE NUMBER',
  'AMOUNT',
  'STATUS',
  'REGISTERED DATE',
  'TRANSACTION HISTORY',
]

export const couponsTableHeads = [
  'NAME',
  'CODE',
  'DISCOUNT',
  'STATUS',
  'ACTIONS',
] 

export const subscriptionPlanTableHeads = [
  'NAME',
  'AMOUNT',
  'CREATED AT',
  'PLAN LIMIT DAYS',
  'STATUS',
  'ACTIONS',
]

export const complaintTableHeads = [
  'USERNAME',
  'DESCRIPTION',
  'MOBILE NUMBER',
  'STATUS',
  'ATTACHMENTS',
  'COMMENTS',
]

export const deliveryTableHeads = [
  'PHOTO',
  'NAME',
  'EMAIL',
  'MOBILE NUMBER',
  'ACTION'
]