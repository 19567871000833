import React, { useEffect, useState } from 'react';
import UserClasses from '../users/Users.module.css';
import ProductClasses from '../products/Products.module.css';
import Layout from 'Components/Layout';
import { Helmet } from 'react-helmet';
import { Services } from 'Services';
import { useNavigate } from 'react-router-dom';
import CategoryClasses from '../categories/AddCategory.module.css';
import { Button, TablePagination } from '@mui/material';
import OrderClasses from '../orders/Orders.module.css';
import { CiSquarePlus } from 'react-icons/ci';
import placeholderImg from '../../../Assets//no-helpnumber1.gif'; // Import the placeholder image
import { FaRegEdit } from "react-icons/fa";
import { SkeletonTheme } from 'react-loading-skeleton';
import UsersSkeleton from '../users/UsersSkeleton';
import { helpNumberTableHeads } from '../constants/Constants';

const HelpNumber = () => {
    // State variables
    const [helpNumber, setHelpNumber] = useState(null);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalCount, setTotalCount] = useState(0);

    const navigate = useNavigate();

    // Function to handle the Add Help Number click event
    const handleAddHelpNumberClick = () => {
        navigate('/add-helpnumber');
    };

    useEffect(() => {
        const token = { Authorization: `token ${localStorage.getItem('Token')}` };

        Services.fetchhelpnumberdata(token)
            .then((response) => {
                if (response.Status === 1 && response.HelpNumber) {
                    setHelpNumber(response.HelpNumber);
                    setTotalCount(1); // Since you have only one help number
                } else {
                    setHelpNumber(null); // No valid help number found
                    setTotalCount(0);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching help number:', error);
                setLoading(false);
            });
    }, []);

    // Handle page change for pagination
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // Handle rows per page change for pagination
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Layout Active={'HelpNumber'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Help Number</title>
            </Helmet>
            <div className={UserClasses['main-first-container']}>
                {!helpNumber && (
                    <div className={ProductClasses['main-add-btn']}>
                        <Button data-aos="zoom-in" variant="contained" color="primary" onClick={handleAddHelpNumberClick}>
                            Add Help Number <CiSquarePlus className="CiSquarePlus" />
                        </Button>
                    </div>
                )}
                <div className={UserClasses['main-data-tab']}>
                    <div className={UserClasses['users-main']}>
                        <h2 className={OrderClasses['header-form']}>
                            Help Numbers <span className={UserClasses['head-count-main']}>({totalCount})</span>
                        </h2>
                        <div>
                            <div>
                                <SkeletonTheme color='#ddd' highlightColor='#eee'>
                                    {loading ? (
                                        <UsersSkeleton TableHeader={helpNumberTableHeads} />
                                    ) : (
                                        <div style={{ overflowY: 'hidden' }}>
                                            {helpNumber ? (
                                                <table className={ProductClasses['category-table']}>
                                                    <thead>
                                                        <tr className={UserClasses['main-table-heads']}>
                                                            {helpNumberTableHeads.map((head, index) => (
                                                                <th key={index} className={ProductClasses['products-table-head']}>{head}</th>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr className={UserClasses['even-row']}>
                                                            <td>{helpNumber || 'N/A'}</td>
                                                            <td>
                                                                <button
                                                                    className={`${CategoryClasses['edit-button']} ${CategoryClasses['button']}`}
                                                                    onClick={() => navigate('/edit-helpnumber', { state: { helpNumber } })}
                                                                >
                                                                    <FaRegEdit />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            ) : (
                                                <div style={{ textAlign: 'center', padding: '20px' }}>
                                                    <img src={placeholderImg} alt="No Data" style={{ width: '100px', height: '100px' }} data-aos="zoom-in"
                                                        data-aos-duration="1000" />
                                                    <p style={{ color: 'gray' }}>No Help Numbers Found</p>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </SkeletonTheme>
                            </div>

                            {/* Add pagination component */}
                            <div className={UserClasses['table-pagination']}>
                                <div className={UserClasses['current-page']}>
                                    <p>Current Page: {page + 1}</p>
                                </div>

                                <TablePagination
                                    component="div"
                                    count={1} // Since there's only one help number, we set count to 1
                                    page={page}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={rowsPerPage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default HelpNumber;
