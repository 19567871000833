import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import ReactLoading from 'react-loading';
import { Services } from 'Services';
import { Success, Error } from 'Util/Toast';
import Layout from 'Components/Layout';
import { Helmet } from 'react-helmet';
import { BsCloudUpload } from 'react-icons/bs';
import CommonTutorClasses from '../commoncssfiles/CategoryTutor.module.css';
import UserClasses from '../users/Users.module.css';
import S3 from 'react-s3';

const EditCategory = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const [base64, setBase64] = useState('');
    const [imagePreview, setImagePreview] = useState('');
    const [loading, setLoading] = useState(false);

    const config = {
        bucketName: process.env.REACT_APP_BUCKETNAME,
        dirName: 'categories',
        region: 'ap-south-1',
        accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
    };

    // Retrieve the category object from location state
    const { category } = location.state || {};

    // Use useEffect to prefill form fields and image preview when category changes
    useEffect(() => {
        if (category) {
            setValue('CategoryName', category.categoryName);
            if (category.imageUrl) {
                setImagePreview(category.imageUrl); // Set initial image preview
                const endpoint = category.imageUrl.split('/').pop(); // Extract endpoint from URL
                setValue('imageUrl', endpoint); // Set only the endpoint in the form state
            }
        }
    }, [category, setValue]);

    const filehandleChange = async (event) => {
        const files = event.target.files[0];
        if (
            files.type === 'image/png' ||
            files.type === 'image/jpeg' ||
            files.type === 'image/webp'
        ) {
            try {
                const timestamp = Date.now();
                const newfile = new File(
                    [files],
                    `${timestamp}.${String(files.type).split('/')[1]}`
                );

                setLoading(true);
                const res = await S3.uploadFile(newfile, config);
                setBase64(res.location);
                setImagePreview(URL.createObjectURL(files)); // Set image preview
                setValue('imageUrl', `${timestamp}.${String(files.type).split('/')[1]}`); // Set only the file name
                setLoading(false);
            } catch (error) {
                console.error('Error uploading image:', error);
                setLoading(false);
                Error('Error uploading image');
            }
        } else {
            alert(`${files.name} is neither PNG nor jpeg`);
        }
    };

    const onSubmit = async (data) => {
        if (!data.imageUrl) {
            // Show error message if image is not uploaded
            Error('Please upload an image.');
            return;
        }

        const requestBody = {
            categoryName: data.CategoryName,
            imageUrl: data.imageUrl, // Use the endpoint file name
            priority: 1, // Set default priority to 1
        };

        const token = { Authorization: `token ${localStorage.getItem('Token')}` };

        try {
            const response = await Services.updateCategory(category.categoryId, requestBody, token);
            if (response.Status === 1) {
                navigate('/categories');
                Success(response.Message);
                console.log('Category updated successfully:', response.Message);
            } else {
                console.error('Error updating category:', response.Message);
            }
        } catch (error) {
            console.error('Error updating category:', error);
        }
    };

    const fileuploadDesign = (
        <div className={CommonTutorClasses["add-item"]} onClick={() => document.getElementById("image").click()}>
            <div className={CommonTutorClasses['upload-image']}>
                {imagePreview && <img src={imagePreview} alt="Selected" className={CommonTutorClasses['uploaded-coupon-image']} />}
                {!imagePreview && (
                    <div className={CommonTutorClasses['add-item']} onClick={() => document.getElementById("image").click()}>
                        <BsCloudUpload className={CommonTutorClasses['upload-icon']} />
                        <h5>Drag Your Files here</h5>
                        <p>(Only *.jpeg, *.png will be accepted)</p>
                    </div>
                )}
                <input type="file" id="image" onChange={filehandleChange} style={{ display: 'none' }} />
            </div>
        </div>
    );

    return (
        <Layout Active={'Categories'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Update Category</title>
            </Helmet>
            <div className={UserClasses['main-first-container']}>
                <div className={UserClasses['main-data-tab']}>
                    <div className={UserClasses['add-users-btn']}>
                        <Button variant="contained" color="primary" onClick={() => navigate(-1)}>
                            BACK
                        </Button>
                    </div>
                    <div className={CommonTutorClasses['wrapper']} data-aos="zoom-in-down">
                        <h2 className={CommonTutorClasses['main-header']}>Update Category</h2>
                        <form className={CommonTutorClasses["form"]} onSubmit={handleSubmit(onSubmit)}>
                            <div className={CommonTutorClasses['form-control']}>
                                <label htmlFor="CategoryName">Category Name</label>
                                <input type='text' {...register('CategoryName', { required: true })} />
                            </div>
                            <div className={CommonTutorClasses['file-upload-wrapper']}>
                                <header>
                                    <h3>
                                        Upload Category Image <span className="important">*</span>
                                    </h3>
                                </header>
                                <div className={CommonTutorClasses["upload-image"]} required>
                                    {fileuploadDesign}
                                </div>
                                {errors.CategoryImage && <p className="error-message">Please upload a category image.</p>}
                            </div>
                            <button disabled={loading}>
                                {loading ? (
                                    <ReactLoading color="green" type="spokes" height={30} width={30} />
                                ) : (
                                    'Submit'
                                )}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default EditCategory;
