import React, { useEffect, useState } from 'react';
import UserClasses from '../users/Users.module.css';
import ProductClasses from '../products/Products.module.css';
import Layout from 'Components/Layout';
import { Helmet } from 'react-helmet';
import { Services } from 'Services';
import { useNavigate } from 'react-router-dom';
import CategoryClasses from '../categories/AddCategory.module.css';
import { Success } from 'Util/Toast';
import { useForm } from 'react-hook-form';
import { FaRegEdit } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import S3 from 'react-s3';
import { SkeletonTheme } from 'react-loading-skeleton';
import UsersSkeleton from '../users/UsersSkeleton';
import { bannerTableHeads, categoryTableHeads } from '../constants/Constants';
import { RxCrossCircled } from "react-icons/rx";
import { Button, TablePagination } from '@mui/material';
import OrderClasses from '../orders/Orders.module.css';
import { CiSquarePlus } from 'react-icons/ci';
import placeholderImg from '../../../Assets/no-banners1.gif'; // Import the placeholder image

window.Buffer = window.Buffer || require('buffer').Buffer;

const Banners = () => {
    // State variables
    const [categories, setCategories] = useState([]);
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [categoryName, setEditedCategoryName] = useState('');
    const [base64, setBase64] = useState('');
    const [loading, setLoading] = useState(true);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deletingCategoryId, setDeletingCategoryId] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalCount, setTotalCount] = useState(0);

    // Form handling with react-hook-form
    const { register, control, handleSubmit, setValue, formState: { errors } } = useForm();
    const navigate = useNavigate();

    // Function to handle the Add Category click event
    const handleAddCategoryClick = () => {
        navigate('/add-banner');
    };

    // Use effect to fetch categories data on mount
    useEffect(() => {
        const token = { Authorization: `token ${localStorage.getItem('Token')}` };

        Services.getBanners(token)
            .then((response) => {
                setCategories(response.Banners || []);
                setTotalCount(response.Banners.length);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching categories:', error);
            });
    }, []);

    // Function to format date strings
    const formatDate = (dateString) => {
        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
        return formattedDate;
    };

    // Function to handle deleting a category
    const handleDeleteCategory = (bannerId) => {
        const token = { Authorization: `token ${localStorage.getItem('Token')}` };

        Services.deleteBanner(bannerId, token)
            .then((response) => {
                if (response.Status === 1) {
                    setCategories((prevCategories) =>
                        prevCategories.filter((category) => category.bannerId !== bannerId)
                    );
                    setTotalCount(totalCount - 1);
                    console.log('Category deleted successfully:', response.Message);
                    Success(response.Message);
                } else {
                    console.error('Error deleting category:', response.Message);
                }
            })
            .catch((error) => {
                console.error('Error deleting category:', error);
            });
    };

    // Function to handle Delete Category click event
    const handleDeleteCategoryClick = (bannerId) => {
        setDeletingCategoryId(bannerId);
        setDeleteModalOpen(true);
    };

    // Function to handle closing the Delete Modal
    const handleDeleteModalClose = () => {
        setDeleteModalOpen(false);
        setDeletingCategoryId(null);
    };

    // Function to handle confirming delete operation
    const handleConfirmDelete = () => {
        if (deletingCategoryId) {
            handleDeleteCategory(deletingCategoryId);
            handleDeleteModalClose();
        }
    };

    // Handle page change for pagination
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // Handle rows per page change for pagination
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Calculate index of last and first items for pagination
    const indexOfLastItem = (page + 1) * rowsPerPage;
    const indexOfFirstItem = indexOfLastItem - rowsPerPage;

    // Get current items for the current page
    const currentItems = categories.slice(indexOfFirstItem, indexOfLastItem);

    const handleEditCategoryClick = (bannerId) => {
        // Find the category by ID
        const selectedCategory = categories.find(category => category.bannerId === bannerId);

        // Navigate to the edit category page with state
        navigate(`/editbanner/${bannerId}`, { state: { category: selectedCategory } });
    };

    return (
        <Layout Active={'Banners'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Banners</title>
            </Helmet>
            <div className={UserClasses['main-first-container']}>
                <div className={ProductClasses['main-add-btn']}>
                    <Button data-aos="zoom-in" variant="contained" color="primary" onClick={handleAddCategoryClick}>
                        Add Banner <CiSquarePlus className="CiSquarePlus" />
                    </Button>
                </div>
                <div className={UserClasses['main-data-tab']}>
                    <div className={UserClasses['users-main']}>
                        <h2 className={OrderClasses['header-form']}>
                            Banners <span className={UserClasses['head-count-main']}>({totalCount})</span>
                        </h2>
                        <div>
                            <SkeletonTheme color='#ddd' highlightColor='#eee'>
                                {loading ? (
                                    <UsersSkeleton TableHeader={categoryTableHeads} />
                                ) : (
                                    <div style={{ overflowY: 'hidden' }}>
                                        {currentItems.length > 0 ? (
                                            <table className={ProductClasses['category-table']}>
                                                <thead>
                                                    <tr className={UserClasses['main-table-heads']}>
                                                        {bannerTableHeads.map((head, index) => (
                                                            <th key={index} className={ProductClasses['products-table-head']}>{head}</th>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {currentItems.map((category, index) => (
                                                        <tr key={category.categoryId} className={index % 2 === 0 ? UserClasses['even-row'] : UserClasses['odd-row']} data-aos="fade-up"
                                                            data-aos-anchor-placement="top-bottom">
                                                            <td className={CategoryClasses["table-cell"]}>{category.bannerId || 'N/A'}</td>
                                                            <td className={CategoryClasses["table-cell"]}>{category.bannerName || 'N/A'}</td>
                                                            <td><img onClick={(e) => {
                                                                e.stopPropagation();
                                                                window.open(
                                                                    category.imagePreview,
                                                                    "_blank"
                                                                );
                                                            }} src={category.imagePreview} alt={category.bannerName} className={CategoryClasses['category-images']} /></td>
                                                            <td className={CategoryClasses["table-cell"]}>{category.priority || 'N/A'}</td>
                                                            <td>
                                                                <button
                                                                    className={`${CategoryClasses['edit-button']} ${CategoryClasses['button']}`}
                                                                    onClick={() => handleEditCategoryClick(category.bannerId)}
                                                                >
                                                                    <FaRegEdit />
                                                                </button>
                                                                <button
                                                                    onClick={() => handleDeleteCategoryClick(category.bannerId)}
                                                                    className={`${CategoryClasses['delete-button']} ${CategoryClasses['button']}`}
                                                                >
                                                                    <AiFillDelete />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        ) : (
                                            <div style={{ textAlign: 'center', padding: '20px' }}>
                                                <img src={placeholderImg} alt="No Data" style={{ width: '100px', height: '100px' }} data-aos="zoom-in"
                                                    data-aos-duration="1000" />
                                                <p style={{ color: 'gray' }}>No Banners Found</p>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </SkeletonTheme>

                            {/* Add pagination component */}
                            <div className={UserClasses['table-pagination']}>
                                <div className={UserClasses['current-page']}>
                                    <p>Current Page: {page + 1}</p>
                                </div>

                                <TablePagination
                                    component="div"
                                    count={categories.length}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={rowsPerPage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Delete modal */}
                {isDeleteModalOpen && (
                    <div className={CategoryClasses['edit-modal-overlay']}>
                        <div className={CategoryClasses['edit-modal']} data-aos="zoom-in-down">
                            <div className={CategoryClasses['modal-content']}>
                                <h1><RxCrossCircled /></h1>
                                <h2>Are you sure?</h2>
                                <p>Do you really want to delete these records? This process cannot be undone.</p>
                            </div>
                            <div className={CategoryClasses['modal-last-div']}>
                                <button onClick={handleDeleteModalClose} className={CategoryClasses['modal-close-button']}>Close</button>
                                <button onClick={handleConfirmDelete} className={CategoryClasses['modal-save-button']}>Delete</button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Layout>
    );
};

export default Banners;
