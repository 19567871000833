

import Login from "../pages/Login/index"
import AccountSettings from "pages/Admin/users/Users"
import AllOrders from "pages/Admin/orders/AllOrders"
import SubscriptionPlans from "pages/Admin/subscriptions/SubscriptionPlans"
import AddSubscription from "pages/Admin/subscriptions/AddSubscription"
import AdminSettings from "pages/Admin/account/AdminSettings"
import CreateTeamMember from "pages/Admin/account/CreateTeamMember"
import Gaurd from "pages/Admin/Util/Guard"
import { AccessControl } from "pages/Admin/Util/AccessControl"
import Complaints from "pages/Admin/support/Complaints"
import Configuration from "pages/Admin/configuration/Configuration"
import CreateNewConfig from "pages/Admin/configuration/CreateNewConfig"
import Dashboard from "pages/Admin/dashboard/Dashboard"
import Products from "pages/Admin/products/Products"
import UserDetails from "pages/Admin/users/UserDetails"
import AddProduct from "pages/Admin/products/AddProduct"
import ProductDetails from "pages/Admin/products/ProductDetails"
import Categories from "pages/Admin/categories/Categories"
import AddCategory from "pages/Admin/categories/AddCategory"
import Basket from "pages/Admin/basket/Basket"
import AddBasket from "pages/Admin/basket/AddBasket"
import EditBasket from "pages/Admin/basket/EditBasket"
import GuestBasket from "pages/Admin/basket/GuestBasket"
import Wallet from "pages/Admin/wallet/Wallet"
import Coupons from "pages/Admin/coupons/Coupons"
import CreateCoupon from "pages/Admin/coupons/CreateCoupon"
import OrderDetails from "pages/Admin/orders/OrderDetails"
import EachPlan from "pages/Admin/subscriptions/EachPlan"
import EditProduct from "pages/Admin/products/EditProduct"
import Faq from "pages/Admin/support/faqs/Faq"
import EditFaq from "pages/Admin/support/faqs/EditFaq"
import AddFaq from "pages/Admin/support/faqs/AddFaq"
import WalletTransactions from "pages/Admin/wallet/WalletTransactions"
import DeliveryBoyManagement from "pages/Admin/deliveryboy/DeliveryBoyManagement"
import AddDeliveryBoy from "pages/Admin/deliveryboy/AddDeliveryBoy"
import EditCategory from "pages/Admin/categories/EditCategory"
import Communities from "pages/communities/Communities"
import AddCommunities from "pages/communities/AddCommunities"
import EditCommunities from "pages/communities/EditCommunities"
import AdditionalOrderDetails from "pages/Admin/orders/AdditionalOrderDetails"
import EditDeliveryBoy from "pages/Admin/deliveryboy/EditDeliveryBoy"
import Banners from "pages/Admin/banners/Banners"
import AddBanner from "pages/Admin/banners/AddBanner"
import EditBanner from "pages/Admin/banners/EditBanner"
import HelpNumber from "pages/Admin/support/HelpNumber"
import AddHelpNumber from "pages/Admin/support/AddHelpNumber"
import EditHelpNumber from "pages/Admin/support/EditHelpNumber"
export const AccessKeys = {
    AccountSettings: 'AccountSettings',
    Users: 'Users',
    SubscriptionPlans: 'Subscription Plans'
}


export const PageRoutes = [


 { path: '/', element: <Login /> },
 { path: '/account-settings', element: <AccountSettings />, AccessKey: AccessKeys.Users, Type: 'read' },
 { path: '/orders', element: <AllOrders /> },
 { path: '/subscriptionplans', element: <SubscriptionPlans /> },
 { path: '/addsubscription', element: <AddSubscription /> },
 { path: '/adminsettings', element: <AdminSettings />},
 { path: '/create-team-member', element: <CreateTeamMember /> },
 { path: '/guard', element: <Gaurd /> },
 { path: '/accesscontrol', element: <AccessControl /> },
 { path: '/complaints', element: <Complaints /> },
 { path: '/configuration', element: <Configuration />},
 { path: '/createnewconfig', element: <CreateNewConfig />},
 { path: '/dashboard', element: <Dashboard /> },
 { path: '/products', element: <Products /> },
 { path: '/user-details/:userId', element: <UserDetails /> },
 { path: '/add-product', element: <AddProduct />},
 { path: '/product-details/:productId', element: <ProductDetails /> },
 { path: '/banners', element: <Banners /> },
 { path: '/add-banner', element: <AddBanner />},
 { path: '/editbanner/:bannerId', element: <EditBanner />},
 { path: '/categories', element: <Categories /> },
 { path: '/add-category', element: <AddCategory />},
 { path: '/editcategory/:categoryId', element: <EditCategory />},
 { path: '/basket', element: <Basket /> },
 { path: '/add-basket', element: <AddBasket /> },
 { path: '/edit-basket/:basketId', element: <EditBasket /> },
 { path: '/guest-basket/:basketId', element: <GuestBasket /> },
 { path: '/wallet', element: <Wallet /> },
 { path: '/coupons', element: <Coupons /> },
 { path: '/create-coupon', element: <CreateCoupon /> },
 { path: '/order-details/:orderId', element: <OrderDetails /> },
 { path: 'additional-orderdetails/:orderId', element: <AdditionalOrderDetails /> },
 { path: '/admin/plans/subscriptionPlanId/:subscriptionPlanId', element: <EachPlan /> },
 { path: '/editproduct/:productId', element: <EditProduct /> },
 { path: '/faqs', element:<Faq /> },
 { path: '/edit-faq/:customerSupportId', element: <EditFaq /> },
 { path: '/add-faq', element: <AddFaq /> },
 { path: '/wallet-transactions', element: <WalletTransactions /> },
 { path: '/deliveryboymanagement', element: <DeliveryBoyManagement />},
 { path: '/add-delivery-boy', element: <AddDeliveryBoy /> },
 { path: '/communities', element: <Communities /> },
 { path: '/add-community', element: <AddCommunities /> },
 { path: '/edit-community/:communityId', element: <EditCommunities /> },
 { path: '/edit-deliveryboy/:deliveryPersonId', element: <EditDeliveryBoy /> },
 { path: '/helpnumber', element: <HelpNumber /> },
 { path: '/add-helpnumber', element: <AddHelpNumber />},
 { path: '/edit-helpnumber', element: <EditHelpNumber />},
]
