import Layout from 'Components/Layout';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import UserClasses from '../users/Users.module.css';
import { Services } from 'Services';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography, Select, MenuItem, Button, Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Paper, IconButton, Collapse, Box } from '@mui/material';
import { Success } from 'Util/Toast';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import OrderDetailStyles from './OrderDetails.module.css';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const OrderDetails = () => {
    const [user, setUser] = useState(null);
    const [basketDetails, setBasketDetails] = useState(null);
    const [orderDetails, setOrderDetails] = useState(null);
    const [address, setAddress] = useState({ city: '', street: '', postalCode: '', additionalInfo: '' });
    const [selectedOrderStatus, setSelectedOrderStatus] = useState('');
    const [additionalOrders, setAdditionalOrders] = useState([]);
    const [additionalOrdersOpen, setAdditionalOrdersOpen] = useState(false); // State for collapsible Additional Orders
    const { orderId } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [basketOpen, setBasketOpen] = useState(false); // State for collapsible Basket Details

    useEffect(() => {
        const token = { Authorization: `token ${localStorage.getItem('Token')}` };
        Services.getOrderById(orderId, token)
            .then(response => {
                setUser(response.User);
                setBasketDetails(response.BasketDetails);
                setOrderDetails(response);
                setSelectedOrderStatus(response.OrderStatus.toString());

                // Parse address string into meaningful parts
                const parsedAddress = parseAddress(response.Address);
                setAddress(parsedAddress);

                setAdditionalOrders(response.AdditionalOrders || []);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching order details:', error);
                setLoading(false);
            });
    }, [orderId]);

    const parseAddress = (addressString) => {
        const parts = addressString.split(', ');
        return {
            city: parts[4] || '',
            street: parts[2] || '',
            postalCode: parts[5] || '',
            additionalInfo: parts[6] || ''
        };
    };

    const handleOrderStatusChange = (event) => {
        setSelectedOrderStatus(event.target.value);
    };

    const handleUpdateOrderStatus = () => {
        const token = { Authorization: `token ${localStorage.getItem('Token')}` };
        Services.changeOrderStatus(orderId, parseInt(selectedOrderStatus), token)
            .then(response => {
                console.log('Order status updated successfully:', response);
                navigate('/orders');
                Success(response.Message);
            })
            .catch(error => {
                console.error('Error updating order status:', error);
            });
    };

    const Row = (props) => {
        const { order, index } = props;
        const [open, setOpen] = useState(false);

        return (
            <React.Fragment>
                <TableRow>
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell>
                        <Typography variant="body1">{`Order Item ${index + 1}`}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="body1">{order.orderName}</Typography>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                
                                <Table size="small" aria-label="order-items">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Product</TableCell>
                                            <TableCell>Metric Type & Value</TableCell>
                                            <TableCell>Price</TableCell>
                                            <TableCell>Image</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {order.orderItems && order.orderItems.map((item, itemIndex) => (
                                            <TableRow key={itemIndex} data-aos="fade-up"
                                            data-aos-anchor-placement="top-bottom">
                                                <TableCell>
                                                    <Typography variant="body1">{item.productName}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="body2">{item.metricValue}{item.metricType}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="body2">₹{item.price}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <img onClick={(e) => {
                                                        e.stopPropagation();
                                                        window.open(
                                                            item.imageUrl,
                                                            "_blank"
                                                        );
                                                    }} src={item.imageUrl} alt={item.productName} className={OrderDetailStyles['product-image']} />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    };

    const toggleAdditionalOrders = () => {
        setAdditionalOrdersOpen(!additionalOrdersOpen);
    };

    return (
        <Layout Active={'Orders'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Order Details</title>
            </Helmet>
            <div className={UserClasses['main-first-container']}>
                <div className={UserClasses['main-data-tab']}>
                    <div className={UserClasses['add-users-btn']}>
                        <Button variant="contained" color="primary" onClick={() => navigate(-1)}>
                            BACK
                        </Button>
                    </div>
                    <div className={UserClasses['users-main']}>
                        <div>
                            <SkeletonTheme color='#ddd' highlightColor='#eee'>
                                {loading ? (
                                    <div style={{ display: 'flex', justifyContent: 'center' }} className={UserClasses['prod-main-skel']}>
                                        <Skeleton height={'8rem'} width={'900px'} count={3} />
                                        {/* Add more skeletons as needed */}
                                    </div>
                                ) : (
                                    <div className={OrderDetailStyles['order-details-container']}>
                                        {user && (
                                            <div className={OrderDetailStyles['user-details-container']}>
                                                <Typography className={OrderDetailStyles['head-every-data']} variant="h6">User Details</Typography>
                                                <Typography className={OrderDetailStyles['para-two-para']} variant="body1"><span className={OrderDetailStyles['head-every-para']}>User ID:</span>{' '}{user.userId || 'NA'}</Typography>
                                                <Typography className={OrderDetailStyles['para-two-para']} variant="body1"><span className={OrderDetailStyles['head-every-para']}>Basket Order ID:</span>{' '}{orderDetails.OrderID || 'NA'}</Typography>
                                                <Typography className={OrderDetailStyles['para-two-para']} variant="body1"><span className={OrderDetailStyles['head-every-para']}>Name:</span>{' '}{user.fullName || 'NA'}</Typography>
                                                <Typography className={OrderDetailStyles['para-two-para']} variant="body1"><span className={OrderDetailStyles['head-every-para']}>Mobile:</span>{' '}{user.mobileNumber || 'NA'}</Typography>
                                                <Typography className={OrderDetailStyles['para-two-para']} variant="body1"><span className={OrderDetailStyles['head-every-para']}>Address:</span>{' '}{address.street}{' '}{address.city}{' '}{address.postalCode}{' '}{address.additionalInfo}</Typography>
                                            </div>
                                        )}
                                        <div className={OrderDetailStyles['basket-details-container']}>
                                            <Typography
                                                className={OrderDetailStyles['head-every-data']}
                                                variant="h6"
                                                onClick={() => setBasketOpen(!basketOpen)} // Toggle basket open state
                                                style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                                            >
                                                Basket Details{' '}
                                                {basketOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                            </Typography>
                                            <Collapse in={basketOpen} timeout="auto" unmountOnExit>
                                                <Box sx={{ margin: 1 }}>
                                                    {basketDetails && (
                                                        <>
                                                            <Typography className={OrderDetailStyles['para-two-para']} variant="body1"><span className={OrderDetailStyles['head-every-para']}>Basket Name:</span>{' '}{basketDetails.basketName || 'NA'}</Typography>
                                                            <Typography className={OrderDetailStyles['para-two-para']} variant="body1"><span className={OrderDetailStyles['head-every-para']}>Basket Price:</span>{' '}₹{basketDetails.basketPrice || 'NA'}</Typography>
                                                        </>
                                                    )}
                                                    <TableContainer component={Paper}>
                                                        <Typography style={{ borderBottom: '1px solid lightgray', textAlign: 'center' }} variant="h6" gutterBottom component="div">
                                                            {basketDetails && (
                                                                <>
                                                                    <Typography variant="body1">{basketDetails.basketName}</Typography>
                                                                </>
                                                            )}
                                                            Products
                                                        </Typography>
                                                        <Table aria-label="basket-products">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>Product</TableCell>
                                                                    <TableCell>Metric Type & Value</TableCell>
                                                                    <TableCell>Price</TableCell>
                                                                    <TableCell>Image</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {basketDetails && basketDetails.products.map((product, index) => (
                                                                    <TableRow key={index} data-aos="fade-up"
                                                                    data-aos-anchor-placement="top-bottom">
                                                                        <TableCell>
                                                                            <Typography variant="body1">{product.name || 'NA'}</Typography>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Typography variant="body2">{product.metricValue}{product.metricType || 'NA'}</Typography>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Typography variant="body2">₹{product.price || 'NA'}</Typography>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <img onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                window.open(
                                                                                    product.url,
                                                                                    "_blank"
                                                                                );
                                                                            }} src={product.url} alt={product.name} className={OrderDetailStyles['product-image']} />
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Box>
                                            </Collapse>
                                        </div>
                                        <div className={OrderDetailStyles['additional-orders-container']}>
                                            <Typography
                                                className={OrderDetailStyles['head-every-data']}
                                                variant="h6"
                                                onClick={toggleAdditionalOrders} // Toggle additional orders open state
                                                style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                                            >
                                                Additional Orders{' '}
                                                {additionalOrdersOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                            </Typography>
                                            <Collapse in={additionalOrdersOpen} timeout="auto" unmountOnExit>
                                                <Box sx={{ margin: 1 }}>
                                                    <TableContainer component={Paper}>
                                                        <Table aria-label="additional-orders">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell />
                                                                    <TableCell>Order Items</TableCell>
                                                                    <TableCell></TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {additionalOrders.map((order, index) => (
                                                                    <Row key={index} order={order} index={index} />
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Box>
                                            </Collapse>
                                        </div>
                                        <div className={OrderDetailStyles['order-status-container']}>
                                            <Typography className={OrderDetailStyles['head-every-data']} variant="h6">Change Order Status</Typography>
                                            <div className={OrderDetailStyles['order-status-inside-container']}>
                                                <Select
                                                    style={{ height: '40px', width: '150px', backgroundColor: 'white' }}
                                                    value={selectedOrderStatus}
                                                    onChange={handleOrderStatusChange}
                                                    className={OrderDetailStyles['order-status-select']}
                                                    disabled={ selectedOrderStatus === '3'} // Disable if status is Completed or Cancelled
                                                >
                                        
                                                    <MenuItem value={2}>Pending</MenuItem>
                                                    <MenuItem value={3}>Delivered</MenuItem>
                                                </Select>
                                                <Button
                                                    style={{ backgroundColor: 'black', color: 'white' }}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleUpdateOrderStatus}
                                                    disabled={selectedOrderStatus === '2' || selectedOrderStatus === '3'} // Disable if status is Completed or Cancelled
                                                >
                                                    Update Status
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </SkeletonTheme>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default OrderDetails;
