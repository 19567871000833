import React, { useEffect, useState, useRef } from 'react';
import Layout from 'Components/Layout';
import UserClasses from '../users/Users.module.css';
import { Helmet } from 'react-helmet';
import { walletTableHeads } from '../constants/Constants';
import { SkeletonTheme } from 'react-loading-skeleton';
import UsersSkeleton from '../users/UsersSkeleton';
import ProductClasses from '../products/Products.module.css';
import Chip from '@mui/material/Chip';
import WalletClasses from '../wallet/Wallet.module.css';
import { Services } from 'Services'; // Import the Services file
import OrderClasses from '../orders/Orders.module.css';
import TablePagination from '@mui/material/TablePagination';
import Fab from '@mui/material/Fab';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import { Box } from '@mui/material';
import { MdNotificationsActive } from 'react-icons/md';
import { Success } from 'Util/Toast';
import { RiWallet2Fill } from "react-icons/ri";
import totalwalletimg from '../../../Assets/unnamed.png';
import placeholderImg from '../../../Assets/no-wallettransactions1.gif'; // Import the placeholder image
import CountUp from 'react-countup'; // Import CountUp component
import CategoryClasses from '../categories/AddCategory.module.css';

const Wallet = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loadingReminder, setLoadingReminder] = useState(null); // To track loading state of reminder API
  const [success, setSuccess] = useState(null); // To track success state of reminder API
  const [errorMessage, setErrorMessage] = useState(''); // To track error messages
  const [totalAmount, setTotalAmount] = useState(0); // State for total amount
  const [totalCount, setTotalCount] = useState(0);

  const timer = useRef();

  // TODO:Clean up timer
  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  // FIXME:Fetching data from the API
  useEffect(() => {
    const token = { Authorization: `token ${localStorage.getItem('Token')}` };
    Services.getWallets(token)
      .then(response => {
        setData(response.data);
        setTotalAmount(response.totalAmount); // Set total amount
        setTotalCount(response.data.length);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching wallet data:', error);
        setLoading(false);
        setErrorMessage(`Error fetching wallet data: ${error.message}`);
      });
  }, []);

  // FIXME:Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // FIXME:Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // TODO:Calculate the indices for the current page
  const indexOfLastItem = (page + 1) * rowsPerPage;
  const indexOfFirstItem = indexOfLastItem - rowsPerPage;

  // TODO:Slice data to get items for the current page
  const currentData = data.slice(indexOfFirstItem, indexOfLastItem);

  const chipColors = ['#7e57c2', '#03a9f4', '#4caf50', '#ff9800'];

  // FIXME:Function to handle reminder button click
  const handleReminderClick = (userId) => {
    if (!loadingReminder) {
      setSuccess(null);
      setLoadingReminder(userId);
      const token = { Authorization: `token ${localStorage.getItem('Token')}` };

      Services.sendReminder(userId, token)
        .then(response => {
          console.log('Reminder sent successfully:', response);
          setSuccess(userId);
          setLoadingReminder(null);
          Success(response.Message);
        })
        .catch(error => {
          console.error('Error sending reminder:', error);
          setErrorMessage(`Error sending reminder: ${error.message}`);
          setLoadingReminder(null);
        });
    }
  };

  const buttonSx = (userId) => ({
    ...(success === userId && {
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[700],
      },
    }),
  });

  return (
    <Layout Active={'Wallet'}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Wallet</title>
      </Helmet>
      <div className={UserClasses['main-first-container']}>
        <div className={UserClasses['main-data-tab']}>
          <div className={UserClasses['users-main']}>
            <div>
              <h2 className={OrderClasses['header-form']}>
                Wallet <span className={UserClasses['head-count-main']}>({totalCount})</span>
              </h2>
              <SkeletonTheme color='#ddd' highlightColor='#eee'>
                {loading ? (
                  <UsersSkeleton TableHeader={walletTableHeads} />
                ) : (
                  <div style={{ overflowY: 'hidden' }}>
                    <div className={WalletClasses['main-wallet']} data-aos="fade-right">
                      <p>Total Wallet Balance</p>
                      <div className={WalletClasses['wallet-sub-main']}>
                        <img className={WalletClasses['walletimg']} src={totalwalletimg} alt="Wallet" />
                        <CountUp start={0} end={totalAmount} duration={3} separator="," prefix="₹" decimals={2} className={WalletClasses['total-wallet-price']} />
                      </div>
                    </div>
                    {currentData.length > 0 ? (
                      <table className={ProductClasses['category-table']}>
                        <thead>
                          <tr className={UserClasses['main-table-heads']}>
                            {walletTableHeads.map((head, index) => (
                              <th key={index} className={ProductClasses['products-table-head']}>{head}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {currentData.map((item, index) => (
                            <tr key={item.userid} data-aos="fade-up"
                              data-aos-anchor-placement="top-bottom">
                              <td className={CategoryClasses["table-cell"]}>{item.fullName}</td>
                              <td>₹{item.amount}</td>
                              <td>{item.date}</td>
                              <td>
                                <Box sx={{ m: 1, position: 'relative' }}>
                                  {/* Reminder button with the icon */}
                                  <button
                                    aria-label="send reminder"
                                    color="primary"
                                    className={WalletClasses['remainder-btn']}
                                    onClick={() => handleReminderClick(item.userid)}
                                    style={buttonSx(item.userid)}
                                  >
                                    {success === item.userid ? (
                                      // TODO:Show the check icon when successful
                                      <CheckIcon className={WalletClasses['remainder-check']} />
                                    ) : (
                                      // TODO:Show the reminder icon when not successful
                                      <MdNotificationsActive className={WalletClasses['remainder-icon']} />
                                    )}
                                  </button>
                                  {loadingReminder === item.userid && (
                                    <CircularProgress
                                      size={24}
                                      sx={{
                                        color: green[500],
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                      }}
                                    />
                                  )}
                                </Box>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <div style={{ textAlign: 'center', padding: '20px' }}>
                        <img src={placeholderImg} alt="No Data" style={{ width: '100px', height: '100px' }} data-aos="fade-down-left" />
                        <p>No Wallet Details Found</p>
                      </div>
                    )}
                  </div>
                )}

                {/* TODO:Add pagination component */}
                <div className={UserClasses['table-pagination']}>
                  <div className={UserClasses['current-page']}>
                    <p>Current Page: {page + 1}</p>
                  </div>

                  <TablePagination
                    component="div"
                    count={data.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>

                {/* TODO:Error message display */}
                {errorMessage && (
                  <div className={WalletClasses['error-message']}>
                    {errorMessage}
                  </div>
                )}
              </SkeletonTheme>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Wallet;
