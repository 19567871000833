import React, { useState } from 'react';
import Layout from 'Components/Layout';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import UserClasses from '../../users/Users.module.css';
import OrderClasses from '../../orders/Orders.module.css';
import { Services } from 'Services';
import FaqClasses from '../faqs/Faq.module.css';
import { Success, Error_Dark } from 'Util/Toast';
import { Button, TextField } from '@mui/material';

const AddFaq = () => {
    const navigate = useNavigate();

    // State variables for question and answer
    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const [errors, setErrors] = useState({});

    // Form validation function
    const validateForm = () => {
        const errors = {};
        if (!question.trim()) {
            errors.question = 'Question is required';
        }
        if (!answer.trim()) {
            errors.answer = 'Answer is required';
        }
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    // Handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validateForm()) {
            return;
        }

        const token = { Authorization: `token ${localStorage.getItem('Token')}` };

        // Prepare the body object with question and answer
        const body = {
            question,
            answer,
        };

        try {
            const response = await Services.addFAQ(body, token);
            if (response.Status === 1) {
                Success('FAQ added successfully');
                navigate('/faqs'); // Navigate to FAQs list page
            } else {
                Error_Dark('Failed to add FAQ');
            }
        } catch (error) {
            console.error('Error adding FAQ:', error);
            Error_Dark('An error occurred while adding FAQ');
        }
    };

    return (
        <Layout Active={'FAQs'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Add FAQs</title>
            </Helmet>
            <div className={UserClasses['main-first-container']}>
                <div className={UserClasses['main-data-tab']}>
                    <div className={UserClasses['add-users-btn']}>
                        <Button variant="contained" color="primary" onClick={() => navigate('/faqs')}>
                            BACK
                        </Button>
                    </div>
                    <div className={UserClasses['users-main']} data-aos="flip-up">
                        <div className={OrderClasses['order-details-container']}>
                            <h2 className={OrderClasses['header-form']}>Add FAQ</h2>

                            {/* Form for adding FAQ */}
                            <div className={FaqClasses['main-add-form']}>
                                <form onSubmit={handleSubmit}>
                                    {/* TextField for Question */}
                                    <div className={FaqClasses['form-group']}>
                                        <TextField
                                            label="Question"
                                            value={question}
                                            onChange={(e) => setQuestion(e.target.value)}
                                            fullWidth
                                            required
                                            variant="outlined"
                                            margin="normal"
                                            error={errors.question ? true : false}
                                            helperText={errors.question}
                                        />
                                    </div>

                                    {/* TextField for Answer */}
                                    <div className={FaqClasses['form-group']}>
                                        <TextField
                                            label="Answer"
                                            value={answer}
                                            onChange={(e) => setAnswer(e.target.value)}
                                            fullWidth
                                            required
                                            variant="outlined"
                                            margin="normal"
                                            multiline
                                            rows={4} // Allows multiline input
                                            error={errors.answer ? true : false}
                                            helperText={errors.answer}
                                        />
                                    </div>

                                    {/* Submit button */}
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        className={FaqClasses['submitButton']}
                                    >
                                        Add FAQ
                                    </Button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default AddFaq;
