import { Error_Dark } from "./Util/Toast";
const httpcall = (url, method, body, token) => {
    return fetch(`${process.env.REACT_APP_BASE_URL}/${url}`, {
        method: method,

        body: body,
        headers: {
            "Content-type": "application/json; charset=UTF-8",
            ...token,
        },
    })
        .then((response) => response.json())
        .then((res) => {
            console.log(res, 'response')
            if (res.Status === 0) {
                if (
                    res.Message === "Invalid token" ||
                    res.Message === "Token expired"
                ) {
                    localStorage.clear();
                    sessionStorage.clear();
                    window.location.href = "/?message=expired";
                    Error_Dark("Session Expired");
                }
                return res
            } else {
                return res;
            }

        });
};

const Login = (method, body) => {
    return httpcall("admin/login", method, body);
};

const getUsers = (isBasketLinked, token) => {
    return httpcall(`admin/getUsers/${isBasketLinked}`, "GET", null, token);
};

const getUsersDetails = (userId, token) => {
    return httpcall(`admin/getUsersDetails/${userId}`, "GET", null, token);
};

const addProduct = (body, token) => {
    return httpcall("admin/add-product", "POST", JSON.stringify(body), token);
};

const addProductVariant = (body, token) => {
    return httpcall("admin/addProductVariant", "POST", JSON.stringify(body), token);
};

const getBanners = (token) => {
    return httpcall("admin/banners", "GET", null, token);
};

const addBanner = (body, token) => {
    return httpcall("admin/banners/add", "POST", JSON.stringify(body), token);
};

const updateBanner = (bannerId, body, token) => {
    return httpcall(`admin/banners/edit/${bannerId}`, "PATCH", JSON.stringify(body), token);
};

const deleteBanner = (bannerId, token) => {
    return httpcall(`admin/banners/delete/${bannerId}`, "DELETE", null, token);
};

const getCategories = (token) => {
    return httpcall("admin/getCategories", "GET", null, token);
};

const getProducts = (token) => {
    return httpcall("admin/getProducts", "GET", null, token);
};

const getProductVariants = (productId, token) => {
    return httpcall(`admin/getProductVariants/${productId}`, "GET", null, token);
};

const fetchCategoriesData = (token) => {
    return httpcall("admin/getCategories", "GET", null, token);
};

const fetchhelpnumberdata = (token) => {
    return httpcall("admin/get-help-number", "GET", null, token);
};

const addCategories = (body, token) => {
    return httpcall("admin/addCategories", "POST", JSON.stringify(body), token);
};

const deleteCategory = (categoryId, token) => {
    return httpcall(`admin/deleteCategory/${categoryId}`, "DELETE", null, token);
};

const updateCategory = (categoryId, body, token) => {
    return httpcall(`admin/updateCategories/${categoryId}`, "PATCH", JSON.stringify(body), token);
};

const getOrders = (token) => {
    return httpcall("admin/orders/all", "GET", null, token);
};

const getOrderById = (orderId, token) => {
    return httpcall(`admin/orders/details/${orderId}`, "GET", null, token);
};

const changeOrderStatus = (orderId, status, token) => {
    return httpcall(`admin/orders/status/${orderId}`, "PATCH", JSON.stringify({ status }), token);
};

const getWallets = (token) => {
    return httpcall("admin/wallets/all", "GET", null, token);
};

const getCoupons = (token) => {
    return httpcall("admin/coupons/all", "GET", null, token);
};

const createCoupon = (body, token) => {
    return httpcall("admin/coupons/create", "POST", JSON.stringify(body), token);
};

const changeCouponStatus = (couponId, couponStatus, token) => {
    return httpcall(`admin/coupons/status/${couponId}/${couponStatus}`, "PATCH", null, token);
};

const getPlans = (token) => {
    return httpcall("admin/plans/all", "GET", null, token);
};

const addPlan = (body, token) => {
    return httpcall("admin/plans/add", "POST", JSON.stringify(body), token);
};

const getPlanById = (planId, token) => {
    return httpcall(`admin/plans/id/${planId}`, "GET", null, token);
};

const changePlanStatus = (planId, status, token) => {
    return httpcall(`admin/plans/status/${planId}/${status}`, "PATCH", null, token);
};

const updatePlan = (planId, body, token) => {
    return httpcall(`admin/plans/update/${planId}`, "PATCH", JSON.stringify(body), token);
};

const deactivatePlan = (planId, token) => {
    return httpcall(`admin/plans/deactivate/${planId}`, "PATCH", null, token);
};

const getBaskets = (token) => {
    return httpcall("admin/baskets/all", "GET", null, token);
};

const editProductVariant = (variantId, body, token) => {
    return httpcall(`admin/variants/edit/${variantId}`, "PATCH", JSON.stringify(body), token);
};

const deleteProductVariant = (variantId, token) => {
    return httpcall(`admin/variants/delete/${variantId}`, "DELETE", null, token);
};

const getComplaints = (token) => {
    return httpcall("admin/complaints/all", "GET", null, token);
};

const changeComplaintStatus = (complaintId, status, token) => {
    return httpcall(`admin/complaints/${complaintId}/status/${status}`, "PATCH", null, token);
};

const getProductById = (productId, token) => {
    return httpcall(`admin/products/id/${productId}`, "GET", null, token);
};

const getBasketProducts = (token) => {
    return httpcall("admin/products", "GET", null, token);
};

const editProduct = (productId, body, token) => {
    return httpcall(`admin/products/edit/${productId}`, "PATCH", JSON.stringify(body), token);
};

const deleteProduct = (productId, token) => {
    return httpcall(`admin/products/delete/${productId}`, "DELETE", null, token);
};

const getFAQs = (token) => {
    return httpcall("admin/FAQs/all", "GET", null, token);
};

const getFAQById = (customerSupportId, token) => {
    return httpcall(`admin/FAQs/${customerSupportId}`, "GET", null, token);
};

const updateFAQ = (customerSupportId, body, token) => {
    const url = `admin/FAQs/update/${customerSupportId}`;
    return httpcall(url, "PATCH", JSON.stringify(body), token);
};

const deleteFAQ = (customerSupportId, token) => {
    const url = `admin/FAQs/delete/${customerSupportId}`;
    return httpcall(url, "DELETE", null, token);
};

const addFAQ = (body, token) => {
    const url = "admin/FAQs/add";
    return httpcall(url, "POST", JSON.stringify(body), token);
};

const changePassword = (body, token) => {
    const url = "admin/change-password";
    return httpcall(url, "PATCH", JSON.stringify(body), token);
};

const sendReminder = (userId, token) => {
    const url = `admin/reminder/${userId}`;
    return httpcall(url, "POST", null, token);
};

const addBasket = (body, token) => {
    return httpcall("admin/baskets/add", "POST", JSON.stringify(body), token);
};

const getBasketById = (basketId, token) => {
    return httpcall(`admin/baskets/${basketId}`, "GET", null, token);
};

const editBasket = (basketId, body, token) => {
    return httpcall(`admin/baskets/edit/${basketId}`, "PATCH", JSON.stringify(body), token);
};

const getBasketsList = (token) => {
    return httpcall("admin/baskets/list", "GET", null, token);
};

const assignBasket = (userId, basketId, token) => {
    return httpcall(`admin/baskets/assign/${userId}/${basketId}`, "POST", null, token);
};

const changeBasketStatus = (basketId, status, token) => {
    return httpcall(`admin/baskets/id/${basketId}/status/${status}`, "PATCH", null, token);
};

const getWalletTransactions = (token) => {
    return httpcall("admin/wallets/list", "GET", null, token);
};

const getDeliveryPersonsList = (token) => {
    return httpcall("admin/deliveryboy/all", "GET", null, token);
};

const fetchDeliveryPersonsList = (token) => {
    return httpcall("admin/deliveryboy/list", "GET", null, token);
};

const assignDeliveryPerson = (orderId, deliveryPersonId, token) => {
    return httpcall(`admin/orders/assign/${orderId}/${deliveryPersonId}`, "PATCH", null, token);
};

const addDeliveryPerson = (body, token) => {
    return httpcall("admin/deliveryboy/add", "POST", JSON.stringify(body), token);
};

const getCommunities = (token) => {
    return httpcall("admin/communities/all", "GET", null, token);
};

const addCommunity = (body, token) => {
    return httpcall("admin/communities/add", "POST", JSON.stringify(body), token);
};

const getCommunitiesById = (communityId, token) => {
    return httpcall(`admin/communities/id/${communityId}`, "GET", null, token);
};

const editCommunity = (communityId, body, token) => {
    return httpcall(`admin/communities/edit/${communityId}`, "PATCH", JSON.stringify(body), token);
};

const deleteCommunity = (communityId, token) => {
    return httpcall(`admin/communities/delete/${communityId}`, "DELETE", null, token);
};

const searchCommunities = (searchTerm, token) => {
    const encodedSearchTerm = encodeURIComponent(searchTerm); // Ensure search term is properly encoded
    return httpcall(`admin/communities/all?search=${encodedSearchTerm}`, "GET", null, token);
};

const getCharts = (token) => {
    return httpcall("admin/chart-data", "GET", null, token);
};

const getAdditionalOrders = (token) => {
    return httpcall("admin/additional-orders/all", "GET", null, token);
};

const getAdditionalOrderDetails = (orderId, token) => {
    return httpcall(`admin/additional-orders/details/${orderId}`, "GET", null, token);
};

const editDeliveryPerson = (deliveryPersonId, body, token) => {
    return httpcall(`admin/deliveryboy/edit/${deliveryPersonId}`, "PATCH", JSON.stringify(body), token);
};

const getDeliveryPersonById = (deliveryPersonId, token) => {
    return httpcall(`admin/deliveryboy/details/${deliveryPersonId}`, "GET", null, token);
};

const addOrUpdateHelpNumber = (body, token) => {
    return httpcall("admin/add-help-number", "POST", JSON.stringify(body), token);
};



export const Services = {
    Login,
    getUsers,
    getUsersDetails,
    addProduct,
    addProductVariant,
    getCategories,
    getProducts,
    getProductVariants,
    fetchCategoriesData,
    addCategories,
    deleteCategory,
    updateCategory,
    getOrders,
    getOrderById,
    changeOrderStatus,
    getWallets,
    getCoupons,
    createCoupon,
    changeCouponStatus,
    getPlans,
    addPlan,
    getPlanById,
    changePlanStatus,
    updatePlan,
    deactivatePlan,
    getBaskets,
    editProductVariant,
    deleteProductVariant,
    getComplaints,
    changeComplaintStatus,
    getProductById,
    getBasketProducts,
    editProduct,
    deleteProduct,
    getFAQs,
    getFAQById,
    updateFAQ,
    deleteFAQ,
    addFAQ,
    changePassword,
    sendReminder,
    addBasket,
    getBasketById,
    editBasket,
    getBasketsList,
    assignBasket,
    changeBasketStatus,
    getWalletTransactions,
    getDeliveryPersonsList,
    fetchDeliveryPersonsList,
    assignDeliveryPerson,
    addDeliveryPerson,
    getCommunities,
    addCommunity,
    getCommunitiesById,
    editCommunity,
    deleteCommunity,
    searchCommunities,
    getCharts,
    getAdditionalOrders,
    getAdditionalOrderDetails,
    editDeliveryPerson,
    getDeliveryPersonById,
    getBanners,
    addBanner,
    updateBanner,
    deleteBanner,
    fetchhelpnumberdata,
    addOrUpdateHelpNumber,
};
