import React, { useState } from 'react';
import Layout from 'Components/Layout';
import { Helmet } from 'react-helmet';
import UserClasses from '../users/Users.module.css';


const CreateTeamMember = () => {

  return (
    <Layout Active={'Permissions'}>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Users</title>
      </Helmet>
      <div className={UserClasses['main-data-tab']}>
        <div className={UserClasses['users-main']}>
      
        </div>
      </div>
    </Layout>
  );
};

export default CreateTeamMember;
