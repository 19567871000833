import React, { useState } from 'react';
import Layout from 'Components/Layout';
import UserClasses from '../users/Users.module.css';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { Services } from 'Services'; // Import the Services file
import CouponClasses from './Coupons.module.css'

const CreateCoupon = () => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [discountAmount, setDiscountAmount] = useState('');
    const [discountType, setDiscountType] = useState(0); // 0 for percentage, 1 for fixed amount
    const [activationDate, setActivationDate] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [couponCode, setCouponCode] = useState('');
    const [couponStatus, setCouponStatus] = useState(0); // 0 for inactive, 1 for active
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const token = { Authorization: `token ${localStorage.getItem('Token')}` };
            const payload = {
                couponName: name,
                couponDescription: description,
                discount: parseFloat(discountAmount),
                discountType: parseInt(discountType),
                activationDate,
                expirationDate: expiryDate,
                couponCode,
                couponStatus: parseInt(couponStatus)
            };
            await Services.createCoupon(payload, token);
            navigate('/coupons');
        } catch (error) {
            console.error('Error creating coupon:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Layout Active={'Coupons'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Create Coupon</title>
            </Helmet>
            <div className={UserClasses['main-first-container']}>
                <div className={UserClasses['main-data-tab']}>
                    <Button variant="contained" color="primary" onClick={() => navigate(-1)}>
                        BACK
                    </Button>
                    <form
                        style={{
                            maxWidth: '600px',
                            margin: '0 auto',
                            padding: '20px',
                            border: '1px solid #ccc',
                            borderRadius: '8px',
                            background: 'white',
                        }}
                        onSubmit={handleSubmit}
                    >
                        <div style={{ marginBottom: '20px' }}>
                            <TextField
                                label="Coupon Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                fullWidth
                                required
                            />
                        </div>
                        <div style={{ marginBottom: '20px' }}>
                            <TextField
                                label="Description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                fullWidth
                            />
                        </div>
                        <div style={{ marginBottom: '20px' }}>
                            <TextField
                                type="number"
                                label="Discount Amount"
                                value={discountAmount}
                                onChange={(e) => setDiscountAmount(e.target.value)}
                                fullWidth
                                required
                            />
                        </div>
                        <div style={{ marginBottom: '20px' }}>
                            <FormControl fullWidth>
                                <p className={CouponClasses['Discount-type']}>Discount Type</p>
                                <Select
                                    value={discountType}
                                    onChange={(e) => setDiscountType(e.target.value)}
                                >
                                    <MenuItem value={0}>Percentage</MenuItem>
                                    <MenuItem value={1}>Fixed Amount</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div style={{ marginBottom: '20px' }}>
                            <TextField
                                type="date"
                                value={activationDate}
                                onChange={(e) => setActivationDate(e.target.value)}
                                fullWidth
                                required
                            />
                        </div>
                        <div style={{ marginBottom: '20px' }}>
                            <TextField
                                type="date"
                                value={expiryDate}
                                onChange={(e) => setExpiryDate(e.target.value)}
                                fullWidth
                                required
                            />
                        </div>
                        <div style={{ marginBottom: '20px' }}>
                            <TextField
                                label="Coupon Code"
                                value={couponCode}
                                onChange={(e) => setCouponCode(e.target.value)}
                                fullWidth
                                required
                            />
                        </div>
                        <FormControl fullWidth>
                            <p className={CouponClasses['Discount-type']}>Coupon Status</p>
                            <Select
                                value={couponStatus}
                                onChange={(e) => setCouponStatus(e.target.value)}
                            >
                                <MenuItem value={0}>Inactive</MenuItem>
                                <MenuItem value={1}>Active</MenuItem>
                            </Select>
                        </FormControl>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={loading}
                            style={{ marginTop: '20px', padding: '10px 20px', borderRadius: '20px' }}
                        >
                            {loading ? 'Creating...' : 'Create Coupon'}
                        </Button>
                    </form>
                </div>
            </div>
        </Layout>
    );
};

export default CreateCoupon;
