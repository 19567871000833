import React, { useState, useEffect } from 'react';
import UserClasses from '../users/Users.module.css';
import ProductClasses from '../products/Products.module.css';
import { CiSquarePlus } from 'react-icons/ci';
import { FaRegEdit, FaBoxOpen } from 'react-icons/fa'; // Import an icon for no records found
import Layout from 'Components/Layout';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { SkeletonTheme } from 'react-loading-skeleton';
import UsersSkeleton from '../users/UsersSkeleton';
import { basketTableHeads } from '../constants/Constants';
import { Button, TablePagination, Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem, ListItemText, Select, MenuItem } from '@mui/material';
import CategoryClasses from '../categories/AddCategory.module.css';
import { Services } from 'Services';
import OrderClasses from '../orders/Orders.module.css';
import User from "../../../Assets/user.svg";
import basketPlaceholder from '../../../Assets/fruits-and-vegetables-1.png'; // Import placeholder image
import { Success } from 'Util/Toast';

const Basket = () => {
    const [baskets, setBaskets] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(() => {
        const savedPage = localStorage.getItem('currentBasketPage');
        return savedPage ? parseInt(savedPage, 10) : 0;
    });
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [totalCount, setTotalCount] = useState(0);
    const navigate = useNavigate();

    // FIXME:Fetching data from the API

    useEffect(() => {
        const fetchBaskets = async () => {
            try {
                const token = { Authorization: `token ${localStorage.getItem('Token')}` };
                const response = await Services.getBaskets(token);
                setBaskets(response.data);
                setTotalCount(response.data.length);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching baskets:', error);
                setLoading(false);
            }
        };

        fetchBaskets();
    }, []);

    useEffect(() => {
        localStorage.setItem('currentBasketPage', page);
    }, [page]);

    // TODO: navigate to add basket page

    const handleAddBasketClick = () => {
        navigate('/add-basket');
    };

    // TODO: navigate to edit basket page

    const handleEditBasketClick = (basketId) => {
        localStorage.setItem('currentBasketPage', page);
        navigate(`/edit-basket/${basketId}`);
    };

    // FIXME: handle change page for pagination

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // FIXME: handle change rows per page for pagination

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // TODO: Calculate the indices for the current page
    const indexOfLastItem = (page + 1) * rowsPerPage;
    const indexOfFirstItem = indexOfLastItem - rowsPerPage;

    // TODO: Slice baskets data to get items for the current page
    const currentBaskets = baskets.slice(indexOfFirstItem, indexOfLastItem);

    const handleBasketStatusChange = async (basketId, status) => {
        try {
            const token = { Authorization: `token ${localStorage.getItem('Token')}` };
            const response = await Services.changeBasketStatus(basketId, status, token);
            Success(response.Message)
            // TODO: Refresh the baskets data after status change
            const responseData = await Services.getBaskets(token);
            setBaskets(responseData.data);
        } catch (error) {
            console.error('Error changing basket status:', error);
        }
    };

    return (
        <Layout Active={'Basket'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Basket</title>
            </Helmet>
            <div className={UserClasses['main-first-container']}>
                <div className={ProductClasses['main-add-btn']}>
                    <Button data-aos="zoom-in" variant="contained" color="primary" onClick={handleAddBasketClick}>
                        Add Basket <CiSquarePlus className="CiSquarePlus" />
                    </Button>
                </div>
                <div className={UserClasses['main-data-tab']}>
                    <div className={UserClasses['users-main']}>
                        <h2 className={OrderClasses['header-form']}>
                            Basket Details <span className={UserClasses['head-count-main']}>({totalCount})</span>
                        </h2>
                        <div>
                            <SkeletonTheme color='#ddd' highlightColor='#eee'>
                                {loading ? (
                                    <UsersSkeleton TableHeader={basketTableHeads} />
                                ) : (
                                    <div style={{ overflowY: 'hidden' }}>
                                        {baskets.length > 0 ? (
                                            <>
                                                <table className={ProductClasses['category-table']}>
                                                    <thead>
                                                        <tr className={UserClasses['main-table-heads']}>
                                                            {basketTableHeads.map((head, index) => (
                                                                <th key={index} className={ProductClasses['products-table-head']}>{head}</th>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {currentBaskets.map((basket) => (
                                                            <tr key={basket.basketId} data-aos="fade-up"
                                                                data-aos-anchor-placement="top-bottom">
                                                                <td className={CategoryClasses["table-cell"]}>{basket.basketName}</td>
                                                                <td className={UserClasses["view-users-main"]}>
                                                                    <Button variant="outlined" onClick={() => {
                                                                        setSelectedUser(basket.users);
                                                                        setDialogOpen(true);
                                                                    }}>
                                                                        View Users
                                                                    </Button>
                                                                </td>
                                                                <td>{basket.noOfProducts}</td>
                                                                <td>₹{basket.basketPrice}</td>
                                                                <td>
                                                                    <Select
                                                                        className={UserClasses['basket-status-main']}
                                                                        value={basket.basketStatus === 1 ? 'Active' : 'Inactive'}
                                                                        onChange={(event) => handleBasketStatusChange(basket.basketId, event.target.value === 'Active' ? 1 : 0)}
                                                                        renderValue={(selected) => (
                                                                            <div>
                                                                                {selected === 'Active' && <span className={`${CategoryClasses['status-dot']} ${CategoryClasses['active']}`}></span>}
                                                                                {selected === 'Inactive' && <span className={`${CategoryClasses['status-dot']} ${CategoryClasses['inactive']}`}></span>}
                                                                                {selected}
                                                                            </div>
                                                                        )}
                                                                    >
                                                                        <MenuItem value="Active">Active</MenuItem>
                                                                        <MenuItem value="Inactive">Inactive</MenuItem>
                                                                    </Select>

                                                                </td>
                                                                <td>
                                                                    <button onClick={() => handleEditBasketClick(basket.basketId)} className={`${CategoryClasses['edit-button']} ${CategoryClasses['button']}`}>
                                                                        <FaRegEdit />
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>

                                                {/* TODO: Pagination */}
                                                <div className={UserClasses['table-pagination']}>
                                                    <div className={UserClasses['current-page']}>
                                                        <p>Current Page: {page + 1}</p>
                                                    </div>
                                                    <TablePagination
                                                        component="div"
                                                        count={baskets.length}
                                                        page={page}
                                                        onPageChange={handleChangePage}
                                                        rowsPerPage={rowsPerPage}
                                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                                    />
                                                </div>
                                            </>
                                        ) : (
                                            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                                <img height={100} src={basketPlaceholder} alt="No Baskets" data-aos="zoom-in" />
                                                <p style={{ marginTop: '10px', fontSize: '18px', color: 'gray' }}>No Baskets Found</p>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </SkeletonTheme>
                        </div>
                    </div>
                </div>
            </div>

            {/* TODO: Simple Dialog */}
            <Dialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                sx={{
                    '& .MuiDialog-paper': {
                        width: '400px', // Adjust width as needed
                    },
                    '& .MuiDialogTitle-root': {
                        backgroundColor: 'black', // Customize title background color
                        padding: '10px 20px', // Adjust padding as needed
                        textAlign: 'center', // Center-align the title
                        color: 'white',
                    },
                    '& .MuiDialogContent-root': {
                        padding: '20px', // Adjust padding as needed
                    },
                    '& .MuiDialogActions-root': {
                        padding: '10px 20px', // Adjust padding as needed
                        justifyContent: 'flex-end', // Align actions to the right
                    },
                }}
            >
                <DialogTitle>Basket Assigned Users</DialogTitle>
                <DialogContent>
                    {selectedUser && selectedUser.length > 0 ? (
                        <List>
                            {selectedUser.map((user, index) => (
                                <ListItem button key={index}>
                                    <img src={User} alt={user.name} style={{ width: '30px', height: '30px', marginRight: '10px', borderRadius: '50%' }} />
                                    <ListItemText primary={user.name} />
                                </ListItem>
                            ))}
                        </List>
                    ) : (
                        <p>No Users Found</p>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button style={{ backgroundColor: 'black', color: 'white' }} onClick={() => setDialogOpen(false)}>Close</Button>
                </DialogActions>
            </Dialog>
        </Layout>
    );
};

export default Basket;
