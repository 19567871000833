import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Layout from 'Components/Layout';
import UserClasses from '../users/Users.module.css';
import DashBoardClasses from './Dashboard.module.css';
import { AreaChart, CartesianGrid, Legend, XAxis, YAxis, Tooltip, Area, BarChart, Bar, LineChart, Line } from 'recharts';
import { useInView } from 'react-intersection-observer';
import { Services } from 'Services';
import NoDataIcon from '../../../Assets/vacationrequestimg2.png'; // Import the Flaticon icon
import scheduledordersimg from '../../../Assets/scheduledorders-img1.png';
import registrationdataimg from '../../../Assets/registrationdata-1.png';
import useStore from 'store';
const Dashboard = () => {
    const { showNavbar } = useStore(state => state)
    const [ref, inView] = useInView(); // Track whether the line chart is in view
    const [showAreaChart, setShowAreaChart] = useState(false); // State to control rendering of AreaChart
    const [registrationData, setRegistrationData] = useState([]);
    const [scheduledOrdersData, setScheduledOrdersData] = useState([]);
    const [vacationRequestData, setVacationRequestData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const token = { Authorization: `token ${localStorage.getItem('Token')}` };
            const response = await Services.getCharts(token);
            if (response.Status === 1) {
                setRegistrationData(response.registrationData);
                setScheduledOrdersData(response.scheduledOrdersData);
                setVacationRequestData(response.vacationRequestData);
            } else {
                // Handle error or show a message to the user
            }
        };

        fetchData();

        const timer = setTimeout(() => {
            setShowAreaChart(true);
        }, 100);

        return () => clearTimeout(timer); // Clear the timeout on component unmount
    }, []);

    const isRegistrationDataEmpty = registrationData.length === 0;
    const isScheduledOrdersDataEmpty = scheduledOrdersData.length === 0;
    const isVacationDataEmpty = vacationRequestData.every(data => data.vacationRequests === 0 && data.guestRequests === 0);

    return (
        <Layout Active={'Dashboard'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Dashboard</title>
            </Helmet>
            <div className={`${UserClasses['main-first-container']} ${DashBoardClasses['chart-container']}`}>
                <h1 style={{ paddingLeft: '20px' }}>Dashboard</h1>
                <div className={UserClasses['main-data-tab']} ref={ref}>
                    <div className={UserClasses['users-main']}>
                        <h1 className={DashBoardClasses['graphs-heading']}>Registration Data</h1>
                        <div className={DashBoardClasses['dashboard-main-one']} style={{ overflowY: 'hidden' }}>
                            <div className={DashBoardClasses['chart-wrapper']} data-aos="zoom-in">
                                {showAreaChart ? (
                                    isRegistrationDataEmpty ? (
                                        <div className={DashBoardClasses['no-data-container']}>
                                            <img src={registrationdataimg} alt="No Data" style={{ width: '200px', height: '200px' }} data-aos="zoom-in-up" />
                                            <p style={{ color: 'gray' }}>No Registration Data Available</p>
                                        </div>
                                    ) : (
                                        <div>
                                            <AreaChart width={showNavbar ? 940 : 1100} height={400} data={registrationData}>
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="month" />
                                                <YAxis />
                                                <Tooltip
                                                    content={({ payload, label, active }) => {
                                                        if (active && payload && payload.length) {
                                                            return (
                                                                <div className={DashBoardClasses['custom-tooltip']}>
                                                                    <p>{`${label}`}</p>
                                                                    <p style={{ color: '#8E44AD' }}>{`registrations: ${payload[0].value}`}</p>
                                                                    <p style={{ color: '#E67E22' }}>{`orders: ${payload[1].value}`}</p>
                                                                </div>
                                                            );
                                                        }
                                                        return null;
                                                    }}
                                                />
                                                <Legend />
                                                <defs>
                                                    <linearGradient id="colorRegistrations" x1="0" y1="0" x2="0" y2="1">
                                                        <stop offset="5%" stopColor="#8E44AD" stopOpacity={0.8} />
                                                        <stop offset="95%" stopColor="#8E44AD" stopOpacity={0} />
                                                    </linearGradient>

                                                    <linearGradient id="colorOrders" x1="0" y1="0" x2="0" y2="1">
                                                        <stop offset="5%" stopColor="#E67E22" stopOpacity={0.8} />
                                                        <stop offset="95%" stopColor="#E67E22" stopOpacity={0} />
                                                    </linearGradient>
                                                </defs>
                                                <Area type="monotone" dataKey="registrations" stroke="#8E44AD" fill="url(#colorRegistrations)" name='Registrations' />
                                                <Area type="monotone" dataKey="orders" stroke="#E67E22" fill="url(#colorOrders)" name='Orders' />
                                            </AreaChart>
                                        </div>
                                    )
                                ) : null}
                            </div>
                        </div>
                        <h1 className={DashBoardClasses['graphs-heading']}>Scheduled Orders Data</h1>
                        <div className={DashBoardClasses['dashboard-main-one']} style={{ overflowY: 'hidden' }}>
                            <div className={DashBoardClasses['chart-wrapper']}>
                                {isScheduledOrdersDataEmpty ? (
                                    <div className={DashBoardClasses['no-data-container']}>
                                        <img src={scheduledordersimg} alt="No Data" style={{ width: '200px', height: '200px' }} data-aos="fade-left" />
                                        <p style={{ color: 'gray' }}>No Scheduled Orders Data Available</p>
                                    </div>
                                ) : (
                                    <div>

                                        <BarChart width={showNavbar ? 940 : 1100} height={400} data={scheduledOrdersData}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="month" />
                                            <YAxis />
                                            <Tooltip
                                                content={({ payload, label, active }) => {
                                                    if (active && payload && payload.length) {
                                                        return (
                                                            <div className={DashBoardClasses['custom-tooltip']}>
                                                                <p>{`${label}`}</p>
                                                                <p style={{ color: '#ff9f43' }}>{`Regular Orders: ${payload[0].value}`}</p>
                                                                <p style={{ color: '#34ace0' }}>{`Orders with Additional Items: ${payload[1].value}`}</p>
                                                            </div>
                                                        );
                                                    }
                                                    return null;
                                                }}
                                            />
                                            <Legend />
                                            <defs>
                                                <linearGradient id="colorRegularOrders" x1="0" y1="0" x2="0" y2="1">
                                                    <stop offset="5%" stopColor="#ff9f43" stopOpacity={0.8} />
                                                    <stop offset="95%" stopColor="#ff6f61" stopOpacity={0} />
                                                </linearGradient>
                                                <linearGradient id="colorOrdersWithAdditionalItems" x1="0" y1="0" x2="0" y2="1">
                                                    <stop offset="5%" stopColor="#34ace0" stopOpacity={0.8} />
                                                    <stop offset="95%" stopColor="#33d9b2" stopOpacity={0} />
                                                </linearGradient>
                                            </defs>
                                            <Bar dataKey="regularOrders" fill="url(#colorRegularOrders)" name="Regular Orders" />
                                            <Bar dataKey="ordersWithAdditionalItems" fill="url(#colorOrdersWithAdditionalItems)" name="Orders with Additional Items" />
                                        </BarChart>

                                    </div>
                                )}
                            </div>
                        </div>
                        <h1 className={DashBoardClasses['graphs-heading']}>Vacation Requests Data</h1>
                        <div className={DashBoardClasses['dashboard-main-one']} style={{ overflowY: 'hidden' }}>
                            <div className={DashBoardClasses['chart-wrapper']}>
                                {inView ? (
                                    isVacationDataEmpty ? (
                                        <div className={DashBoardClasses['no-data-container']}>
                                            <img src={NoDataIcon} alt="No Data" style={{ width: '200px', height: '200px' }} data-aos="fade-left" />
                                            <p style={{ color: 'gray' }}>No Vacation Request Data Available</p>
                                        </div>
                                    ) : (
                                        <div>
                                            <LineChart
                                                width={showNavbar ? 940 : 1100} height={400}
                                                data={vacationRequestData}
                                                className={`${DashBoardClasses['line-chart']} ${DashBoardClasses['line-chart-container']}`}
                                            >
                                                <defs>
                                                    <filter id="dropShadow">
                                                        <feDropShadow dx="0" dy="4" stdDeviation="4" floodColor="#1F8A70" floodOpacity="0.9" />
                                                    </filter>
                                                </defs>
                                                <defs>
                                                    <filter id="dropShadowtwo">
                                                        <feDropShadow dx="0" dy="4" stdDeviation="4" floodColor="#FF9F1C" floodOpacity="0.9" />
                                                    </filter>
                                                </defs>
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="month" />
                                                <YAxis />
                                                <Tooltip />
                                                <Legend />
                                                <Line
                                                    type="monotone"
                                                    dataKey="vacationRequests"
                                                    stroke="#1F8A70"
                                                    name="Vacation Requests"
                                                    filter="url(#dropShadow)" // Apply the shadow filter here
                                                />
                                                <Line
                                                    type="monotone"
                                                    dataKey="guestRequests"
                                                    stroke="#FF9F1C"
                                                    name="Guest Requests"
                                                    filter="url(#dropShadowtwo)"
                                                />
                                            </LineChart>
                                        </div>
                                    )
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Dashboard;
