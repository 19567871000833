import React, { useState, useEffect } from 'react';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { RxCross2 } from 'react-icons/rx';
import ProductClasses from '../products/Products.module.css';
import CommonTutorClasses from '../commoncssfiles/CategoryTutor.module.css';
import { useFieldArray } from 'react-hook-form';

const metricValueOptions = ['Kg', 'ltr', 'gm', 'ml', 'units']; // Example options

const AddProductVariant = ({ register, errors, control, setValue, onVariantsChange, varianterror }) => {
    const [variants, setVariants] = useState([{ metricType: '', metricValue: '', price: '' }]);
    console.log("variants", variants)
    useEffect(() => {
        onVariantsChange(variants);
    }, [variants, onVariantsChange]);

    const handleAddVariant = () => {
        setVariants([...variants, { metricType: '', metricValue: '', price: '' }]);
    };

    const handleRemoveVariant = (index) => {
        const updatedVariants = [...variants];
        updatedVariants.splice(index, 1);
        setVariants(updatedVariants);
    };

    const handleInputChange = (index, field, value) => {
        console.log(index, field, value, 'index, field, value')
        const updatedVariants = [...variants];
        updatedVariants[index][field] = value;
        setVariants(updatedVariants);
    };

    const {
        fields,
    } = useFieldArray({
        control,
        name: "test",
        rules: {
            minLength: 4
        }
    });

    return (
        <div className={ProductClasses['variant-section']}>
            <div style={{ marginTop: '10px' }} className={CommonTutorClasses['file-upload-wrapper']}>
                <header className={ProductClasses['nutri-cont-main']}>
                    <h3>
                        Product Variants <span className="important">*</span>
                    </h3>
                    <button type="button" onClick={handleAddVariant} className={ProductClasses['key-val-add']}>
                        <IoMdAddCircleOutline />
                    </button>
                </header>
                <div className={ProductClasses['variant-list']}>
                    {
                        varianterror ? <p className={ProductClasses['error-variant-text']}>Please add atleast one variant</p> : ''
                    }

                    {variants.map((variant, index) => (
                        <div className={ProductClasses['variant-item']} key={index}>
                            <div className={ProductClasses['form-control']}>
                                <select
                                    style={{ padding: '10px' }}
                                    value={variant.metricType}
                                    onChange={(e) => handleInputChange(index, 'metricType', e.target.value)}
                                >
                                    <option value="" disabled>Type</option>
                                    {metricValueOptions.map((option) => (
                                        <option key={option} value={option}>{option}</option>
                                    ))}
                                </select>
                                <input
                                    style={{ padding: '10px', textIndent: '100px', width: '300px' }}
                                    type="number"
                                    placeholder="Metric Value"
                                    defaultValue={variant.metricValue}
                                    onChange={(e) => handleInputChange(index, 'metricValue', e.target.value)}
                                // {...register(`variant.${index}.metricValue`, { required: true })}
                                />

                            </div>
                            <div className={ProductClasses['form-control']}>
                                <input
                                    style={{ padding: '10px' }}
                                    type="number"
                                    placeholder="New Price"
                                    value={variant.price}
                                    onChange={(e) => handleInputChange(index, 'price', e.target.value)}
                                />
                            </div>

                            <button type="button" onClick={() => handleRemoveVariant(index)} className={ProductClasses['key-remove']}>
                                <RxCross2 />
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default AddProductVariant;
