import React, { useState, useEffect } from 'react';
import Layout from 'Components/Layout';
import { Helmet } from 'react-helmet';
import { Accordion, AccordionSummary, AccordionDetails, Typography, IconButton, Dialog, DialogTitle, DialogActions, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { Services } from 'Services';
import UsersSkeleton from '../../users/UsersSkeleton';
import FaqClasses from '../faqs/Faq.module.css';
import { Success, Error_Dark } from 'Util/Toast';
import UserClasses from '../../users/Users.module.css';
import OrderClasses from '../../orders/Orders.module.css';
import CategoryClasses from '../../categories/AddCategory.module.css';
import { RxCrossCircled } from 'react-icons/rx';
import ProductClasses from '../../products/Products.module.css';
import { CiSquarePlus } from 'react-icons/ci';
import placeholderImg from '../../../../Assets/no-faq1.gif'; // Import the placeholder image

const Faq = () => {
    const [FAQs, setFAQs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedFAQId, setSelectedFAQId] = useState(null);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const navigate = useNavigate();

    const fetchFAQs = async () => {
        try {
            const token = { Authorization: `token ${localStorage.getItem('Token')}` };

            const response = await Services.getFAQs(token);
            if (response.Status === 1) {
                setFAQs(response.FAQs);
                setTotalCount(response.FAQs.length);
            } else {
                Error_Dark('Failed to fetch FAQs');
            }
        } catch (error) {
            console.error('Error fetching FAQs:', error);
            Error_Dark('An error occurred while fetching FAQs');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchFAQs();
    }, []);

    const handleEditClick = (customerSupportId) => {
        navigate(`/edit-faq/${customerSupportId}`); // Navigate to /edit-faq/:faqId page
    };

    const handleDeleteClick = (customerSupportId) => {
        setSelectedFAQId(customerSupportId);
        setShowConfirmation(true);
    };

    const handleConfirmDelete = async () => {
        if (selectedFAQId) {
            const token = { Authorization: `token ${localStorage.getItem('Token')}` };
            try {
                const response = await Services.deleteFAQ(selectedFAQId, token);
                if (response.Status === 1) {
                    setTotalCount(totalCount - 1);
                    Success('FAQ deleted successfully');
                    fetchFAQs(); // Refresh FAQs list
                } else {
                    Error_Dark('Failed to delete FAQ');
                }
            } catch (error) {
                console.error('Error deleting FAQ:', error);
                Error_Dark('An error occurred while deleting FAQ');
            } finally {
                setShowConfirmation(false);
            }
        }
    };

    const handleCancelDelete = () => {
        setShowConfirmation(false);
        setSelectedFAQId(null);
    };

    const handleAddFaqClick = () => {
        navigate('/add-faq');
    };

    return (
        <Layout Active={'FAQs'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>FAQs</title>
            </Helmet>
            <div className={UserClasses['main-first-container']}>
                <div className={ProductClasses['main-add-btn']}>
                    <Button data-aos="zoom-in" variant="contained" color="primary" onClick={handleAddFaqClick}>
                        Add FAQs <CiSquarePlus className="CiSquarePlus" />
                    </Button>
                </div>
                <div className={UserClasses['main-data-tab']}>
                    <div className={UserClasses['users-main']}>
                        <div className={OrderClasses['order-details-container']}>
                            <h2 className={OrderClasses['header-form']}>
                                FAQs <span className={UserClasses['head-count-main']}>({totalCount})</span>
                            </h2>
                            {loading ? (
                                <UsersSkeleton TableHeader={[]} /> // Loading skeleton while FAQs are loading
                            ) : (
                                <div>
                                    {FAQs.length > 0 ? (
                                        FAQs.map((faq) => (
                                            <Accordion key={faq.customerSupportId} className={FaqClasses['accordionContainer']}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    className={FaqClasses['accordionSummary']}
                                                >
                                                    <Typography className={FaqClasses['accordionQuestion']}>
                                                        Question: {faq.question}
                                                    </Typography>
                                                </AccordionSummary>
                                                <div className={FaqClasses['accordionButtonsContainer']}>
                                                    <IconButton
                                                        onClick={() => handleEditClick(faq.customerSupportId)}
                                                        className={FaqClasses['editButton']}
                                                        aria-label="Edit"
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        onClick={() => handleDeleteClick(faq.customerSupportId)}
                                                        className={FaqClasses['deleteButton']}
                                                        aria-label="Delete"
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>
                                                <AccordionDetails className={FaqClasses['accordionDetails']}>
                                                    <Typography className={FaqClasses['accordionAnswer']} >
                                                        <span className={FaqClasses['accordian-ans-head']}>Answer:</span> {faq.answer}
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        ))
                                    ) : (
                                        <div style={{ textAlign: 'center', padding: '20px' }}>
                                            <img src={placeholderImg} alt="No Data" style={{ width: '100px', height: '100px' }} data-aos="zoom-in-up" />
                                            <p>No FAQs Found</p>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {/* Confirmation Modal */}
            <Dialog
                open={showConfirmation}
                onClose={handleCancelDelete}
            >
                <div className={CategoryClasses['edit-modal-overlay']}>
                    <div className={CategoryClasses['edit-modal']}>
                        <div className={CategoryClasses['modal-content']}>
                            <h1><RxCrossCircled /></h1>
                            <DialogTitle>Are You Sure ?</DialogTitle>
                            <p>Do you really want to delete these FAQ? This process cannot be undone.</p>
                        </div>
                        <div className={CategoryClasses['modal-last-div']}>
                            <DialogActions>
                                <Button onClick={handleCancelDelete} color="primary" variant='outlined'>
                                    Cancel
                                </Button>
                                <Button onClick={handleConfirmDelete} color="secondary" variant='contained'>
                                    OK
                                </Button>
                            </DialogActions>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Layout>
    );
};

export default Faq;
