import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Modal, Button, TextField, Box, Typography } from '@mui/material';
import Layout from 'Components/Layout';
import UserClasses from '../users/Users.module.css';
import OrderClasses from '../orders/Orders.module.css';
import AdminClasses from './AdminSettings.module.css';
import { Services } from 'Services';
import { Success, Error_Dark } from 'Util/Toast';

const AdminSettings = () => {
    // State variables for modal visibility and form fields
    const [isModalOpen, setModalOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');

    // Function to reset form fields and errors
    const resetForm = () => {
        setPassword('');
        setConfirmPassword('');
        setPasswordError('');
        setConfirmPasswordError('');
    };

    // Function to handle "Change Password" button click
    const handleChangePasswordClick = () => {
        resetForm(); // Reset form fields and errors before opening modal
        setModalOpen(true);
    };

    // Function to handle form submission
    const handlePasswordChange = async (e) => {
        e.preventDefault();

        // Validate form data
        // if (!email.includes('@')) {
        //     setEmailError('Please enter a valid email address');
        //     return;
        // } else {
        //     setEmailError('');
        // }

        if (password.length < 6) {
            setPasswordError('Password must be at least 6 characters long');
            return;
        } else {
            setPasswordError('');
        }

        if (password !== confirmPassword) {
            setConfirmPasswordError('Passwords do not match');
            return;
        } else {
            setConfirmPasswordError('');
        }

        // Prepare the body for the API call
        const body = {
            newPassword: password,
            confirmPassword,
        };

        try {
            // Call the changePassword API
            const token = { Authorization: `token ${localStorage.getItem('Token')}` };
            const response = await Services.changePassword(body, token);

            // Handle the API response
            if (response.Status === 1) {
                console.log('Password changed successfully:', response);
                Success(response.Message);
                // Optionally, display success message or perform other actions
            } else {
                console.error('Failed to change password:', response);
                Error_Dark(response.Message);
                // Optionally, display error message or perform other actions
            }
        } catch (error) {
            console.error('Error changing password:', error);
            Error_Dark('An error occurred while changing the password.');
            // Optionally, display error message or perform other actions
        }

        // Close the modal after submission
        setModalOpen(false);
    };

    return (
        <Layout Active={'AdminSettings'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Admin Settings</title>
            </Helmet>
            <div className={UserClasses['main-first-container']}>
                <div className={UserClasses['main-data-tab']}>
                    <div className={UserClasses['users-main']}>
                        <div className={OrderClasses['order-details-container']}>
                            <h2 className={OrderClasses['header-form']}>Admin Settings</h2>
                            <Button data-aos="zoom-in" className={AdminClasses['change-password']} variant="contained" onClick={handleChangePasswordClick}>
                                Change Password
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

            {/* MUI Modal */}
            <Modal
                open={isModalOpen}
                onClose={() => setModalOpen(false)}
                aria-labelledby="change-password-modal-title"
                aria-describedby="change-password-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    p: 4,
                    boxShadow: 24,
                    borderRadius: 2,
                }}>
                    <h2 className={AdminClasses['heading-pass']}>Change Password</h2>
                    <form onSubmit={handlePasswordChange}>
                        
                        <div>
                            <TextField
                                label="New Password"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                fullWidth
                                margin="normal"
                                error={!!passwordError}
                                helperText={passwordError}
                            />
                        </div>
                        <div>
                            <TextField
                                label="Confirm New Password"
                                type="password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                                fullWidth
                                margin="normal"
                                error={!!confirmPasswordError}
                                helperText={confirmPasswordError}
                            />
                        </div>
                        <Button type="submit" variant="contained" sx={{ mt: 2 }}>
                            Submit
                        </Button>
                        <Button variant="outlined" sx={{ mt: 2, ml: 2 }} onClick={() => setModalOpen(false)}>
                            Cancel
                        </Button>
                    </form>
                </Box>
            </Modal>
        </Layout>
    );
};

export default AdminSettings;
