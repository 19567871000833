// AddBasket.jsx

import React, { useState } from 'react';
import Layout from 'Components/Layout';
import { Helmet } from 'react-helmet';
import UserClasses from '../users/Users.module.css';
import BasketClasses from './basket.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import ProductClasses from '../../Admin/products/Products.module.css';
import Select from 'react-select';
import { AiFillDelete } from 'react-icons/ai';
import { IoIosCreate } from "react-icons/io";
import ReactDatePicker from 'react-datepicker';


const GuestBasket = () => {
    const [products, setProducts] = useState([{ productName: '', productQuantity: '', unit: '', productPrice: '' }]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [basketPrice, setBasketPrice] = useState(500);
    const [activeFromDate, setActiveFromDate] = useState(null);
    const [activeToDate, setActiveToDate] = useState(null);

    const options = [
        { value: 'Ram', label: 'Ram' },
        { value: 'Raj', label: 'Raj' },
        { value: 'Sham', label: 'Sham' },
        { value: 'Ragav', label: 'Ragav' },
    ];

    const [selectedAssignee, setSelectedAssignee] = useState(null);


    const navigate = useNavigate();

    const units = ['liters', 'kilograms', 'milliliters', 'grams', 'units'];

    const handleChange = (index, field, value) => {
        const updatedProducts = [...products];
        updatedProducts[index][field] = value;
        setProducts(updatedProducts);
    };

    const addProductRow = () => {
        setProducts([...products, { productName: '', productQuantity: '', unit: '', productPrice: '', counter: 1 }]);
    };


    const cancelProductRow = (index) => {
        const updatedProducts = [...products];
        updatedProducts.splice(index, 1);
        setProducts(updatedProducts);
    };


    const handleCounterChange = (index, increment) => {
        const updatedProducts = [...products];
        const currentCounter = updatedProducts[index]?.counter || 0;
        updatedProducts[index].counter = currentCounter + increment;
        setProducts(updatedProducts);
    };


    const calculateProductTotalPrice = (product) => {
        const price = parseFloat(product.productPrice) || 0;
        return product.counter * price;
    };


    const onSubmit = (event) => {
        event.preventDefault();

        // Calculate total price as the sum of all product prices
        const calculatedTotalPrice = products.reduce((total, product) => {
            return total + calculateProductTotalPrice(product);
        }, 0);

        setTotalPrice(calculatedTotalPrice.toFixed(2));

        // Optionally, you can reset the form or perform any other actions.
        setProducts([{ productName: '', productQuantity: '', unit: '', productPrice: '', counter: 1 }]);
    };

    const { basketId } = useParams();

    const handleCreateClick = () => {
        if (basketId) {
            navigate(`/guest-basket/${basketId}`);
        } else {
            console.log('Invalid basketId')
        }
    }

    return (
        <Layout Active={'Complaints'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>AddBasket</title>
            </Helmet>
            <div className={UserClasses['main-first-container']}>
                <div className={UserClasses['main-data-tab']}>
                    <div className={BasketClasses['add-basket-form-container']}>
                        <div className={ProductClasses['main-add-product-btn']}>
                            <Button variant="contained" color="primary" onClick={() => navigate(-1)}>
                                BACK
                            </Button>
                        </div>
                        <form className={BasketClasses['add-basket-form']} onSubmit={onSubmit}>
                            <div className={ProductClasses['basket-add-product-btn']}>
                                <Button variant="contained" color="primary" type="button" onClick={addProductRow}>
                                    ADD PRODUCT
                                </Button>
                            </div>
                            {products.map((product, index) => (
                                <div key={index} className={BasketClasses['main-inp-basket']}>
                                    <input
                                        type="text"
                                        placeholder="Product Name"
                                        value={product.productName}
                                        onChange={(e) => handleChange(index, 'productName', e.target.value)}

                                    />
                                    <div className={BasketClasses['basket-inp-qty']}>
                                        <select
                                            value={product.unit}
                                            onChange={(e) => handleChange(index, 'unit', e.target.value)}
                                        >
                                            <option value="" disabled>
                                                Unit
                                            </option>
                                            {units.map((unit) => (
                                                <option key={unit} value={unit}>
                                                    {unit}
                                                </option>
                                            ))}
                                        </select>
                                        <input
                                            type="number"
                                            placeholder="Quantity"
                                            value={product.productQuantity}
                                            onChange={(e) => handleChange(index, 'productQuantity', e.target.value)}
                                            className={BasketClasses['pro-name-inp']}
                                        />
                                    </div>
                                    <input
                                        type="number"
                                        placeholder="Product Price"
                                        value={product.productPrice}
                                        onChange={(e) => handleChange(index, 'productPrice', parseFloat(e.target.value))}
                                    />
                                    <div className={BasketClasses['counter-container']}>
                                        <Button
                                            variant="contained"
                                            style={{ backgroundColor: '#C2CDDF', color: '#fff', width: '40px', padding: '5px' }}
                                            type="button"
                                            onClick={() => handleCounterChange(index, -1)}
                                            className={BasketClasses['add-pro-btn-cancel']}
                                        >
                                            -
                                        </Button>
                                        <span className={BasketClasses['counter-value']}>{product.counter}</span>
                                        <Button
                                            variant="contained"
                                            style={{ backgroundColor: '#C2CDDF', color: '#fff', width: '40px', padding: '5px' }}
                                            type="button"
                                            onClick={() => handleCounterChange(index, 1)}
                                            className={BasketClasses['add-pro-btn-cancel']}
                                        >
                                            +
                                        </Button>
                                    </div>
                                    <Button className={BasketClasses['del-pro-btn-cancel']} type="button" onClick={() => cancelProductRow(index)}>
                                        <AiFillDelete />
                                    </Button>
                                </div>
                            ))}

                            <div className={BasketClasses['guest-status-main']}>
                                <div>
                                    <label>Active from:</label><br/>
                                    <ReactDatePicker
                                        selected={activeFromDate}
                                        onChange={(date) => setActiveFromDate(date)}
                                        dateFormat="MM/dd/yyyy"
                                        placeholderText="Select Date"
                                    />
                                </div>
                                <div>
                                    <label>Active to:</label><br/>
                                    <ReactDatePicker
                                        selected={activeToDate}
                                        onChange={(date) => setActiveToDate(date)}
                                        dateFormat="MM/dd/yyyy"
                                        placeholderText="Select Date"
                                    />
                                </div>
                            </div>

                            {totalPrice > 0 && (
                                <div className={BasketClasses['add-basket-form-total']}>
                                    <p>Total Price: {totalPrice}</p>
                                    <div className={BasketClasses['basket-price-container']}>
                                        <label>Basket Price:</label>
                                        <input
                                            type="number"
                                            placeholder="Basket Price"
                                            value={basketPrice}
                                            onChange={(e) => setBasketPrice(parseFloat(e.target.value))}
                                        />
                                    </div>
                                    <label className={BasketClasses['assign-label']}>Assign to:</label>
                                    <Select
                                        options={options}
                                        value={selectedAssignee}
                                        onChange={(selectedOption) => setSelectedAssignee(selectedOption)}
                                        isSearchable
                                        className={BasketClasses['searchable-addbasket']}
                                    />
                                </div>
                            )}
                            <div className={BasketClasses['add-basket-sub-main']}>
                                <Button variant="contained" style={{ backgroundColor: '#04AA6D', color: '#fff', width: '100px' }} type="submit">Submit</Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default GuestBasket;
