import React, { useEffect, useState } from 'react';
import Layout from 'Components/Layout';
import UserClasses from '../users/Users.module.css';
import { Helmet } from 'react-helmet';
import { couponsTableHeads } from '../constants/Constants';
import { SkeletonTheme } from 'react-loading-skeleton';
import UsersSkeleton from '../users/UsersSkeleton';
import ProductClasses from '../products/Products.module.css';
import { Button, Modal, Box, Typography } from '@mui/material';
import { CiSquarePlus } from 'react-icons/ci';
import { useNavigate } from 'react-router-dom';
import { Services } from 'Services';
import OrderClasses from '../orders/Orders.module.css';
import { MenuItem, FormControl, Select } from '@mui/material';
import { Success } from 'Util/Toast';
import CouponClasses from '../coupons/Coupons.module.css';
import TablePagination from '@mui/material/TablePagination';
import { GiPartyPopper } from "react-icons/gi";
import coupondiscount from '../../../Assets/Discount.gif'


const Coupons = () => {
    const [loading, setLoading] = useState(true);
    const [coupons, setCoupons] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedCoupon, setSelectedCoupon] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const token = { Authorization: `token ${localStorage.getItem('Token')}` };
        Services.getCoupons(token)
            .then(response => {
                const updatedCoupons = response.data.map(coupon => ({
                    ...coupon,
                    couponStatus: coupon.couponStatus === 1 ? 'Active' : 'Inactive'
                }));
                setCoupons(updatedCoupons);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching coupons:', error);
                setLoading(false);
            });
    }, []);

    const handleCreateCoupon = () => {
        navigate('/create-coupon');
    };

    const handleCouponStatusChange = async (event, couponId) => {
        try {
            const token = { Authorization: `token ${localStorage.getItem('Token')}` };
            const newStatus = event.target.value;

            const response = await Services.changeCouponStatus(couponId, newStatus === 'Active' ? 1 : 0, token);

            if (response && response.Status === 1) {
                const updatedCoupons = coupons.map(coupon => {
                    if (coupon.couponId === couponId) {
                        return { ...coupon, couponStatus: newStatus };
                    } else {
                        return coupon;
                    }
                });
                setCoupons(updatedCoupons);
                Success(response.Message);
            } else {
                console.error('Error changing coupon status:', response && response.Message);
            }
        } catch (error) {
            console.error('Error changing coupon status:', error);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const indexOfLastItem = (page + 1) * rowsPerPage;
    const indexOfFirstItem = indexOfLastItem - rowsPerPage;

    const currentCoupons = coupons.slice(indexOfFirstItem, indexOfLastItem);

    const handleOpenModal = (coupon) => {
        setSelectedCoupon(coupon);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedCoupon(null);
    };

    return (
        <Layout Active={'Coupons'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Coupons</title>
            </Helmet>
            <div className={UserClasses['main-first-container']}>
                <div className={ProductClasses['main-add-btn']}>
                    <Button variant="contained" color="primary" onClick={handleCreateCoupon}>
                        Create Coupon <CiSquarePlus className="CiSquarePlus" />
                    </Button>
                </div>
                <div className={UserClasses['main-data-tab']}>
                    <div className={UserClasses['users-main']}>
                        <div>
                            <h2 className={OrderClasses['header-form']}>
                                Coupons
                            </h2>
                            <SkeletonTheme color='#ddd' highlightColor='#eee'>
                                {loading ? (
                                    <UsersSkeleton TableHeader={couponsTableHeads} />
                                ) : (
                                    <div style={{ overflowY: 'hidden' }}>
                                        {currentCoupons.length > 0 ? (
                                            <table className={ProductClasses['category-table']}>
                                                <thead>
                                                    <tr className={UserClasses['main-table-heads']}>
                                                        {couponsTableHeads.map((head, index) => (
                                                            <th key={index} className={ProductClasses['products-table-head']}>{head}</th>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {currentCoupons.map((coupon, index) => (
                                                        <tr key={coupon.couponId}>
                                                            <td>{coupon.couponName}</td>
                                                            <td>{coupon.couponCode}</td>
                                                            <td>{coupon.discount}</td>
                                                            <td>
                                                                <FormControl variant="outlined" size="small">
                                                                    <Select
                                                                        className={CouponClasses['coupon-drop']}
                                                                        value={coupon.couponStatus}
                                                                        onChange={(e) => handleCouponStatusChange(e, coupon.couponId)}
                                                                    >
                                                                        <MenuItem value={'Active'}>Active</MenuItem>
                                                                        <MenuItem value={'Inactive'}>Inactive</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </td>
                                                            <td>
                                                                <Button
                                                                    variant='contained'
                                                                    color='secondary'
                                                                    onClick={() => handleOpenModal(coupon)}
                                                                >
                                                                    View More
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        ) : (
                                            <p>No Records Found</p>
                                        )}
                                    </div>
                                )}

                                <div className={UserClasses['table-pagination']}>
                                    <div className={UserClasses['current-page']}>
                                        <p>Current Page: {page + 1}</p>
                                    </div>

                                    <TablePagination
                                        component="div"
                                        count={coupons.length}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        rowsPerPage={rowsPerPage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </div>
                            </SkeletonTheme>
                        </div>
                    </div>
                </div>
            </div>

            <Modal open={modalOpen} onClose={handleCloseModal}>
                <Box className={CouponClasses["modal-box"]} sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    border: '2px solid black;',
                    boxShadow: 24,
                    p: 4
                }}>
                    <div className={CouponClasses['couponp-first']}>
                        <div className={CouponClasses['first-coupon-main']}>
                            <Typography className={CouponClasses["modal-title"]} variant="h6" component="h2">
                                {selectedCoupon?.couponName}<GiPartyPopper className={CouponClasses['popper']} />
                            </Typography>
                            <img className={CouponClasses['discount-one']} src={coupondiscount}></img>
                        </div>
                        <div className={CouponClasses['second-coupon-main']}>
                        </div>
                        <div className={CouponClasses['third-coupon-main']}>
                            <Typography className={CouponClasses["modal-content"]} variant="body1">
                                Coupon Name: {selectedCoupon?.couponName}
                            </Typography>
                            <Typography className={CouponClasses["modal-content"]} variant="body1">
                                Code: {selectedCoupon?.couponCode}
                            </Typography>
                            <Typography className={CouponClasses["modal-content"]} variant="body1">
                                Discount: {selectedCoupon?.discount}
                            </Typography>
                            <Typography className={CouponClasses["modal-content"]} variant="body1">
                                Status: {selectedCoupon?.couponStatus}
                            </Typography>
                            <Typography className={CouponClasses["modal-content"]} variant="body1">
                                Activation Date: {selectedCoupon?.activationDate}
                            </Typography>
                            <Typography className={CouponClasses["modal-content"]} variant="body1">
                                expirationDate: {selectedCoupon?.expirationDate}
                            </Typography>
                            <Typography className={CouponClasses["modal-content"]} variant="body1">
                                Discount Type: {selectedCoupon?.discountType}
                            </Typography>
                            <Typography className={CouponClasses["modal-content"]} variant="body1">
                                Coupon Description: {selectedCoupon?.couponDescription}
                            </Typography>
                        </div>
                    </div>
                    <Button variant="contained" color="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Box>
            </Modal>
        </Layout>
    );
};

export default Coupons;

