import React, { useState, useEffect } from 'react';
import Layout from 'Components/Layout';
import { Helmet } from 'react-helmet';
import UserClasses from '../users/Users.module.css';
import OrderClasses from '../orders/Orders.module.css';
import ProductClasses from '../products/Products.module.css';
import { Services } from 'Services';
import UsersSkeleton from '../users/UsersSkeleton';
import { complaintTableHeads } from '../constants/Constants';
import { Success } from 'Util/Toast';
import CouponClasses from '../coupons/Coupons.module.css';
import { TablePagination } from '@mui/material';
import TicketClasses from '../support/TicketDetails.module.css';
import { SkeletonTheme } from 'react-loading-skeleton';
import placeholderImg from '../../../Assets/no-complaints1.gif'; // Import the placeholder image

const Complaints = () => {
    const [complaints, setComplaints] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalCount, setTotalCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        const fetchComplaints = async () => {
            try {
                const token = { Authorization: `token ${localStorage.getItem('Token')}` };
                const response = await Services.getComplaints(token);

                if (response.Status === 1) {
                    setComplaints(response.complaints);
                }
                setTotalCount(response.complaints.length);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching complaints:', error);
                setLoading(false);
            }
        };

        fetchComplaints();
    }, []);

    const handleStatusChange = async (complaintId, newStatus) => {
        try {
            const token = { Authorization: `token ${localStorage.getItem('Token')}` };
            const response = await Services.changeComplaintStatus(complaintId, newStatus, token);
            console.log(`Complaint ${complaintId} status changed to ${newStatus}`);

            setComplaints((prevComplaints) =>
                prevComplaints.map((complaint) =>
                    complaint.complaintId === complaintId ? { ...complaint, complaintStatus: newStatus } : complaint
                )
            );
            Success(response.Message);
        } catch (error) {
            console.error('Error changing complaint status:', error);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const indexOfLastItem = (page + 1) * rowsPerPage;
    const indexOfFirstItem = indexOfLastItem - rowsPerPage;
    const currentComplaints = complaints.slice(indexOfFirstItem, indexOfLastItem);

    const statusOptions = [
        { value: 0, label: 'Open' },
        { value: 1, label: 'Pending' },
        { value: 2, label: 'In Progress' },
        { value: 3, label: 'Resolved' },
        { value: 4, label: 'Closed' },
    ];

    return (
        <Layout Active={'Complaints'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Complaints</title>
            </Helmet>
            <div className={UserClasses['main-first-container']}>
                <div className={UserClasses['main-data-tab']}>
                    <div className={UserClasses['users-main']}>
                        <div>
                            <h2 className={OrderClasses['header-form']}>
                                Complaints <span className={UserClasses['head-count-main']}>({totalCount})</span>
                            </h2>
                            <SkeletonTheme color='#ddd' highlightColor='#eee'>
                                {loading ? (
                                    <UsersSkeleton TableHeader={complaintTableHeads} />
                                ) : (
                                    <div style={{ overflowY: 'hidden' }}>
                                        {currentComplaints.length > 0 ? (
                                            <table className={ProductClasses['category-table']}>
                                                <thead>
                                                    <tr className={UserClasses['main-table-heads']}>
                                                        <th>USER NAME</th>
                                                        <th>DESCRIPTION</th>
                                                        <th>MOBILE NUMBER</th>
                                                        <th>STATUS</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {currentComplaints.map((complaint) => (
                                                        <tr key={complaint.complaintId} data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                                                            <td>{complaint.fullName}</td>
                                                            <td style={{wordBreak: 'break-all', textTransform: 'lowercase', textAlign: 'justify', width: '60%'}}>{complaint.complaintDescription}</td>
                                                            <td>{complaint.mobileNumber}</td>
                                                            <td>
                                                                <select
                                                                    value={complaint.complaintStatus}
                                                                    onChange={(e) => handleStatusChange(complaint.complaintId, parseInt(e.target.value))}
                                                                >
                                                                    {statusOptions.map((option) => (
                                                                        <option key={option.value} value={option.value}>
                                                                            {option.label}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        ) : (
                                            <div style={{ textAlign: 'center', padding: '20px' }}>
                                                <img src={placeholderImg} alt="No Data" style={{ width: '100px', height: '100px' }} data-aos="zoom-in-up"/>
                                                <p>No Complaints Found</p>
                                            </div>
                                        )}
                                    </div>
                                )}
                                <div className={UserClasses['table-pagination']}>
                                    <div className={UserClasses['current-page']}>
                                        <p>Current Page: {page + 1}</p>
                                    </div>
                                    <TablePagination
                                        component="div"
                                        count={complaints.length}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        rowsPerPage={rowsPerPage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </div>
                            </SkeletonTheme>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Complaints;
