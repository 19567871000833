import React, { useState, useEffect } from 'react';
import Layout from 'Components/Layout';
import { Helmet } from 'react-helmet';
import UserClasses from '../users/Users.module.css';
import BasketClasses from './basket.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material'; // Import Button from Material-UI
import ProductClasses from '../../Admin/products/Products.module.css';
import Select from 'react-select';
import { AiFillDelete } from "react-icons/ai";
import { Services } from 'Services';
import { Error_Dark, Success } from 'Util/Toast';

const EditBasket = () => {
    const { userId, basketId } = useParams();
    const [basketName, setBasketName] = useState('');
    const [products, setProducts] = useState([{ productName: '', productId: '', qty: '', variants: [] }]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [basketPrice, setBasketPrice] = useState(0);
    const [basketOfferPrice, setBasketOfferPrice] = useState(0);
    const [selectedAssignee, setSelectedAssignee] = useState([]);
    const [productOptions, setProductOptions] = useState([]);
    const [variantOptions, setVariantOptions] = useState([]);
    const [formErrors, setFormErrors] = useState({});
    const [users, setUsers] = useState([]);
    const [userOptions, setUserOptions] = useState([]); // State to hold user options

    const navigate = useNavigate();

    useEffect(() => {
        const fetchBasketData = async () => {
            try {
                const token = { Authorization: `token ${localStorage.getItem('Token')}` };
                const response = await Services.getBasketById(basketId, token);
                if (response.Status === 1) {
                    setBasketName(response.basketName);
                    setSelectedAssignee(response.users.map(user => ({ value: user.userId, label: user.fullName })));

                    // Extract products from the baskets in the api response.
                    const basketProducts = response.basket.map(product => ({
                        productName: product.productName,
                        productId: product.productId,
                        qty: product.qty,
                        variantId: product.variantId, // Add variantId to product data
                        variants: [{
                            variantId: product.variantId, metricValue: product.metricValue, metricType: product.metricType, price: product.price, value: product.variantId, // Use variantId instead of varaintId
                            label: `${product.metricValue} ${product.metricType} - ₹${product.price}`, // Update price label
                        }]
                    }));

                    // Set products
                    setProducts(basketProducts);

                    // Set basket price
                    setBasketPrice(response.basket.reduce((totalPrice, product) => totalPrice + product.price * product.qty, 0));

                    // Set variant options based on retrieved variants
                    const initialVariantOptions = basketProducts.map(product => product.variants.map(variant => ({
                        value: variant.variantId, // Use variantId instead of varaintId
                        label: `${variant.metricValue} ${variant.metricType} - ₹${variant.price}`, // Update price label
                    })));
                    setVariantOptions(initialVariantOptions);
                }
            } catch (error) {
                console.error('Error fetching basket data:', error);
            }
        };

        fetchBasketData();
    }, [basketId]);






    // Fetch product data using the getProducts API
    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const token = { Authorization: `token ${localStorage.getItem('Token')}` };
                const response = await Services.getBasketProducts(token);
                if (response.Status === 1) {
                    const options = response.products.map((product) => ({
                        value: product.productId,
                        label: product.productName,
                        variants: product.variants, // Store variants in options
                    }));
                    setProductOptions(options);
                }
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchProducts();
    }, []);

    // Function to handle product selection
    const handleProductChange = (index, value) => {
        const updatedProducts = [...products];
        const selectedProduct = productOptions.find(option => option.value === value);
        if (selectedProduct) {
            updatedProducts[index].productName = selectedProduct.label;
            updatedProducts[index].productId = selectedProduct.value;

            // Check if selected product has variants
            if (selectedProduct.variants && selectedProduct.variants.length > 0) {
                // Map variants to create an array of variant options
                const productVariants = selectedProduct.variants.map(variant => ({
                    value: variant.variantId,
                    label: `${variant.metricValue} ${variant.metricType} - $${variant.price}`,
                    price: variant.price
                }));
                updatedProducts[index].variants = productVariants;

                // Update variantOptions state with the new variant options
                const updatedVariantOptions = [...variantOptions];
                updatedVariantOptions[index] = productVariants;
                setVariantOptions(updatedVariantOptions);
            } else {
                // If no variants are available, set an empty array
                updatedProducts[index].variants = [];
                setVariantOptions([]);
            }
        }
        setProducts(updatedProducts);

        // Recalculate the basket price after changing the product
        calculateBasketPrice(updatedProducts);
    };




    // Function to add a new product row
    const addProductRow = () => {
        setProducts([...products, { productName: '', productId: '', qty: '', variants: [] }]);
    };

    // Function to remove a product row
    const cancelProductRow = (index) => {
        const updatedProducts = [...products];
        if (updatedProducts.length === 1) {
            Error_Dark("At least one product must remain in the basket."); // Display error message
            return; // Do not delete if there is only one product
        }
        updatedProducts.splice(index, 1);
        setProducts(updatedProducts);

        // Recalculate the basket price after removing a product row
        calculateBasketPrice(updatedProducts); // Pass updatedProducts to calculateBasketPrice
    };

    // Function to handle variant selection
    const handleVariantChange = (index, value) => {
        const updatedProducts = [...products];
        const selectedVariant = updatedProducts[index].variants.find(variant => variant.value === value);
        if (selectedVariant) {
            console.log(selectedVariant, "selected variant")
            updatedProducts[index].variantId = value;
            updatedProducts[index].productPrice = selectedVariant.price; // Update product price with selected variant price
            updatedProducts[index].qty = 0; // Reset quantity to 0 when a new variant is selected
        }
        setProducts(updatedProducts);

        // Recalculate the basket price after changing the variant and resetting the quantity
        calculateBasketPrice(updatedProducts);
    };

    // Function to handle quantity changes
    const handleCounterChange = (index, increment) => {
        console.log("handleCounterChange");

        const updatedProducts = [...products];
        const currentQty = parseInt(updatedProducts[index].qty) || 0;
        if (currentQty + increment >= 1
        ) {
            updatedProducts[index].qty = currentQty + increment;
            setProducts(updatedProducts);
            console.log(updatedProducts, index);

            // Recalculate the basket price after changing the quantity
            calculateBasketPrice(updatedProducts);
        }
    };

    // Function to calculate basket price based on products array
    const calculateBasketPrice = (updatedProducts) => {
        console.log("all products", updatedProducts);
        const total = updatedProducts.reduce((sum, product) => {
            // Find the selected variant based on the variantId
            const selectedVariant = product.variants.find(variant => variant.value === product.variantId);
            if (!selectedVariant) return sum;

            // Extract the product price from the selected variant
            const productPrice = parseFloat(selectedVariant.price) || 0;
            console.log(productPrice, "pro price");
            const qty = parseFloat(product.qty) || 0;
            console.log("this is sample", qty);
            // Calculate the total price for each product
            const productTotalPrice = productPrice * qty;
            console.log(productTotalPrice, "this is kikiki");

            // Add the product's total price to the sum
            return sum + productTotalPrice;
        }, 0);
        // Update the basket price state with the calculated total
        setBasketPrice(total);
    };



    // Function to validate the form
    const validateForm = () => {
        const errors = {};

        // Validate each product
        products.forEach((product, index) => {
            if (!product.productName || product.productId === '') {
                errors[`product_${index}`] = 'Please select a product.';
            }
            if (!product.qty) {
                errors[`qty_${index}`] = 'Please enter a quantity.';
            }
        });

        // Validate basket prices
        if (basketPrice === '' || basketPrice <= 0) {
            errors.basketPrice = 'Please enter a valid basket price.';
        }

        // Check if there are any errors
        setFormErrors(errors);

        // Return whether the form is valid (no errors)
        return Object.keys(errors).length === 0;
    };

    // Function to handle form submission
    // Function to handle form submission
    const onSubmit = async (event) => {
        event.preventDefault();
        const formIsValid = validateForm();

        if (!formIsValid) {
            return;
        }

        const userIds = selectedAssignee.map((assignee) => assignee.value);

        const basketData = {
            userIds: userIds,
            basketName: basketName,
            basketOfferPrice: parseFloat(basketOfferPrice),
            products: products.map((product) => ({
                productId: product.productId,
                variantId: product.variantId,
                qty: parseInt(product.qty),
            })),
        };

        try {
            const token = { Authorization: `token ${localStorage.getItem('Token')}` };
            const response = await Services.editBasket(basketId, basketData, token); // Call editBasket API
            if (response.Status === 1) {
                Success(response.Message);
                navigate('/basket'); // Redirect to home page after successful submission
            } else {
                Error_Dark(response.ErrorMessage);
            }
        } catch (error) {
            console.error('Error editing basket:', error);
            Error_Dark('Error editing basket');
        }
    };


    // Fetch users using the getUsers API
    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const token = { Authorization: `token ${localStorage.getItem('Token')}` };
                const response = await Services.getUsers(0, token);
                if (response.Status === 1) {
                    setUsers(response.users); // Update users state
                    // Create options array for Select component
                    const options = response.users.map((user) => ({
                        value: user.userId,
                        label: user.fullName,
                    }));
                    setUserOptions(options); // Update user options state
                }
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        fetchUsers();
    }, []);

    const handleUserChange = (selectedOptions) => {
        setSelectedAssignee(selectedOptions);
    };

    return (
        <Layout Active={'Complaints'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Edit Basket</title>
            </Helmet>
            <div className={UserClasses['main-first-container']}>
                <div className={UserClasses['main-data-tab']}>
                    <div className={BasketClasses['add-basket-form-container']}>
                        <div className={ProductClasses['main-add-product-btn']}>
                            <Button variant="contained" color="primary" onClick={() => navigate(-1)}>
                                BACK
                            </Button>
                        </div>
                        <form className={BasketClasses['add-basket-form']} onSubmit={onSubmit} data-aos="fade-up"
                        data-aos-anchor-placement="top-bottom">
                            <div className={ProductClasses['basket-add-product-btn']}>
                                <Button variant="contained" color="primary" type="button" onClick={addProductRow}>
                                    ADD PRODUCT
                                </Button>
                            </div>
                            <div className={BasketClasses['basket-name-field']}>
                                {/* Input for Basket Name */}
                                <input
                                    type="text"
                                    placeholder="Basket Name"
                                    value={basketName}
                                    onChange={(e) => setBasketName(e.target.value)}
                                    className={BasketClasses['basket-name-input']}
                                />
                            </div>

                            {products.map((product, index) => (
                                <div key={index} className={BasketClasses['main-inp-basket']}>
                                    <Select
                                        value={productOptions.find(option => option.value === product.productId)}
                                        options={productOptions}
                                        onChange={(selectedOption) => handleProductChange(index, selectedOption.value)}
                                        placeholder="Select product"
                                        className={BasketClasses['product-select-list']}
                                    />
                                    {/* Display error message for product selection */}
                                    {formErrors[`product_${index}`] && (
                                        <div className={BasketClasses['error-message']}>
                                            {formErrors[`product_${index}`]}
                                        </div>
                                    )}
                                    <Select
                                        value={variantOptions[index] && variantOptions[index].find(option => option.value === product.variantId)}
                                        options={variantOptions[index] || []}
                                        onChange={(selectedOption) => handleVariantChange(index, selectedOption.value)}
                                        placeholder="Select variant"
                                        className={BasketClasses['product-select-list']}
                                    />

                                    <div className={BasketClasses['basket-inp-qty']}>
                                        <input
                                            type="number"
                                            placeholder="Quantity"
                                            value={product.qty}
                                            onChange={(e) => handleVariantChange(index, 'qty', e.target.value)}
                                            className={BasketClasses['pro-name-inp']}
                                        />
                                        {/* Display error message for quantity input */}
                                        {formErrors[`qty_${index}`] && (
                                            <div className={BasketClasses['error-message']}>
                                                {formErrors[`qty_${index}`]}
                                            </div>
                                        )}
                                    </div>
                                    <div className={BasketClasses['counter-container']}>
                                        <Button
                                            variant="contained"
                                            style={{ backgroundColor: '#C2CDDF', color: '#fff', width: '40px' }}
                                            type="button"
                                            onClick={() => handleCounterChange(index, -1)}
                                            className={BasketClasses['add-pro-btn-cancel']}
                                        >
                                            -
                                        </Button>
                                        <span className={BasketClasses['counter-value']}>{product.qty}</span>
                                        <Button
                                            variant="contained"
                                            style={{ backgroundColor: '#C2CDDF', color: '#fff', width: '40px' }}
                                            type="button"
                                            onClick={() => handleCounterChange(index, 1)}
                                            className={BasketClasses['add-pro-btn-cancel']}
                                        >
                                            +
                                        </Button>
                                    </div>
                                    <Button
                                        className={BasketClasses['del-pro-btn-cancel']}
                                        type="button"
                                        onClick={() => cancelProductRow(index)} // Make sure index is defined and passed correctly
                                    >
                                        <AiFillDelete />
                                    </Button>
                                </div>
                            ))}
                            <div className={BasketClasses['add-basket-form-total']}>
                                <div className={BasketClasses['basket-price-container']}>
                                    <label>Basket Price:</label>
                                    <input
                                        type="number"
                                        placeholder="Basket Price"
                                        value={basketPrice}
                                        // onChange={(e) => setBasketPrice(parseFloat(e.target.value))}
                                    />
                                    {/* Display error message for basket price input */}
                                    {formErrors.basketPrice && (
                                        <div className={BasketClasses['error-message']}>
                                            {formErrors.basketPrice}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className={BasketClasses['add-basket-sub-main']}>
                                <Button
                                    variant="contained"
                                    style={{ backgroundColor: '#04AA6D', color: '#fff', width: '100px' }}
                                    type="submit"
                                >
                                    Submit
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default EditBasket;

