import React, { useState } from 'react';
import Layout from 'Components/Layout';
import UserClasses from '../users/Users.module.css';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { Services } from 'Services';
import { Success, Error } from 'Util/Toast'; // Import toast messages for success and error

const AddSubscription = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        planName: '',
        basicPlanAmount: '',
        basicAdultCount: '',
        basicChildCount: '',
        extraAdultAmount: '',
        extraChildAmount: '',
        status: '',
        planLimitDays: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Validate form fields before submitting
            if (!formData.planName || !formData.basicPlanAmount || !formData.basicAdultCount || !formData.basicChildCount || !formData.extraAdultAmount || !formData.extraChildAmount || !formData.status || !formData.planLimitDays) {
                Error('All fields are required'); // Display error message if any field is empty
                return;
            }
            const token = { Authorization: `token ${localStorage.getItem('Token')}` };
            const response = await Services.addPlan(formData, token); // Call addPlan API with form data
            console.log(response); // Log the response
            // You can add further logic here based on the response
            navigate('/subscriptionplans')
            Success(response.Message)
        } catch (error) {
            console.error('Error adding plan:', error);
            // Handle error as needed
        }
    };

    return (
        <Layout Active={'SubscriptionPlans'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Add Subscription</title>
            </Helmet>
            <div className={UserClasses['main-first-container']}>
                <div className={UserClasses['main-data-tab']}>
                    <Button variant="contained" color="primary" onClick={() => navigate(-1)}>
                        BACK
                    </Button>
                    <form style={{
                        maxWidth: '600px',
                        margin: '0 auto',
                        padding: '20px',
                        border: '1px solid #ccc',
                        borderRadius: '8px',
                        background: 'white',
                    }} onSubmit={handleSubmit}>
                        <div style={{ marginBottom: '20px' }}>
                            <TextField
                                label="Plan Name"
                                name="planName"
                                value={formData.planName}
                                onChange={handleChange}
                                fullWidth
                            />
                        </div>
                        <div style={{ marginBottom: '20px' }}>
                            <TextField
                                label="Basic Plan Amount"
                                name="basicPlanAmount"
                                value={formData.basicPlanAmount}
                                onChange={handleChange}
                                fullWidth
                                type="number" // Set input type to number for numeric values
                            />
                        </div>
                        <div style={{ marginBottom: '20px' }}>
                            <TextField
                                label="Basic Adult Count"
                                name="basicAdultCount"
                                value={formData.basicAdultCount}
                                onChange={handleChange}
                                fullWidth
                                type="number" // Set input type to number for numeric values
                            />
                        </div>
                        <div style={{ marginBottom: '20px' }}>
                            <TextField
                                label="Basic Child Count"
                                name="basicChildCount"
                                value={formData.basicChildCount}
                                onChange={handleChange}
                                fullWidth
                                type="number" // Set input type to number for numeric values
                            />
                        </div>
                        <div style={{ marginBottom: '20px' }}>
                            <TextField
                                label="Extra Adult Amount"
                                name="extraAdultAmount"
                                value={formData.extraAdultAmount}
                                onChange={handleChange}
                                fullWidth
                                type="number" // Set input type to number for numeric values
                            />
                        </div>
                        <div style={{ marginBottom: '20px' }}>
                            <TextField
                                label="Extra Child Amount"
                                name="extraChildAmount"
                                value={formData.extraChildAmount}
                                onChange={handleChange}
                                fullWidth
                                type="number" // Set input type to number for numeric values
                            />
                        </div>
                        <div style={{ marginBottom: '20px' }}>
                        <FormControl fullWidth>
                            <InputLabel>Status</InputLabel>
                            <Select
                                name="status"
                                value={formData.status}
                                onChange={handleChange}
                            >
                                <MenuItem value={0}>Inactive</MenuItem>
                                <MenuItem value={1}>Active</MenuItem>
                            </Select>
                        </FormControl>
                        </div>
                        <div style={{ marginBottom: '20px' }}>
                            <TextField
                                label="Plan Limit Days"
                                name="planLimitDays"
                                value={formData.planLimitDays}
                                onChange={handleChange}
                                fullWidth
                                type="number" // Set input type to number for numeric values
                            />
                        </div>
                        <Button type="submit" variant="contained" color="primary">
                            Submit
                        </Button>
                    </form>
                </div>
            </div>
        </Layout>
    );
};

export default AddSubscription;
