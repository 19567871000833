import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './App.css';
import 'react-loading-skeleton/dist/skeleton.css';
import ScrollToTop from "./Util/ScrollToTop";
import Router from './Routes/routes';
import AccessDenied from "./pages/AccessDenied/Popup";
import useStore from "./store";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { ThemeProvider } from './pages/Admin/theme/ThemeContext'; // Import the ThemeProvider

AOS.init();

function App() {
  const { showAccessDenied, setShowAccessDenied } = useStore(item => item);

  return (
    <ThemeProvider>
      <AccessDenied showModal={showAccessDenied} setModal={setShowAccessDenied} />
      <ScrollToTop />
      <ToastContainer />
      <Router />
    </ThemeProvider>
  );
}

export default App;
