import uuid from "react-uuid";
import { AiOutlineDashboard } from "react-icons/ai";
import { FaPeopleGroup } from "react-icons/fa6";
import { GiCardboardBoxClosed } from "react-icons/gi";
import { BiSolidDonateHeart } from "react-icons/bi";
import { IoSettingsSharp } from "react-icons/io5";
import { RiCustomerService2Line } from "react-icons/ri";
import { FcDataConfiguration } from "react-icons/fc";
import { BiSolidShoppingBagAlt } from "react-icons/bi";
import { TbCategoryPlus } from "react-icons/tb";
import { RiShoppingBasketFill } from "react-icons/ri";
import { IoWallet } from "react-icons/io5";
import { RiCoupon2Fill } from "react-icons/ri";
import { MdDeliveryDining } from "react-icons/md";
import { RiCommunityFill } from "react-icons/ri";
import DashboardIcon from "../../Assets/side-dashboardgif-img-1.png.gif";
import BasketIcon from "../../Assets/side-basketimg-6.png";
import UsersIcon from "../../Assets/side-users-7.png";
import CategoryIcon from "../../Assets/side-categories2-img.png";
import BannerIcon from '../../Assets/side-banners-img.png'
import ProductIcon from "../../Assets/side-productsimg-4.png";
import OrdersIcon from "../../Assets/side-ordersimg-7.png";
import DeliveryBoyIcon from "../../Assets/side-deliveryboyimg-4.png";
import CommunityIcon from "../../Assets/side-communityimg-3.png";
import WalletIcon from "../../Assets/side-walletimg-3.png";
import AccountSettingsIcon from "../../Assets/side-accountsettingsimg-3.png";
import SupportIcon from "../../Assets/side-supportimg-2.png";

const FlaticonDashboard = () => (
   <img src={DashboardIcon} alt="Dashboard Icon" style={{ width: '2em', height: '2em' }} />
);

const FlaticonBasket = () => (
   <img src={BasketIcon} alt="Basket Icon" style={{ width: '2em', height: '2em' }} />
);

const FlaticonUser = () => (
   <img src={UsersIcon} alt="Basket Icon" style={{ width: '2em', height: '2em' }} />
);

const FlaticonCategory = () => (
   <img src={CategoryIcon} alt="Basket Icon" style={{ width: '2em', height: '2em' }} />
);

const FlaticonBanner = () => (
   <img src={BannerIcon} alt="Basket Icon" style={{ width: '2em', height: '2em' }} />
);

const FlaticonProduct = () => (
   <img src={ProductIcon} alt="Basket Icon" style={{ width: '2em', height: '2em' }} />
);

const FlaticonOrder = () => (
   <img src={OrdersIcon} alt="Basket Icon" style={{ width: '2em', height: '2em' }} />
);

const FlaticonDeliveryBoy = () => (
   <img src={DeliveryBoyIcon} alt="Basket Icon" style={{ width: '2em', height: '2em' }} />
);

const FlaticonCommunity = () => (
   <img src={CommunityIcon} alt="Basket Icon" style={{ width: '2em', height: '2em' }} />
);

const FlaticonWallet = () => (
   <img src={WalletIcon} alt="Basket Icon" style={{ width: '2em', height: '2em' }} />
);

const FlaticonAccountSettings = () => (
   <img src={AccountSettingsIcon} alt="Basket Icon" style={{ width: '2em', height: '2em' }} />
);

const FlaticonSupport = () => (
   <img src={SupportIcon} alt="Basket Icon" style={{ width: '2em', height: '2em' }} />
);

export const permissions = {
   Users: { read: ["view-Users"] },
   Orders: { read: ['view-Orders'] },
   SubscriptionPlans: { read: ["view-SubscriptionPlans"] },
   AccountSettings: { read: ["view-AccountSettings"] },
   AdminSettings: { read: ["view-AdminSettings"] },
   Complaints: { read: ["view-Complaints"] },
}


export const menuItems = [

   {
      id: uuid(),
      multiple: false,
      name: "Dashboard",
      menuLink: "/dashboard",
      active: "Dashboard",
      icon: FlaticonDashboard,
   },

   {
      id: uuid(),
      multiple: false,
      name: "Banners",
      menuLink: "/banners",
      active: "Banners",
      icon: FlaticonBanner,
      Permissions: permissions.Users.read,
   },

   {
      id: uuid(),
      multiple: false,
      name: "Basket",
      menuLink: "/basket",
      active: "Basket",
      icon: FlaticonBasket,
   },

   {
      id: uuid(),
      multiple: false,
      name: "Users",
      menuLink: "/account-settings",
      active: "Account Settings",
      icon: FlaticonUser,
      Permissions: permissions.Users.read,
   },

   {
      id: uuid(),
      multiple: false,
      name: "Categories",
      menuLink: "/categories",
      active: "Categories",
      icon: FlaticonCategory,
      Permissions: permissions.Users.read,
   },

   {
      id: uuid(),
      multiple: false,
      name: "Products",
      menuLink: "/products",
      active: "Products",
      icon: FlaticonProduct,
      Permissions: permissions.Users.read,
   },

   {
      id: uuid(),
      multiple: false,
      name: "Orders",
      menuLink: "/orders",
      active: "Orders",
      icon: FlaticonOrder,
      Permissions: permissions.Orders.read,
   },

   {
      id: uuid(),
      multiple: false,
      name: "DeliveryBoy Management",
      menuLink: "/deliveryboymanagement",
      active: "DeliveryBoyManagement",
      icon: FlaticonDeliveryBoy,
      Permissions: permissions.Orders.read,
   },

   {
      id: uuid(),
      multiple: false,
      name: "Communities",
      menuLink: "/communities",
      active: "Communities",
      icon: FlaticonCommunity,
      Permissions: permissions.Orders.read,
   },

   {
      id: uuid(),
      multiple: true,
      name: "Wallets",
      menuLink: "/wallet",
      active: "Wallet",
      icon: FlaticonWallet,
      items: [
         {
            id: '1',
            multiple: false,
            name: "Wallet",
            menuLink: "/wallet",
            active: "Wallet",
            // Permissions: permissions.Wallet.read,
         },
         {
            id: '2',
            multiple: false,
            name: "Wallet Transactions",
            menuLink: "/wallet-transactions",
            active: "WalletTransactions",
            // Permissions: permissions.Wallet.read,
         },
      ]
   },

   // {
   //    id: uuid(),
   //    multiple: false,
   //    name: "Coupons",
   //    menuLink: "/coupons",
   //    active: "Coupons",
   //    icon: RiCoupon2Fill,
   //    // Permissions: permissions.Wallet.read,
   // },

   //  {
   //    id: uuid(),
   //    multiple: false,
   //    name: "Subscription Plans",
   //    menuLink: "/subscriptionplans",
   //    active: "SubscriptionPlans",
   //    icon: BiSolidDonateHeart,
   //    Permissions: permissions.SubscriptionPlans.read,
   // },

   {
      id: uuid(),
      multiple: true,
      name: "Account Settings",
      menuLink: "/accountsettings",
      active: "AccountSettings",
      icon: FlaticonAccountSettings,
      items: [
         {
            id: '1',
            name: "Admin Settings",
            menuLink: "/adminsettings",
            active: "AdminSettings",
            icon: BiSolidDonateHeart,
            Permissions: permissions.AdminSettings.read,
         },
      ]
   },

   {
      id: uuid(),
      multiple: true,
      name: "Support",
      menuLink: "/support",
      active: "Support",
      icon: FlaticonSupport,
      items: [
         {
            id: '1',
            name: "Complaints",
            menuLink: "/complaints",
            active: "Complaints",
            icon: BiSolidDonateHeart,
            Permissions: permissions.Complaints.read,
         },
         {
            id: '2',
            name: "FAQs",
            menuLink: "/faqs",
            active: "FAQs",
            icon: BiSolidDonateHeart,
            Permissions: permissions.Complaints.read,
         },
         {
            id: '3',
            name: "Help Number",
            menuLink: "/helpnumber",
            active: "HelpNumber",
            icon: BiSolidDonateHeart,
            Permissions: permissions.Complaints.read,
         },
      ]
   },

   // {
   //    id: uuid(),
   //    multiple: false,
   //    name: "Configuration",
   //    menuLink: "/configuration",
   //    active: "Configuration",
   //    icon: FcDataConfiguration,
   //    Permissions: permissions.Configuration.read,
   // },
];

