import React, { useState } from 'react';
import Layout from 'Components/Layout';
import UserClasses from '../users/Users.module.css';
import ProductClasses from '../products/Products.module.css'
import OrderClasses from '../orders/Orders.module.css'
import { Helmet } from 'react-helmet';
import { Button, MenuItem, Box, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DeliveryClasses from './AddDeliveryBoy.module.css';
import { BsCloudUpload, BsEye, BsEyeSlash } from 'react-icons/bs';
import ReactLoading from 'react-loading';
import S3 from 'react-s3';
import { Services } from 'Services';
import { Success, Error, warningSwal } from 'Util/Toast';
window.Buffer = window.Buffer || require('buffer').Buffer;

const AddDeliveryBoy = () => {
    const navigate = useNavigate(); // Initialize useNavigate
    const [image, setImage] = useState('');
    const [value, setValue] = useState('');
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const [showPassword, setShowPassword] = useState(false); // State for toggling password visibility
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        mobileNumber: '',
        password: '',
        imageId: '',
        role: '',
        salaryPerMonth: ''
    });
    console.log(formData)

    const [formErrors, setFormErrors] = useState({
        firstName: '',
        lastName: '',
        email: '',
        mobileNumber: '',
        password: '',
        role: '',
        image: '',
        salaryPerMonth: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
    
        // Mobile number validation using regex
        if (name === 'mobileNumber' && !/^\d{0,10}$/.test(value)) {
            // If the entered value does not match the regex pattern for up to 10 digits
            return; // Do not update state
        }
    
        // Convert salaryPerMonth to integer if it's not empty and ensure it's not negative
        if (name === 'salaryPerMonth') {
            const parsedValue = parseInt(value);
            if (parsedValue < 0) {
                return; // Do not update state if the value is negative
            }
            setFormData({ ...formData, [name]: parsedValue });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };
    


    const config = {
        bucketName: process.env.REACT_APP_BUCKETNAME,
        dirName: 'delivery-persons',
        region: 'ap-south-1',
        accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const token = { Authorization: `token ${localStorage.getItem('Token')}` };

        // Basic form validation
        const errors = {};
        let formIsValid = true;

        if (!formData.firstName) {
            errors.firstName = 'Please enter your first name.';
            formIsValid = false;
        }

        if (!formData.lastName) {
            errors.lastName = 'Please enter your last name.';
            formIsValid = false;
        }

        if (!formData.email.match(emailRegex)) {
            errors.email = 'Please enter a valid email address.';
            formIsValid = false;
        }

        if (!formData.mobileNumber.match(/^\d{10}$/)) {
            errors.mobileNumber = 'Please enter a valid 10-digit mobile number.';
            formIsValid = false;
        }

        if (!formData.password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$/)) {
            errors.password = 'Password must contain at least 6 characters with at least one numeric digit, one uppercase, and one lowercase letter.';
            formIsValid = false;
        }

        if (!formData.role) {
            errors.role = 'Please select a role.';
            formIsValid = false;
        }

        if (!formData.salaryPerMonth) {
            errors.salaryPerMonth = 'Please enter SalaryPerMonth.';
            formIsValid = false;
        }

        if (!image) {
            errors.image = 'Please upload an image.';
            formIsValid = false;
        }

        setFormErrors(errors);

        if (!formIsValid) {
            return;
        }

        setLoading(true)

        // Call the addDeliveryPerson API with form data and token
        Services.addDeliveryPerson(formData, token)
            .then((response) => {
                console.log(response)
                // Handle success response

                if (response.Status) {
                    navigate('/deliveryboymanagement')
                    Success(response.Message)
                    console.log('Delivery person added successfully:', response);
                    // Reset form data after successful submission
                    setFormData({
                        firstName: '',
                        lastName: '',
                        email: '',
                        mobileNumber: '',
                        password: '',
                        imageId: '',
                        role: '',
                        salaryPerMonth: '',
                    });
                } else {
                    Error(response.Message)
                }
            })
            .catch((error) => {
                // Handle error

                console.error('Error adding delivery person:', error);
                // You may want to display an error message to the user
            })
            .finally(() => {
                setLoading(false); // Set loading to false after API call
            })
    };

    const filehandleChange = (event) => {
        const files = event.target.files[0];
        if (
            files.type === 'image/png' ||
            files.type === 'image/jpeg' ||
            files.type === 'image/webp'
        ) {
            const timestamp = Date.now();
            const newfile = new File(
                [files],
                `${timestamp}.${String(files.type).split('/')[1]}`
            );

            setLoading(true);

            S3.uploadFile(newfile, {
                ...config,
                dirName: 'delivery-persons',
            })
                .then((res) => {
                    setImage(res.location);
                    setLoading(false);
                    setFormData((prev) => ({ ...prev, imageId: `${timestamp}.${String(files.type).split('/')[1]}` }))
                    console.log(setImage)
                    setValue('imageId', `${timestamp}.${String(files.type).split('/')[1]}`);
                })
                .catch((err) => {
                    alert(err);
                    console.log(err);
                    setLoading(false);
                });
        } else {
            alert(`${files.name} is neither PNG nor jpeg`);
        }
    };


    const fileuploadDesign = (
        <div className={DeliveryClasses["add-item"]} onClick={() => document.getElementById("image").click()}>
            <BsCloudUpload className={DeliveryClasses["upload-icon"]} />
            <h5>Drag Your Files here</h5>
            <p>(Only *.jpeg ,*.png will be accepted)</p>
            <input type="file" id="image" onChange={filehandleChange} style={{ display: 'none' }} />
        </div>
    );

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <Layout Active={'DeliveryBoyManagement'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Add Delivery Boy</title>
            </Helmet>
            <div className={UserClasses['main-first-container']}>
                <div className={UserClasses['main-data-tab']}>

                    <div className={UserClasses['add-users-btn']}>
                        <Button variant="contained" color="primary" onClick={() => navigate(-1)}>
                            BACK
                        </Button>
                    </div>
                    <div className={UserClasses['users-main']}>
                        <h2 className={OrderClasses['header-form']}>
                            Add Delivery Boy
                        </h2>

                        <form className={DeliveryClasses['form-container']} onSubmit={handleSubmit} data-aos="fade-up"
                            data-aos-anchor-placement="top-bottom">
                            <Box mb={2}>
                                <input
                                    className={DeliveryClasses['form-textfields']}
                                    name="firstName"
                                    placeholder="First Name"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    required
                                />
                                {formErrors.firstName && <span className={DeliveryClasses["error-message"]}>{formErrors.firstName}</span>}
                            </Box>
                            <Box mb={2}>
                                <input
                                    className={DeliveryClasses['form-textfields']}
                                    name="lastName"
                                    placeholder="Last Name"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    required
                                />
                                {formErrors.lastName && <span className={DeliveryClasses["error-message"]}>{formErrors.lastName}</span>}
                            </Box>
                            <Box mb={2}>
                                <input
                                    className={DeliveryClasses['form-textfields']}
                                    name="email"
                                    placeholder="Email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                    type="email" // Email validation
                                />
                                {formErrors.email && <span className={DeliveryClasses["error-message"]}>{formErrors.email}</span>}
                            </Box>
                            <Box mb={2}>
                                <input
                                    className={DeliveryClasses['form-textfields']}
                                    name="mobileNumber"
                                    placeholder="Mobile Number"
                                    value={formData.mobileNumber}
                                    onChange={handleChange}
                                    required
                                    type="number" // Mobile number validation
                                />
                                {formErrors.mobileNumber && <span className={DeliveryClasses["error-message"]}>{formErrors.mobileNumber}</span>}
                            </Box>
                            <Box mb={2}>
                                <div style={{ position: 'relative' }}>
                                    <input
                                        className={DeliveryClasses['form-textfields']}
                                        name="password"
                                        placeholder="Password"
                                        type={showPassword ? "text" : "password"} // Toggle password visibility
                                        value={formData.password}
                                        onChange={handleChange}
                                        required
                                        minLength={6} // Password length validation
                                    />
                                    <IconButton
                                        style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}
                                        onClick={togglePasswordVisibility}
                                    >
                                        {!showPassword ? <BsEyeSlash /> : <BsEye />}
                                    </IconButton>
                                </div>
                                {formErrors.password && <span className={DeliveryClasses["error-message"]}>{formErrors.password}</span>}
                            </Box>

                            <Box mb={2}>
                                <select
                                    className={DeliveryClasses['form-textfields']}
                                    name="role"
                                    value={formData.role}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="">Select Role</option>
                                    <option value="1">Delivery Boy</option>
                                </select>
                                {formErrors.role && <span className={DeliveryClasses["error-message"]}>{formErrors.role}</span>}
                            </Box>
                            <Box mb={2}>
                                <input
                                    className={DeliveryClasses['form-textfields']}
                                    name="salaryPerMonth"
                                    placeholder="Salary Per Month"
                                    value={formData.salaryPerMonth}
                                    onChange={handleChange}
                                    required
                                    type="number"
                                />
                                {formErrors.salaryPerMonth && <span className={DeliveryClasses["error-message"]}>{formErrors.salaryPerMonth}</span>}
                            </Box>
                            <div className={DeliveryClasses['file-upload-wrapper']}>
                                <header>
                                    <h3>
                                        Upload Image <span className="important">*</span>
                                    </h3>
                                </header>
                                <div className={DeliveryClasses["upload-image"]}>
                                    {fileuploadDesign}
                                </div>
                                {formErrors.image && <span className={DeliveryClasses["error-message"]}>{formErrors.image}</span>}
                                {image && <img src={image} alt="Uploaded" height={150} width={150} />}
                            </div>
                            <Box mt={2}>
                                <div className={ProductClasses['form-btn-main']}>
                                    {loading && <ReactLoading type="spin" color="#000" height={50} width={50} />} {/* Loading spinner */}
                                    <Button type="submit" disabled={loading}>Submit</Button> {/* Disable button when loading */}
                                </div>
                            </Box>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default AddDeliveryBoy;
