import React, { useEffect, useState } from 'react';
import Layout from 'Components/Layout';
import UserClasses from '../users/Users.module.css';
import { Helmet } from 'react-helmet';
import { ordersTableHeads } from '../constants/Constants';
import { SkeletonTheme } from 'react-loading-skeleton';
import UsersSkeleton from '../users/UsersSkeleton';
import ProductClasses from '../products/Products.module.css';
import Chip from '@mui/material/Chip';
import { MenuItem, Select, TablePagination, FormControl, InputLabel } from '@mui/material';
import OrderClasses from './Orders.module.css';
import { Services } from 'Services';
import { useNavigate } from 'react-router-dom';
import { HiOutlineSearch } from 'react-icons/hi';
import { Success } from 'Util/Toast';
import { FaBoxOpen, FaTruckMoving } from 'react-icons/fa';
import CategoryClasses from '../categories/AddCategory.module.css';
import userPlaceholder from '../../../Assets/basketorders-2.png';
import usertwo from '../../../Assets/basketorder-1.png';

const AllOrders = () => {
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('basketOrders');
  const [basketOrdersData, setBasketOrdersData] = useState([]);
  const [additionalOrdersData, setAdditionalOrdersData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedDeliveryPersons, setSelectedDeliveryPersons] = useState({});
  const [page, setPage] = useState(() => parseInt(localStorage.getItem('currentOrderPage')) || 0);
  const [rowsPerPage, setRowsPerPage] = useState(() => parseInt(localStorage.getItem('orderRowsPerPage')) || 10);
  const [deliveryPersons, setDeliveryPersons] = useState([]);
  const [currentOrders, setCurrentOrders] = useState([]);
  const [filterStatus, setFilterStatus] = useState('All');  // New state for filtering orders by status
  const navigate = useNavigate();

  const token = { Authorization: `token ${localStorage.getItem('Token')}` };

  useEffect(() => {
    fetchBasketOrders();
    fetchDeliveryPersonsList();
  }, []);

  useEffect(() => {
    if (activeTab === 'basketOrders') {
      setFilteredData(filterOrders(basketOrdersData, searchTerm, filterStatus));
    } else {
      fetchAdditionalOrders();
    }
  }, [activeTab, filterStatus]);

  const fetchBasketOrders = () => {
    setLoading(true);
    Services.getOrders(token)
      .then(response => {
        const orders = response.data;
        setBasketOrdersData(orders);
        if (activeTab === 'basketOrders') {
          setFilteredData(filterOrders(orders, searchTerm, filterStatus));
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching orders:', error);
        setLoading(false);
      });
  };

  const fetchAdditionalOrders = () => {
    setLoading(true);
    Services.getAdditionalOrders(token)
      .then(response => {
        const orders = response.AdditionalOrders || [];
        setAdditionalOrdersData(orders);
        if (activeTab !== 'basketOrders') {
          setFilteredData(filterOrders(orders, searchTerm, filterStatus));
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching additional orders:', error);
        setLoading(false);
      });
  };

  const fetchDeliveryPersonsList = () => {
    Services.fetchDeliveryPersonsList(token)
      .then(response => {
        setDeliveryPersons(response.accounts);
      })
      .catch(error => {
        console.error('Error fetching delivery persons list:', error);
      });
  };

  const handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(searchTerm);

    const filtered = filterOrders(
      activeTab === 'basketOrders' ? basketOrdersData : additionalOrdersData,
      searchTerm,
      filterStatus
    );

    setFilteredData(filtered);
    setPage(0);
  };

  const filterOrders = (orders, searchTerm, status) => {
    return orders.filter(order => {
      const matchesSearch = order.fullname.toLowerCase().includes(searchTerm) ||
        getOrderStatusText(order.orderStatus).toLowerCase().includes(searchTerm);
      const matchesStatus = status === 'All' || getOrderStatusText(order.orderStatus).toLowerCase() === status.toLowerCase();

      return matchesSearch && matchesStatus;
    });
  };

  const handleStatusFilterChange = (event) => {
    setFilterStatus(event.target.value);
  };

  const handleClick = (orderId) => {
    if (activeTab === 'basketOrders') {
      navigate(`/order-details/${orderId}`);
    } else {
      navigate(`/additional-orderdetails/${orderId}`);
    }
  };

  const getOrderStatusText = (status) => {
    switch (status) {
      case 0:
        return 'Pending';
      case 1:
        return 'Processing';
      case 2:
        return 'Pending';
      case 3:
        return 'Delivered';
      default:
        return 'Unknown';
    }
  };

  const getOrderStatusColor = (status) => {
    switch (status) {
      case 0:
        return '#7e57c2'; // Purple for Pending
      case 1:
        return '#03a9f4'; // Blue for Processing
      case 2:
        return '#4caf50'; // Green for Shipped
      case 3:
        return '#ff9800'; // Orange for Delivered
      default:
        return '#757575'; // Gray for Unknown or other statuses
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    localStorage.setItem('currentOrderPage', newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    localStorage.setItem('orderRowsPerPage', newRowsPerPage);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const indexOfLastItem = (page + 1) * rowsPerPage;
  const indexOfFirstItem = indexOfLastItem - rowsPerPage;

  useEffect(() => {
    const temp = filteredData.slice(indexOfFirstItem, indexOfLastItem);
    setCurrentOrders(temp);
  }, [filteredData, page, rowsPerPage]);

  const renderTable = () => {
    if (currentOrders.length === 0) {
      return (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          {activeTab === 'basketOrders' ? (
            <div className={UserClasses['users-notfoundicon']} data-aos="fade-right">
              <img height={100} src={userPlaceholder} alt="No Users" className={UserClasses['no-records-icon']} />
              <p>No Basket Orders Found</p>
            </div>
          ) : (
            <div className={UserClasses['users-notfoundicon']} data-aos="fade-right">
              <img height={100} src={usertwo} alt="No Users" className={UserClasses['no-records-icon']} />
              <p>No Additional Orders Found</p>
            </div>
          )}
        </div>
      );
    }

    return (
      <table className={ProductClasses['category-table']}>
        <thead>
          <tr className={UserClasses['main-table-heads']}>
            {activeTab === 'basketOrders'
              ? ordersTableHeads.map((head, index) => (
                <th key={index} className={ProductClasses['products-table-head']}>{head}</th>
              ))
              : ordersTableHeads.filter(item => item !== 'ASSIGN DELIVERY BOY').map((head, index) => (
                <th key={index} className={ProductClasses['products-table-head']}>{head}</th>
              ))}
          </tr>
        </thead>
        <tbody>
          {currentOrders.map((order, index) => (
            <tr id={order.orderId} key={order.orderId} onClick={() => handleClick(order.orderId)} data-aos="fade-up"
              data-aos-anchor-placement="top-bottom">
              <td className={CategoryClasses["table-cell"]}>{order.fullname}</td>
              <td>
                <Chip
                  label={getOrderStatusText(order.orderStatus)}
                  color="primary"
                  variant="outlined"
                  className={OrderClasses['chip-main']}
                  style={{
                    backgroundColor: getOrderStatusColor(order.orderStatus),
                    color: '#fff',
                    border: 'none',
                  }}
                />
              </td>
              <td>{order.createdAt}</td>
              {activeTab === 'basketOrders' ? (
                <td>
                  <Select
                    className={OrderClasses['assign-delivery-boy']}
                    value={selectedDeliveryPersons[order.orderId] || ''}
                    onChange={(event) => {
                      event.stopPropagation();
                      const newSelectedDeliveryPersons = { ...selectedDeliveryPersons };
                      newSelectedDeliveryPersons[order.orderId] = event.target.value;
                      setSelectedDeliveryPersons(newSelectedDeliveryPersons);

                      if (event.target.value !== '') {
                        if (order.orderStatus !== 3) {
                          Services.assignDeliveryPerson(order.orderId, event.target.value, token)
                            .then(response => {
                              console.log(response);
                              Success(response.Message);
                            })
                            .catch(error => {
                              console.error('Error assigning delivery person:', error);
                            });
                        } else {
                          console.log(`Order status (${order.orderStatus}) does not allow assigning a delivery person.`);
                        }
                      }
                    }}
                    displayEmpty
                    renderValue={(value) => {
                      const selectedPerson = deliveryPersons.find(person => person.deliveryPersonId === value);

                      if (selectedPerson) {
                        return `${selectedPerson.firstName} ${selectedPerson.lastName}`;
                      } else if (order.assignedToDeliveryPerson) {
                        const selectedPerson = deliveryPersons.find(person => person.deliveryPersonId === order.assignedToDeliveryPerson);
                        return selectedPerson ? `${selectedPerson.firstName} ${selectedPerson.lastName}` : 'Select a delivery boy';
                      } else {
                        return 'Select a delivery boy';
                      }
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    disabled={order.orderStatus === 3}
                  >
                    <MenuItem className={OrderClasses['select-drop-text']} disabled value="">Select a delivery boy</MenuItem>
                    {deliveryPersons.map((person) => (
                      <MenuItem key={person.deliveryPersonId} value={person.deliveryPersonId}>
                        {`${person.firstName} ${person.lastName}`}
                      </MenuItem>
                    ))}
                  </Select>
                </td>
              ) : (
                <td></td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <Layout Active={'Orders'}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Orders</title>
      </Helmet>
      <div className={UserClasses['main-first-container']}>
        <div className={UserClasses['main-data-tab']}>
          <div className={UserClasses['form-main-search']}>
            <form className={UserClasses['form-search']} action=''>
              <input
                style={{ width: '270px' }}
                data-aos="zoom-in"
                className={UserClasses['form-input-search']}
                type='search'
                value={searchTerm}
                onChange={handleSearch}
                placeholder='Search by name/order status'
              />
            </form>

          </div>
          <div className={UserClasses['users-main']}>
            <h2 className={OrderClasses['header-form']}>
              Orders
            </h2>
            <div className={OrderClasses['filter-tab-main']}>
              <div className={OrderClasses['main-tabs-switch']}>
                <button
                  className={activeTab === 'basketOrders' ? OrderClasses['active-tab'] : OrderClasses['inactive-tab']}
                  onClick={() => handleTabChange('basketOrders')}
                >
                  Basket Orders
                </button>
                <button
                  className={activeTab === 'additionalOrders' ? OrderClasses['active-tab'] : OrderClasses['inactive-tab']}
                  onClick={() => handleTabChange('additionalOrders')}
                >
                  Additional Orders
                </button>
              </div>
              <div>
          
                <div className={UserClasses['filter-dropdown']}>
                  <select style={{ height: '36px', borderRadius: '4px', backgroundColor: 'black', color: 'white' }}
                    value={filterStatus}
                    onChange={handleStatusFilterChange}>
                    <option value="All">All</option>
                    <option value="Pending">Pending</option>
                    <option value="Delivered">Delivered</option>
                  </select>
                </div>
              </div>
            </div>
            <div>
              <SkeletonTheme color='#ddd' highlightColor='#eee'>
                {loading ? (
                  <UsersSkeleton TableHeader={ordersTableHeads} />
                ) : (
                  <div style={{ overflowY: 'hidden' }}>
                    {renderTable()}
                  </div>
                )}
                <div className={UserClasses['table-pagination']}>
                  <div className={UserClasses['current-page']}>
                    <p>Current Page: {page + 1}</p>
                  </div>
                  <TablePagination
                    component="div"
                    count={filteredData.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              </SkeletonTheme>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AllOrders;
