import React, { useState, useEffect } from 'react';
import Layout from 'Components/Layout';
import { Helmet } from 'react-helmet';
import UserClasses from '../users/Users.module.css';
import BasketClasses from './basket.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material'; // Import Button from Material-UI
import ProductClasses from '../../Admin/products/Products.module.css';
import Select from 'react-select';
import { AiFillDelete } from "react-icons/ai";
import { Services } from 'Services';
import { Success, Error } from 'Util/Toast';

const AddBasket = () => {
    const { userId } = useParams();
    const [basketName, setBasketName] = useState('');
    const [products, setProducts] = useState([{ productName: '', productId: '', qty: '', variantId: '', variants: [] }]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [basketPrice, setBasketPrice] = useState(0);
    const [basketOfferPrice, setBasketOfferPrice] = useState(0);
    const [selectedAssignee, setSelectedAssignee] = useState([]);
    const [productOptions, setProductOptions] = useState([]);
    const [variantOptions, setVariantOptions] = useState([]);
    const [formErrors, setFormErrors] = useState({});
    const [users, setUsers] = useState([]);
    const [userOptions, setUserOptions] = useState([]); // State to hold user options

    const navigate = useNavigate();

    // FIXME: Fetch product data using the getProducts API
    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const token = { Authorization: `token ${localStorage.getItem('Token')}` };
                const response = await Services.getBasketProducts(token);
                if (response.Status === 1) {
                    const options = response.products.map((product) => ({
                        value: product.productId,
                        label: product.productName,
                        variants: product.variants, // Store variants in options
                    }));
                    setProductOptions(options);
                }
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchProducts();
    }, []);

    // FIXME: Function to handle product selection
    const handleProductChange = (index, value) => {
        const updatedProducts = [...products];
        const selectedProduct = productOptions.find(option => option.value === value);
        if (selectedProduct) {
            updatedProducts[index].productName = selectedProduct.label;
            updatedProducts[index].productId = selectedProduct.value;
            updatedProducts[index].variants = selectedProduct.variants; // Update variants for the selected product
        }
        setProducts(updatedProducts);
    };

    // FIXME: Function to add a new product row
    const addProductRow = () => {
        setProducts([...products, { productName: '', productId: '', qty: '', variants: [] }]);
    };

    // FIXME: Function to remove a product row
    const cancelProductRow = (index) => {
        const updatedProducts = [...products];
        updatedProducts.splice(index, 1);
        setProducts(updatedProducts);

        // TODO: Recalculate the basket price after removing a product row
        calculateBasketPrice();
    };

    // FIXME: Function to handle variant selection
    const handleVariantChange = (index, value) => {
        const updatedProducts = [...products];
        const selectedVariant = updatedProducts[index].variants.find(variant => variant.variantId === value);
        if (selectedVariant) {
            updatedProducts[index].variantId = value;
            updatedProducts[index].productPrice = selectedVariant.price; // Update product price with selected variant price
        }
        setProducts(updatedProducts);

        // TODO: Recalculate the basket price after changing the variant
        calculateBasketPrice();
    };

    // FIXME: Function to handle quantity changes
    const handleCounterChange = (index, increment) => {
        const updatedProducts = [...products];
        const currentQty = parseInt(updatedProducts[index].qty) || 0;
        updatedProducts[index].qty = currentQty + increment;
        setProducts(updatedProducts);

        // TODO: Recalculate the basket price after changing the quantity
        calculateBasketPrice();
    };

    // FIXME: Function to calculate basket price based on products array
    const calculateBasketPrice = () => {
        const total = products.reduce((sum, product) => {
            const productPrice = parseFloat(product.productPrice) || 0;
            const qty = parseFloat(product.qty) || 0;
            // TODO: Calculate the total price for each product
            const productTotalPrice = productPrice * qty;
            // TODO: Add the product's total price to the sum
            return sum + productTotalPrice;
        }, 0);
        // TODO: Update the basket price state with the calculated total
        setBasketPrice(total);
    };


    // FIXME: Function to validate the form
    const validateForm = () => {
        const errors = {};

        // TODO: Validate each product
        products.forEach((product, index) => {
            if (!product.productName || product.productId === '') {
                errors[`product_${index}`] = 'Please select a product.';
            }
            if (!product.qty) {
                errors[`qty_${index}`] = 'Please enter a quantity.';
            }
            if (!product.variantId) {
                errors[`variantId_${index}`] = 'Please select a variant.';
            }
        });

        // TODO: Validate basket prices
        if (basketPrice === '' || basketPrice <= 0) {
            errors.basketPrice = 'Please enter a valid basket price.';
        }

        // TODO: Check if there are any errors
        setFormErrors(errors);

        // TODO: Return whether the form is valid (no errors)
        return Object.keys(errors).length === 0;
    };

    // FIXME: Function to handle form submission
    const onSubmit = async (event) => {
        event.preventDefault();

        const formIsValid = validateForm();

        if (!formIsValid) {
            return;
        }

        const userIds = selectedAssignee.map((assignee) => assignee.value);

        const basketData = {
            userIds: userIds,
            basketName: basketName,
            basketPrice: parseFloat(basketPrice),
            basketOfferPrice: parseFloat(basketOfferPrice),
            products: products.map((product) => ({
                productId: product.productId,
                variantId: product.variantId,
                qty: parseInt(product.qty),
            })),
        };

        try {
            const token = { Authorization: `token ${localStorage.getItem('Token')}` };
            const response = await Services.addBasket(basketData, token);
            console.log('Add basket response:', response);
            if (response.Status === 1) {
                navigate('/basket');
                Success(response.Message);
            } else {
                Error(response.Message)
            }
        } catch (error) {
            console.error('Error adding basket:', error);
        }
    };



    // FIXME: Fetch users using the getUsers API
    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const token = { Authorization: `token ${localStorage.getItem('Token')}` };
                const response = await Services.getUsers(0, token);
                if (response.Status === 1) {
                    setUsers(response.users); // Update users state
                    // Create options array for Select component
                    const options = response.users.map((user) => ({
                        value: user.userId,
                        label: user.fullName,
                    }));
                    setUserOptions(options); // Update user options state
                }
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        fetchUsers();
    }, []);

    const handleUserChange = (selectedOptions) => {
        setSelectedAssignee(selectedOptions);
    };

    return (
        <Layout Active={'Basket'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Add Basket</title>
            </Helmet>
            <div className={UserClasses['main-first-container']}>
                <div className={UserClasses['main-data-tab']}>
                    <div className={BasketClasses['add-basket-form-container']}>
                        <div className={ProductClasses['main-add-product-btn']}>
                            <Button variant="contained" color="primary" onClick={() => navigate(-1)}>
                                BACK
                            </Button>
                        </div>
                        <form className={BasketClasses['add-basket-form']} onSubmit={onSubmit} data-aos="fade-up"
                            data-aos-anchor-placement="top-bottom">
                            <div className={ProductClasses['basket-add-product-btn']}>
                                <Button variant="contained" color="primary" type="button" onClick={addProductRow}>
                                    ADD PRODUCT
                                </Button>
                            </div>
                            <div className={BasketClasses['basket-name-field']}>
                                {/* Input for Basket Name */}
                                <input
                                    type="text"
                                    placeholder="Basket Name"
                                    value={basketName}
                                    onChange={(e) => setBasketName(e.target.value)}
                                    className={BasketClasses['basket-name-input']}
                                />
                            </div>

                            {products.map((product, index) => (
                                <div key={index} className={BasketClasses['main-inp-basket']}>
                                    <Select
                                        value={productOptions.find(option => option.value === product.productId)}
                                        options={productOptions}
                                        onChange={(selectedOption) => handleProductChange(index, selectedOption.value)}
                                        placeholder="Select product"
                                        className={BasketClasses['product-select-list']}
                                    />
                                    {/* Display error message for product selection */}
                                    {formErrors[`product_${index}`] && (
                                        <div className={BasketClasses['error-message']}>
                                            {formErrors[`product_${index}`]}
                                        </div>
                                    )}
                                    <div>
                                        {formErrors[`variantId_${index}`] && (
                                            <div style={{ marginTop: '60px' }} className={BasketClasses['error-message']}>
                                                {formErrors[`variantId_${index}`]}
                                            </div>
                                        )}
                                        <Select
                                            value={variantOptions?.find(option => option.value === product.variantId)}
                                            options={product?.variants?.map(variant => ({
                                                value: variant.variantId,
                                                label: `${variant.metricValue} ${variant.metricType} - ₹${variant.price}`, // Concatenate ₹ symbol with the price
                                            }))}
                                            onChange={(selectedOption) => handleVariantChange(index, selectedOption.value)}
                                            placeholder="Select variant"
                                            className={BasketClasses['product-select-list']}
                                        />
                                    </div>
                                    <div className={BasketClasses['basket-inp-qty']}>
                                        <input
                                            type="number"
                                            placeholder="Quantity"
                                            value={product.qty}
                                            onChange={(e) => handleVariantChange(index, 'qty', e.target.value)}
                                            className={BasketClasses['pro-name-inp']}
                                        />
                                        {/* Display error message for quantity input */}
                                        {formErrors[`qty_${index}`] && (
                                            <div className={BasketClasses['error-message']}>
                                                {formErrors[`qty_${index}`]}
                                            </div>
                                        )}
                                    </div>
                                    <div className={BasketClasses['counter-container']}>
                                        <Button
                                            variant="contained"
                                            style={{ backgroundColor: '#C2CDDF', color: '#fff', width: '40px' }}
                                            type="button"
                                            onClick={() => handleCounterChange(index, -1)}
                                            className={BasketClasses['add-pro-btn-cancel']}
                                        >
                                            -
                                        </Button>
                                        <span className={BasketClasses['counter-value']}>{product.qty}</span>
                                        <Button
                                            variant="contained"
                                            style={{ backgroundColor: '#C2CDDF', color: '#fff', width: '40px' }}
                                            type="button"
                                            onClick={() => handleCounterChange(index, 1)}
                                            className={BasketClasses['add-pro-btn-cancel']}
                                        >
                                            +
                                        </Button>
                                    </div>
                                    <Button
                                        className={BasketClasses['del-pro-btn-cancel']}
                                        type="button"
                                        onClick={() => cancelProductRow(index)}
                                    >
                                        <AiFillDelete />
                                    </Button>
                                </div>
                            ))}
                            <div className={BasketClasses['add-basket-form-total']}>
                                <div className={BasketClasses['basket-price-container']}>
                                    <label>Basket Price:</label>
                                    <input
                                        type="number"
                                        placeholder="Basket Price"
                                        value={basketPrice}
                                    // onChange={(e) => setBasketPrice(parseFloat(e.target.value))}
                                    />
                                    {/* Display error message for basket price input */}
                                    {formErrors.basketPrice && (
                                        <div className={BasketClasses['error-message']}>
                                            {formErrors.basketPrice}
                                        </div>
                                    )}
                                </div>

                            </div>
                            <div className={BasketClasses['add-basket-sub-main']}>
                                <Button
                                    variant="contained"
                                    style={{ backgroundColor: '#04AA6D', color: '#fff', width: '100px' }}
                                    type="submit"
                                >
                                    Submit
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default AddBasket;

