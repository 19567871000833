import React, { useState, useEffect } from 'react';
import Layout from 'Components/Layout';
import { Helmet } from 'react-helmet';
import { useParams, useNavigate } from 'react-router-dom';
import UserClasses from '../../users/Users.module.css';
import OrderClasses from '../../orders/Orders.module.css';
import { Services } from 'Services';
import UsersSkeleton from '../../users/UsersSkeleton';
import FaqClasses from '../faqs/Faq.module.css';
import { Success, Error_Dark } from 'Util/Toast';
import { Button } from '@mui/material';

const EditFaq = () => {
    const { customerSupportId } = useParams();
    const navigate = useNavigate();
    const [FAQ, setFaq] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchFaqData = async () => {
            try {
                const token = { Authorization: `token ${localStorage.getItem('Token')}` };
                const response = await Services.getFAQById(customerSupportId, token);

                if (response.Status === 1) {
                    setFaq(response.FAQ);
                } else {
                    Error_Dark('Failed to fetch FAQ data');
                }
            } catch (error) {
                console.error('Error fetching FAQ data:', error);
                Error_Dark('An error occurred while fetching FAQ data');
            } finally {
                setLoading(false);
            }
        };

        fetchFaqData();
    }, [customerSupportId]);

    if (loading) {
        return <UsersSkeleton TableHeader={[]} />; 
    }

    if (!FAQ) {
        return <div>Failed to load FAQ data.</div>;
    }

    const handleFormSubmit = async (event) => {
        event.preventDefault();
    
        const token = { Authorization: `token ${localStorage.getItem('Token')}` };
    
        try {
            const response = await Services.updateFAQ(customerSupportId, FAQ, token);
    
            if (response.Status === 1) {
                Success('FAQ updated successfully!');
                navigate('/faqs');
            } else {
                Error_Dark('Failed to update FAQ');
            }
        } catch (error) {
            console.error('Error updating FAQ:', error);
            Error_Dark('An error occurred while updating FAQ');
        }
    };
    

    return (
        <Layout Active={'FAQs'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Edit FAQs</title>
            </Helmet>
            <div className={UserClasses['main-first-container']}>
                <div className={UserClasses['main-data-tab']}>
                    <div className={UserClasses['add-users-btn']}>
                        <Button variant="contained" color="primary" onClick={() => navigate(-1)}>
                            BACK
                        </Button>
                    </div>
                    <div className={UserClasses['users-main']} data-aos="flip-up">
                        <div className={OrderClasses['order-details-container']}>
                            <h2 className={OrderClasses['header-form']}>
                                Edit FAQ
                            </h2>
                            <form onSubmit={handleFormSubmit}>
                                <div className={FaqClasses['edit-formGroup']}>
                                    <label htmlFor="question">Question:</label>
                                    <input
                                        type="text"
                                        id="question"
                                        value={FAQ.question}
                                        onChange={(e) => setFaq({ ...FAQ, question: e.target.value })}
                                        className={FaqClasses['edit-formControl']}
                                    />
                                </div>
                                <div className={FaqClasses['edit-formGroup']}>
                                    <label htmlFor="answer">Answer:</label>
                                    <textarea
                                        id="answer"
                                        value={FAQ.answer}
                                        onChange={(e) => setFaq({ ...FAQ, answer: e.target.value })}
                                        className={FaqClasses['edit-formControl-ans']}
                                    />
                                </div>
                                <div className={FaqClasses['edit-buttonContainer']}>
                                    <button type="submit" className={`${FaqClasses['edit-button']} ${FaqClasses['edit-buttonPrimary']}`}>
                                        Save
                                    </button>
                                    <button
                                        type="button"
                                        className={`${FaqClasses['edit-button']} ${FaqClasses['edit-buttonSecondary']}`}
                                        onClick={() => navigate('/faqs')}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default EditFaq;
