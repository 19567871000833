import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import ReactLoading from 'react-loading';
import { Services } from 'Services';
import { Success, Error } from 'Util/Toast';
import Layout from 'Components/Layout';
import { Helmet } from 'react-helmet';
import { BsCloudUpload } from 'react-icons/bs';
import CommonTutorClasses from '../commoncssfiles/CategoryTutor.module.css';
import UserClasses from '../users/Users.module.css';
import S3 from 'react-s3';

const EditBanner = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const [base64, setBase64] = useState('');
    const [imagePreview, setImagePreview] = useState('');
    const [loading, setLoading] = useState(false);

    const config = {
        bucketName: process.env.REACT_APP_BUCKETNAME,
        dirName: 'banners',
        region: 'ap-south-1',
        accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
    };

    // Retrieve the category object from location state
    const { category } = location.state || {};

    // Use useEffect to prefill form fields and image preview when category changes
    useEffect(() => {
        if (category) {
            setValue('bannerName', category.bannerName);
            setValue('priority', category.priority); // Prefill priority
            if (category.imageUrl) {
                setImagePreview(category.imagePreview); // Set initial image preview
                const endpoint = category.imageUrl.split('/').pop(); // Extract endpoint from URL
                setValue('imageUrl', endpoint); // Set only the endpoint in the form state
            }
        }
    }, [category, setValue]);

    const filehandleChange = async (event) => {
        const files = event.target.files[0];
        if (
            files.type === 'image/png' ||
            files.type === 'image/jpeg' ||
            files.type === 'image/webp'
        ) {
            try {
                const timestamp = Date.now();
                const newfile = new File(
                    [files],
                    `${timestamp}.${String(files.type).split('/')[1]}`
                );

                setLoading(true);
                const res = await S3.uploadFile(newfile, config);
                setBase64(res.location);
                setImagePreview(URL.createObjectURL(files)); // Set image preview
                setValue('imageUrl', `${timestamp}.${String(files.type).split('/')[1]}`); // Set only the file name
                setLoading(false);
            } catch (error) {
                console.error('Error uploading image:', error);
                setLoading(false);
                Error('Error uploading image');
            }
        } else {
            alert(`${files.name} is neither PNG nor jpeg`);
        }
    };

    const onSubmit = async (data) => {
        if (!data.imageUrl) {
            // Show error message if image is not uploaded
            Error('Please upload an image.');
            return;
        }

        const requestBody = {
            bannerName: data.bannerName,
            imageUrl: data.imageUrl, // Use the endpoint file name
            priority: parseInt(data.priority), // Ensure priority is an integer
        };

        const token = { Authorization: `token ${localStorage.getItem('Token')}` };

        try {
            const response = await Services.updateBanner(category.bannerId, requestBody, token);
            if (response.Status === 1) {
                navigate('/banners');
                Success(response.Message);
                console.log('Banner updated successfully:', response.Message);
            } else {
                Error(response.Message);
                console.error('Error updating banner:', response.Message);
            }
        } catch (error) {
            console.error('Error updating banner:', error);
            Error('An error occurred while updating the banner.');
        }
    };

    const fileuploadDesign = (
        <div className={CommonTutorClasses["add-item"]} onClick={() => document.getElementById("image").click()}>
            <div className={CommonTutorClasses['upload-image']}>
                {imagePreview && <img src={imagePreview} alt="Selected" className={CommonTutorClasses['uploaded-coupon-image']} />}
                {!imagePreview && (
                    <div className={CommonTutorClasses['add-item']} onClick={() => document.getElementById("image").click()}>
                        <BsCloudUpload className={CommonTutorClasses['upload-icon']} />
                        <h5>Drag Your Files here</h5>
                        <p>(Only *.jpeg, *.png will be accepted)</p>
                    </div>
                )}
                <input type="file" id="image" onChange={filehandleChange} style={{ display: 'none' }} />
            </div>
        </div>
    );

    return (
        <Layout Active={'Banners'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Update Banner</title>
            </Helmet>
            <div className={UserClasses['main-first-container']}>
                <div className={UserClasses['main-data-tab']}>
                    <div className={UserClasses['add-users-btn']}>
                        <Button variant="contained" color="primary" onClick={() => navigate(-1)}>
                            BACK
                        </Button>
                    </div>
                    <div className={CommonTutorClasses['wrapper']} data-aos="zoom-in-down">
                        <h2 className={CommonTutorClasses['main-header']}>Update Banner</h2>
                        <form className={CommonTutorClasses["form"]} onSubmit={handleSubmit(onSubmit)}>
                            <div className={CommonTutorClasses['form-control']}>
                                <label htmlFor="bannerName">Banner Name</label>
                                <input type='text' {...register('bannerName', { required: true })} />
                            </div>
                            <div className={CommonTutorClasses['form-control']}>
                                <label htmlFor="priority">Priority</label>
                                <input type="number" {...register('priority', { required: true, valueAsNumber: true })} />
                                {errors.priority && <p className="error-message">Priority must be an integer.</p>}
                            </div>
                            <div className={CommonTutorClasses['file-upload-wrapper']}>
                                <header>
                                    <h3>
                                        Upload Banner Image <span className="important">*</span>
                                    </h3>
                                </header>
                                <div className={CommonTutorClasses["upload-image"]} required>
                                    {fileuploadDesign}
                                </div>
                                {errors.imageUrl && <p className="error-message">Please upload a banner image.</p>}
                            </div>
                            <button disabled={loading}>
                                {loading ? (
                                    <ReactLoading color="green" type="spokes" height={30} width={30} />
                                ) : (
                                    'Submit'
                                )}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default EditBanner;
