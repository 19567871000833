import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import Layout from 'Components/Layout';
import UserClasses from '../users/Users.module.css';
import { useNavigate } from 'react-router-dom';
import S3 from 'react-s3';
import { Services } from 'Services';
import { Success, Error } from 'Util/Toast';
import { Button } from '@mui/material';
import { BsCloudUpload } from 'react-icons/bs';
import ReactLoading from 'react-loading';
import CommonCategoryClasses from '../commoncssfiles/CategoryCommon.module.css';
import CommonTutorClasses from '../commoncssfiles/CategoryTutor.module.css';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import ProductWrapperClasses from './Products.module.css';
import ProductClasses from '../products/Products.module.css';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { RxCross2 } from 'react-icons/rx';
import AddProductVariant from './AddProductVariant';

window.Buffer = window.Buffer || require('buffer').Buffer;

const AddProduct = () => {
    const { register, control, handleSubmit, setValue, setError, formState: { errors } } = useForm(

        {
            defaultValues: {
                variant: [{ metricValue: "" }]
            }
        }
    );
    const navigate = useNavigate();

    const [base64, setBase64] = useState('');
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [imagePreview, setImagePreview] = useState('');
    const [locationId, setLocationId] = useState(''); // New state for locationId
    const [nutritionalValues, setNutritionalValues] = useState([
        { key: 'Calorie', value: 0 },
        { key: 'Added sugars', value: 0 },
        { key: 'Biotin', value: 0 },
        { key: 'Calcium', value: 0 },
        { key: 'Chloride', value: 0 },
        { key: 'Choline', value: 0 },
        { key: 'Cholesterol', value: 0 },
        { key: 'Chromium', value: 0 },
        { key: 'Copper', value: 0 },
        { key: 'Dietary Fiber', value: 0 },
        { key: 'Fat', value: 0 },
        { key: 'Folate/Folic Acid', value: 0 },
        { key: 'Iodine', value: 0 },
        { key: 'Iron', value: 0 },
        { key: 'Magnesium', value: 0 },
        { key: 'Manganese', value: 0 },
        { key: 'Molybdenum', value: 0 },
        { key: 'Niacin', value: 0 },
        { key: 'Pantothenic Acid', value: 0 },
        { key: 'Phosphorus', value: 0 },
        { key: 'Potassium', value: 0 },
        { key: 'Protein', value: 0 },
        { key: 'Riboflavin', value: 0 },
        { key: 'Saturated fat', value: 0 },
        { key: 'Selenium', value: 0 },
        { key: 'Sodium', value: 0 },
        { key: 'Thiamin', value: 0 },
        { key: 'Total carbohydrate', value: 0 },
        { key: 'Vitamin A', value: 0 },
        { key: 'Vitamin B6', value: 0 },
        { key: 'Vitamin B12', value: 0 },
        { key: 'Vitamin C', value: 0 },
        { key: 'Vitamin D', value: 0 },
        { key: 'Vitamin E', value: 0 },
        { key: 'Vitamin K', value: 0 },
        { key: 'Zinc', value: 0 },
    ]);
    const [variants, setVariants] = useState([]);
    const [varianterror, setVariantError] = useState(false)

    const [err, setErr] = useState(false)
    const handleAddNutritionalValue = () => {
        const newNutritionalValue = { key: '', value: '' };
        setNutritionalValues([...nutritionalValues, newNutritionalValue]);
    };

    const handleRemoveNutritionalValue = (index) => {
        const updatedNutritionalValues = [...nutritionalValues];
        updatedNutritionalValues.splice(index, 1);
        setNutritionalValues(updatedNutritionalValues);
    };

    const handleVariantsChange = (newVariants) => {
        setVariants(newVariants);
    };

    console.log("errors", errors)

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const token = { Authorization: `token ${localStorage.getItem('Token')}` };
                const response = await Services.getCategories(token);

                if (response.Status === 1) {
                    setCategories(response.categories);
                    setLoading(false);
                } else {
                    console.error('Error fetching categories:', response.Message);
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error fetching categories:', error);
                setLoading(false);
            }
        };

        fetchCategories();
    }, []);

    const config = {
        bucketName: process.env.REACT_APP_BUCKETNAME,
        dirName: 'product-images',
        region: 'ap-south-1',
        accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
    };

    const filehandleChange = async (event) => {
        const files = event.target.files[0];
        if (
            files.type === 'image/png' ||
            files.type === 'image/jpeg' ||
            files.type === 'image/webp'
        ) {
            try {
                const timestamp = Date.now();
                const newfile = new File(
                    [files],
                    `${timestamp}.${String(files.type).split('/')[1]}`
                );

                setLoading(true);
                const res = await S3.uploadFile(newfile, config);
                setBase64(res.location);
                setImagePreview(URL.createObjectURL(files)); // Set image preview
                setValue('imageUrl', `${timestamp}.${String(files.type).split('/')[1]}`); // Set only the file name
                setLoading(false);
            } catch (error) {
                console.error('Error uploading image:', error);
                setLoading(false);
                Error('Error uploading image');
            }
        } else {
            alert(`${files.name} is neither PNG nor jpeg`);
        }
    };

    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setBase64(reader.result);
            setImagePreview(URL.createObjectURL(file));
        };
        reader.readAsDataURL(file);
    };

    const handleFormSubmit = async (data) => {

        console.log("", data)
        if (!data.imageUrl) {
            setError('imageUrl', { type: 'custom', message: 'Image is required' });
            Error('Please upload an image.');
            setErr(true)
            return;
        } else {
            setErr(false)
        }

        variants.map(async (e) => {
            if (!e.metricValue || !e.metricType || !e.price) {
                setVariantError('please add one variant')
                setErr(true)
                return;
            } else {
                setErr(false)
            }
        })
        if (!err) {
            setLoading(true);
            const requestBody = {
                categoryId: data.categoryId,
                imageUrl: data.imageUrl,
                productName: data.productName,
                description: data.description,
                calorie: data.calorie,
                price: data.price,
                defaultQuantity: data.defaultQuantity,
                basketShopToggle: data.basketShopToggle || false,
                nutritionalValues: data.nutritionalValues || [],
                variants: variants,
                locationId: data.locationId // Include the locationId in the request body

            };

            const token = { Authorization: `token ${localStorage.getItem('Token')}` };

            try {
                const response = await Services.addProduct(requestBody, token);
                if (response.Status === 1) {
                    console.log('Product added successfully:', response.Message);
                    navigate('/products');
                    Success(response.Message);
                } else {
                    console.error('Error adding product:', response.Message);
                }
            } catch (error) {
                console.error('Error adding product:', error);
            } finally {
                setLoading(false); // Set loading to false after API call
            }
        }
    }

    const fileuploadDesign = (
        <div className={CommonTutorClasses["add-item"]} onClick={() => document.getElementById("image").click()}>
            <div className={CommonTutorClasses['upload-image']}>
                {imagePreview && <img src={imagePreview} alt="Selected" className={CommonTutorClasses['uploaded-coupon-image']} />}
                {!imagePreview && (
                    <div className={CommonTutorClasses['add-item']}>
                        <BsCloudUpload className={CommonTutorClasses['upload-icon']} />
                        <h5>Drag Your Files here</h5>
                        <p>(Only *.jpeg, *.png will be accepted)</p>
                    </div>
                )}
                <input type="file" id="image" onChange={filehandleChange} style={{ display: 'none' }} />
            </div>
        </div>
    );

    return (
        <Layout Active={'Products'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Add Product</title>
            </Helmet>
            <div className={UserClasses['main-first-container']}>
                <div className={UserClasses['main-data-tab']}>
                    <div className={UserClasses['add-users-btn']}>
                        <Button variant="contained" color="primary" onClick={() => navigate(-1)}>
                            BACK
                        </Button>
                    </div>
                    <div className={ProductWrapperClasses['product-wrapper']} data-aos="zoom-in-down">
                        <h2 className={CommonTutorClasses['main-header']}>Add Product</h2>
                        <form className={ProductWrapperClasses["form"]} onSubmit={handleSubmit(handleFormSubmit)}>
                            <div style={{ width: '400px' }} className={CommonTutorClasses['form-control']}>
                                <label htmlFor="locationId">Location</label>
                                <select
                                    className={CommonTutorClasses['select-input']}
                                    {...register('locationId', { required: true })}
                                    onChange={(e) => setLocationId(e.target.value)}
                                >
                                    <option value="">Select a location</option>
                                    <option value="1">Store</option>
                                    <option value="2">Inventory</option>
                                </select>
                                {errors.locationId && <p className={ProductClasses['error-text']}>Location is required</p>}
                            </div>
                            <div className={ProductClasses['first-two-divs']}>
                                <div style={{ width: '400px' }} className={CommonTutorClasses['form-control']}>
                                    <label htmlFor="categoryId">Category</label>
                                    <select className={CommonTutorClasses['select-input']} {...register('categoryId', { required: true })}>
                                        <option value="">Select a category</option>
                                        {categories.map(category => (
                                            <option key={category.categoryId} value={category.categoryId}>
                                                {category.categoryName}
                                            </option>
                                        ))}
                                    </select>
                                    {errors.categoryId && <p className={ProductClasses['error-text']}>Category is required</p>}
                                </div>
                                <div style={{ width: '400px' }} className={CommonTutorClasses['form-control']}>
                                    <label htmlFor="productName">Product Name</label>
                                    <input type="text" className={CommonTutorClasses['text-input']} {...register('productName', { required: true })} />
                                    {errors.productName && <p className={ProductClasses['error-text']}>Product name is required</p>}
                                </div>
                            </div>
                            <div className={CommonTutorClasses['form-control']}>
                                <label htmlFor="description">Product Description</label>
                                <textarea style={{ textIndent: '10px' }} rows={10} className={ProductClasses['text-area']} {...register('description', { required: true })}></textarea>
                                {errors.description && <p className={ProductClasses['error-text']}>Product description is required</p>}
                            </div>
                            <div style={{ marginTop: '10px' }} className={CommonTutorClasses['file-upload-wrapper']}>
                                <header>
                                    <h3>
                                        Upload Product Image <span className="important">*</span>
                                    </h3>
                                </header>
                                <div className={CommonTutorClasses["upload-image"]} required>
                                    {fileuploadDesign}
                                </div>
                            </div>
                            <div className={CommonTutorClasses['form-control']}>
                                <div style={{ marginTop: '10px' }} className={CommonTutorClasses['file-upload-wrapper']}>
                                    <header className={ProductClasses['nutri-cont-main']}>
                                        <h3>
                                            Nutritional Value per 100 Gram <span className="important">*</span>
                                        </h3>
                                        <button className={ProductClasses['key-val-add']} type="button" onClick={handleAddNutritionalValue}>
                                            <IoMdAddCircleOutline />
                                        </button>
                                    </header>
                                    <div className={ProductClasses['key-val']} style={{ overflowX: 'hidden' }}>
                                        <div style={{ marginBottom: '20px' }} className={ProductClasses['inp-key-val']}>
                                            {nutritionalValues.map((nutritionalValue, index) => (
                                                <div style={{ marginTop: '20px' }} className={ProductClasses['main-key-val']} key={index}>
                                                    <input
                                                        type="text"
                                                        defaultValue={nutritionalValue.key}
                                                        {...register(`nutritionalValues[${index}].key`)}
                                                        className={ProductClasses['text-input']}
                                                    />
                                                    <input
                                                        type="number"
                                                        step="any"
                                                        defaultValue={parseFloat(nutritionalValue.value)}
                                                        {...register(`nutritionalValues[${index}].value`, { valueAsNumber: true })}
                                                        className={ProductClasses['text-num-input']}
                                                    />
                                                    {index >= 0 && (
                                                        <button className={ProductClasses['key-remove']} type="button" onClick={() => handleRemoveNutritionalValue(index)}>
                                                            <RxCross2 />
                                                        </button>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <AddProductVariant errors={errors} setVariantError={setVariantError} varianterror={varianterror} register={register} control={control} setValue={setValue} onVariantsChange={handleVariantsChange} />
                            <div className={ProductClasses['form-btn-main']}>
                                {loading && <ReactLoading type="spin" color="#000" height={50} width={50} />} {/* Loading spinner */}
                                <Button type="submit" disabled={loading}>Add Product</Button> {/* Disable button when loading */}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default AddProduct;
