import React, { useEffect, useState } from 'react';
import UserClasses from '../Admin/users/Users.module.css';
import ProductClasses from '../Admin/products/Products.module.css';
import Layout from 'Components/Layout';
import { Helmet } from 'react-helmet';
import { Services } from 'Services';
import { useNavigate } from 'react-router-dom';
import CategoryClasses from '../Admin/categories/AddCategory.module.css';
import { Success, Error } from 'Util/Toast';
import { useForm } from 'react-hook-form';
import { FaRegEdit } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import { SkeletonTheme } from 'react-loading-skeleton';
import UsersSkeleton from '../Admin/users/UsersSkeleton';
import { communityTableHeads } from '../Admin/constants/Constants';
import { Button, TablePagination } from '@mui/material';
import { CiSquarePlus } from 'react-icons/ci';
import { HiOutlineSearch } from 'react-icons/hi';
import OrderClasses from '../Admin/orders/Orders.module.css';
import { RxCrossCircled } from 'react-icons/rx';
import { RiCommunityFill } from 'react-icons/ri';
import communityPlaceholder from '../../Assets/no-communities1.gif';

const Communities = () => {
    const [communities, setCommunities] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deletingCommunityId, setDeletingCommunityId] = useState(null);
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const { register, control, handleSubmit, setValue, formState: { errors } } = useForm();
    const navigate = useNavigate();

    useEffect(() => {
        const token = { Authorization: `token ${localStorage.getItem('Token')}` };
        Services.getCommunities(token)
            .then((response) => {
                setCommunities(response.Communities || []);
                setTotalCount(response.Communities.length);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching communities:', error);
            });
    }, []);

    useEffect(() => {
        const token = { Authorization: `token ${localStorage.getItem('Token')}` };
        if (searchTerm.trim() !== '') {
            Services.searchCommunities(searchTerm, token)
                .then((response) => {
                    setCommunities([]);
                    setLoading(false);
                })
                .catch((error) => {
                    console.error('Error searching communities:', error);
                });
        } else {
            Services.getCommunities(token)
                .then((response) => {
                    setCommunities(response.Communities || []);
                    setLoading(false);
                })
                .catch((error) => {
                    console.error('Error fetching communities:', error);
                });
        }
    }, [searchTerm]);

    const formatDate = (dateString) => {
        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
        return formattedDate;
    };

    const handleDeleteCommunity = (communityId) => {
        const token = { Authorization: `token ${localStorage.getItem('Token')}` };
        Services.deleteCommunity(communityId, token)
            .then((response) => {
                if (response.Status === 1) {
                    setCommunities((prevCommunities) =>
                        prevCommunities.filter((community) => community.communityId !== communityId)
                    );
                    setTotalCount(totalCount - 1);
                    Success(response.Message);
                } else {
                    Error(response.Message);
                }
            })
            .catch((error) => {
                console.error('Error deleting community:', error);
                Error('Failed to delete community');
            });
    };

    const handleDeleteCommunityClick = (communityId) => {
        setDeletingCommunityId(communityId);
        setDeleteModalOpen(true);
    };

    const handleDeleteModalClose = () => {
        setDeleteModalOpen(false);
        setDeletingCommunityId(null);
    };

    const handleConfirmDelete = () => {
        if (deletingCommunityId) {
            handleDeleteCommunity(deletingCommunityId);
            handleDeleteModalClose();
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const indexOfLastItem = (page + 1) * rowsPerPage;
    const indexOfFirstItem = indexOfLastItem - rowsPerPage;
    const currentItems = communities.slice(indexOfFirstItem, indexOfLastItem);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleEditCommunityClick = (communityId) => {
        navigate(`/edit-community/${communityId}`);
    };

    const renderTable = () => {
        if (currentItems.length === 0) {
            return (
                <div className={UserClasses['no-records-container']}>
                    <img height={100} src={communityPlaceholder} alt="No Communities" className={UserClasses['no-records-icon']} data-aos="zoom-in" />
                    <p>No Communities Found</p>
                </div>
            );
        }

        return (
            <table className={ProductClasses['category-table']}>
                <thead>
                    <tr className={UserClasses['main-table-heads']}>
                        {communityTableHeads.map((head, index) => (
                            <th key={index} className={ProductClasses['products-table-head']}>{head}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((community, index) => (
                        <tr key={community.communityId} className={index % 2 === 0 ? UserClasses['even-row'] : UserClasses['odd-row']} data-aos="fade-up"
                            data-aos-anchor-placement="top-bottom">
                            <td className={CategoryClasses["table-cell"]}>{community.communityId || 'N/A'}</td>
                            <td className={CategoryClasses["table-cell"]}>{community.communityName || 'N/A'}</td>
                            <td className={CategoryClasses["table-cell"]}>{community.communityArea || 'N/A'}</td>
                            <td className={CategoryClasses["table-cell"]}>{community.communityLatitude} , {community.communityLongitude}</td>
                            <td>
                                <button
                                    className={`${CategoryClasses['edit-button']} ${CategoryClasses['button']}`}
                                    onClick={() => handleEditCommunityClick(community.communityId)}
                                >
                                    <FaRegEdit />
                                </button>
                                <button
                                    onClick={() => handleDeleteCommunityClick(community.communityId)}
                                    className={`${CategoryClasses['delete-button']} ${CategoryClasses['button']}`}
                                >
                                    <AiFillDelete />
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    return (
        <Layout Active={'Communities'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Communities</title>
            </Helmet>
            <div className={UserClasses['main-first-container']}>
                <div className={UserClasses['main-community-head']}>
                    <div className={ProductClasses['main-add-btn']} data-aos="zoom-in">
                        <Button style={{ height: '35px' }} variant="contained" color="primary" onClick={() => navigate('/add-community')}>
                            Add Community <CiSquarePlus className="CiSquarePlus" />
                        </Button>
                    </div>
                    <div className={UserClasses['main-data-tab']}>
                        <div className={UserClasses['form-main-search']} data-aos="zoom-in">
                            <form className={UserClasses['form-search']} action=''>
                                <input
                                    style={{ width: '300px' }}
                                    className={UserClasses['form-input-search']}
                                    type='search'
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                    placeholder='Search by community name/area'
                                />
                            </form>
                        </div>
                    </div>
                </div>
                <div className={UserClasses['main-data-tab']}>
                    <div className={UserClasses['users-main']}>
                        <h2 className={OrderClasses['header-form']}>
                            Communities <span className={UserClasses['head-count-main']}>({totalCount})</span>
                        </h2>
                        <div>
                            <SkeletonTheme color='#ddd' highlightColor='#eee'>
                                {loading ? (
                                    <UsersSkeleton TableHeader={communityTableHeads} />
                                ) : (
                                    <div style={{ overflowY: 'hidden' }}>
                                        {renderTable()}
                                    </div>
                                )}
                            </SkeletonTheme>
                            <div className={UserClasses['table-pagination']}>
                                <div className={UserClasses['current-page']}>
                                    <p>Current Page: {page + 1}</p>
                                </div>
                                <TablePagination
                                    component="div"
                                    count={communities.length}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={rowsPerPage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isDeleteModalOpen && (
                <div className={CategoryClasses['edit-modal-overlay']}>
                    <div className={CategoryClasses['edit-modal']} data-aos="zoom-in-down">
                        <div className={CategoryClasses['modal-content']}>
                            <h1><RxCrossCircled /></h1>
                            <h2>Are you sure?</h2>
                            <p>Do you really want to delete these records? This process cannot be undone.</p>
                        </div>
                        <div className={CategoryClasses['modal-last-div']}>
                            <button onClick={handleDeleteModalClose} className={CategoryClasses['modal-close-button']}>Close</button>
                            <button onClick={handleConfirmDelete} className={CategoryClasses['modal-save-button']}>Delete</button>
                        </div>
                    </div>
                </div>
            )}
        </Layout>
    );
};

export default Communities;
