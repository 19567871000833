import Layout from 'Components/Layout';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import UserClasses from '../users/Users.module.css';
import OrderClasses from '../orders/Orders.module.css';
import ProductClasses from '../products/Products.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import { TextField, Button, Select, MenuItem, Typography } from '@mui/material';
import { Services } from 'Services';
import { Success } from 'Util/Toast';

const EachPlan = () => {
    const navigate = useNavigate();
    const { subscriptionPlanId } = useParams(); // Extracting planId from URL
    const [data, setData] = useState(null);
    const [status, setStatus] = useState(null);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = { Authorization: `token ${localStorage.getItem('Token')}` };
                const response = await Services.getPlanById(subscriptionPlanId, token); // Calling API with planId from URL
                setData(response.data[0]); // Extracting the first plan from the response array
            } catch (error) {
                console.error('Error fetching plan details:', error);
            }
        };

        fetchData();
    }, [subscriptionPlanId]);

    const handleStatusChange = async (event) => {
        try {
            const newStatus = event.target.value; // Get selected status
            const token = { Authorization: `token ${localStorage.getItem('Token')}` };
            const response = await Services.changePlanStatus(subscriptionPlanId, newStatus, token); // Call changePlanStatus API
            setData(prevData => ({ ...prevData, status: newStatus })); // Update status in data state
            Success(response.Message);
        } catch (error) {
            console.error('Error changing plan status:', error);
        }
    };

    return (
        <Layout Active={'SubscriptionPlans'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Subscription Plans</title>
            </Helmet>
            <div className={UserClasses['main-first-container']}>
                <div className={UserClasses['main-data-tab']}>
                    <Button variant="contained" color="primary" onClick={() => navigate(-1)}>
                        BACK
                    </Button>
                </div>
                <div className={UserClasses['main-data-tab']}>
                    <div className={UserClasses['users-main']}>
                        <div>
                            <h2 className={OrderClasses['header-form']}>
                                Plan Details
                            </h2>
                            {data && (
                                <div>
                                    <div style={{ margin: '20px' }}>
                                        <TextField
                                            label="Plan Name"
                                            value={data.planName}
                                            disabled
                                            fullWidth
                                        />
                                    </div>
                                    <div style={{ margin: '20px' }}>
                                        <TextField
                                            label="Basic Plan Amount"
                                            value={data.basicPlanAmount}
                                            disabled
                                            fullWidth
                                        />
                                    </div>
                                    <div style={{ margin: '20px' }}>
                                        <TextField
                                            label="Basic Adult Count"
                                            value={data.basicAdultCount}
                                            disabled
                                            fullWidth
                                        />
                                    </div>
                                    <div style={{ margin: '20px' }}>
                                        <TextField
                                            label="Basic Child Count"
                                            value={data.basicChildCount}
                                            disabled
                                            fullWidth
                                        />
                                    </div>
                                    <div style={{ margin: '20px' }}>
                                        <TextField
                                            label="Extra Adult Amount"
                                            value={data.extraAdultAmount}
                                            disabled
                                            fullWidth
                                        />
                                    </div>
                                    <div style={{ margin: '20px' }}>
                                        <TextField
                                            label="Extra Child Amount"
                                            value={data.extraChildAmount}
                                            disabled
                                            fullWidth
                                        />
                                    </div>
                                    <div style={{ margin: '20px' }}>
                                        <TextField
                                            label="Plan Limit Days"
                                            value={data.planLimitDays}
                                            disabled
                                            fullWidth
                                        />
                                    </div>
                                    <Typography className={OrderClasses['header-form']} variant="h6" align="left" gutterBottom>
                                        Change Order Status
                                    </Typography>
                                    <div style={{ margin: '20px' }}>
                                        <Select
                                            value={data ? data.status : ''}
                                            onChange={handleStatusChange}
                                            fullWidth
                                        >
                                            <MenuItem value={1}>Active</MenuItem>
                                            <MenuItem value={0}>Inactive</MenuItem>
                                        </Select>
                                    </div>

                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default EachPlan;
