import React from 'react';
import { Helmet } from 'react-helmet';
import UserClasses from '../users/Users.module.css';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Button, TextField } from '@mui/material';
import Layout from 'Components/Layout';
import { Services } from 'Services';
import { Success } from 'Util/Toast';

const CreateNewConfig = () => {

    return (
        <Layout Active={'Configuration'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Create New Config</title>
            </Helmet>
            <div className={UserClasses['main-data-tab']}>
                <div className={UserClasses['users-main']}>
                  
                </div>
            </div>
        </Layout>
    );
};

export default CreateNewConfig;
